import "./Subscription.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";
import moment from "moment";
import { CSVLink } from "react-csv";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import Pagination from "../../Component/Pagination";
import SubscriptionCard from "../../Component/tableCard/SubscriptionCard";

const Subscription = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState();
  const [excel, setExcel] = useState([]);
  const [screenSize, setScreenSize] = useState(0);
  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);
  const linkRef = useRef(null);
  const searchdata = search.trim().split(/ +/).join(" ");

  const columns = [
    {
      headerName: "Consultations No",
      field: "coundno",
    },
    {
      headerName: "Patient Name",
      field: "name",
    },
    {
      headerName: "Treatment Plan",
      field: "treatment",
    },
    {
      headerName: "Frequency",
      field: "frequency",
      sortable: true,
    },
    {
      headerName: "Number Of RepeatLeft",
      field: "number",
    },
    {
      headerName: "Prescribing Doctor",
      field: "prescribing",
    },

    {
      headerName: "Payment amount",
      field: "payment",
    },
    {
      headerName: "Next Payment Due",
      field: "next",
    },
    {
      headerName: "Shipping Tracking No.",
      field: "shippingtrackignno",
    },
    {
      headerName: "Shipping Status",
      field: "shippingstatus",
      cellRenderer: (params) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: `${params?.value === 2 ? "#8b0000" : "#220a0a"}`,
            }}
          >
            {params?.data?.shippingstatus === 0 && "Awaiting Payment"}{" "}
            {params?.data?.shippingstatus === 1 && "Dispensing"}{" "}
            {params?.data?.shippingstatus === 2 && "Shipped"}
          </span>
        );
      },
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      cellRenderer: (params) =>
        params?.value === 0 ? (
          <span style={{ color: "#ff0000" }}>{"Pending"}</span>
        ) : params?.value === 1 ? (
          <span style={{ color: "#008000" }}>{"Success"}</span>
        ) : (
          ""
        ),
    },
  ];

  // modify subscription data
  const subscriptionListData = data?.map((subscriptions) => {
    return {
      id: subscriptions?.id,
      subscriptionNum: subscriptions?.coundno,
      patientName: subscriptions?.name,
      treatmentPlan: subscriptions?.treatment,
      frequency: subscriptions?.frequency,
      numberOfRepeats: subscriptions?.number,
      prescribingDoctor: subscriptions?.prescribing,
      paymentAmount: subscriptions?.payment,
      nextPaymentDue: subscriptions?.next,
      shippingTrackingNo: subscriptions?.shippingtrackignno,
      shippingStatus: (
        <h6
          className="consulation_list_li_data_inner_head_data status"
          style={{
            cursor: "pointer",
            backgroundColor: `${subscriptions?.shippingstatus === 2 ? "#8b01011a" : "#220a0a1a"
              }`,
            color: `${subscriptions?.shippingstatus === 2 ? "#8b0000" : "#220a0a"
              }`,
          }}
        >
          {subscriptions?.shippingstatus === 0 && "Awaiting Payment"}{" "}
          {subscriptions?.shippingstatus === 1 && "Dispensing"}{" "}
          {subscriptions?.shippingstatus === 2 && "Shipped"}
        </h6>
      ),
      paymentStatus:
        subscriptions?.shippingstatus === 0 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#ff00001a", color: "#ff0000" }}
          >
            Pending
          </h6>
        ) : subscriptions?.shippingstatus === 1 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#0080001a", color: "#008000" }}
          >
            Success
          </h6>
        ) : null,
    };
  });

  const headers = [
    { label: "Consultations No", key: "coundno" },
    { label: "Patient Name", key: "name" },
    { label: "Treatment Plan", key: "treatment" },
    { label: "Frequency", key: "frequency" },
    { label: "Number Of Repeats", key: "number" },
    { label: "Prescribing Doctor", key: "prescribing" },
    { label: "Payment amount", key: "payment" },
    { label: "Next Payment Due", key: "next" },
    { label: "Shipping Trackign No.", key: "shippingtrackignno" },
    { label: "Shipping Status", key: "shippingstatus" },
    { label: "Payment Status", key: "paymentstatus" },
  ];

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  const bodyData = {
    limit: perpage,
    page: page,
    search: searchdata,
  };

  // get subscription data on pages changes
  useEffect(() => {
    getdata(bodyData);
  }, [page, perpage]);

  // handle search
  const handleSearch = (searchData) => {
    if (searchData.length === 0) {
      getdata({ ...bodyData, search: "" });
    }
    setSearch(searchData);
  };

  // handle serach on submit
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getdata(body);
  };
  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata])

    // get subscription data
  const getdata = async (body) => {
    setLoader(true);
    ApiPost(`admin/patient/subscription/get`, body)
      .then((res) => {
        setLoader(false);
        const { data } = res.data;
        const temp =
          data?.subscription_data &&
          data?.subscription_data.map((val, index) => {

            return {
              coundno: (page - 1) * perpage + (index + 1),
              name: `${val?.patient?.[0]?.firstName && val?.patient?.[0]?.lastName
                ? `${val?.patient?.[0]?.firstName} ${val?.patient?.[0]?.lastName}`
                : "-"
                }`,
              treatment: val?.treatment?.[0]?.treatmentName
                ? val?.treatment?.[0]?.treatmentName
                : "-",
              frequency: val?.treatment?.[0]?.actual_frequency,
              number: val?.remainMonthDuration,
              prescribing: `${val?.doctor?.[0]?.firstName && val?.doctor?.[0]?.lastName
                ? `${val?.doctor?.[0]?.firstName} ${val?.doctor?.[0]?.lastName}`
                : "-"
                }`,
              payment: val?.paymentAmount,
              next:
                val?.expireDate === null ? "-" : moment(val?.expireDate).format("LLL"),
              shippingstatus: val?.shipmentStatus,
              shippingtrackignno:
                val?.shipmentStatus === 2 ? val?.shipmentCode : "-",
              paymentStatus: val?.paymentStatus,
              id: val?._id,
            };
          });
        setData(temp);
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  // export excel
  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  const ExportExcel = () => {
    const body = {
      page: 1,
      search: searchdata,
    };
    ApiPost(`admin/patient/subscription/get`, body)
      .then((res) => {
        const { data } = res.data;
        const temp =
          data?.subscription_data &&
          data?.subscription_data.map((val, index) => {
            return {
              coundno: (page - 1) * perpage + (index + 1),
              name: `${val?.patient?.[0]?.firstName && val?.patient?.[0]?.lastName
                ? `${val?.patient?.[0]?.firstName} ${val?.patient?.[0]?.lastName}`
                : "-"
                }`,
              treatment: val?.treatment?.[0]?.treatmentName
                ? val?.treatment?.[0]?.treatmentName
                : "-",
              frequency: val?.treatment?.[0]?.actual_frequency,
              number: val?.treatment?.[0]?.number_of_repeats
                ? val?.treatment?.[0]?.number_of_repeats
                : "-",
              prescribing: `${val?.doctor?.[0]?.firstName && val?.doctor?.[0]?.lastName
                ? `${val?.doctor?.[0]?.firstName} ${val?.doctor?.[0]?.lastName}`
                : "-"
                }`,
              payment: val?.paymentAmount,
              next:
                val?.expireDate === null ? "-" : moment(val?.expireDate).format("LLL"),
              shippingstatus:
                val?.shipmentStatus === 0 ? "Awaiting Payment" : val?.shipmentStatus === 1 ? "Dispensing" : val?.shipmentStatus === 2 ? "Shipped" : "-",
          
              shippingtrackignno:
                val?.shipmentStatus === 2 ? val?.shipmentCode : "-",
              paymentstatus: val?.paymentStatus === 0 ? "Pending" : val?.paymentStatus === 1 ? "Success" : "-",
              id: val?._id,
            };
          });
        setExcel(temp);
      })
      .catch((e) => { });
  };

  // hide side bar on responsive
  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Subscription Model</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"subscription"}
                  headers={headers}
                />
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="Input search text"
                      className="  pe-3 search search1"
                      value={search}
                      name="search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn searchbtn1 d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                  <button
                    className="button button1"
                    type="button"
                    onClick={() => ExportExcel()}
                  >
                    Export ExcelSheet
                  </button>
                </div>
              </div>
              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={data}
                    page={page}
                    perpage={perpage}
                    setparpage={setPerPage}
                    setpage={setPage}
                    totalData={totalData}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {subscriptionListData?.map((items, index) => {
                      return <SubscriptionCard key={index} data={items} />;
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}

              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>

            
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default Subscription;
