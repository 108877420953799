import React from "react";

// customer support card
const CustomerSupportCard = ({ data }) => {
  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head flex">
          <h6 className="consulation_list_li__head">{data?.id}</h6>
          <div
            style={{
              margin: "0 8px",
            }}
          >
          </div>
          <h6 className="consulation_list_li__head">{data?.userName}</h6>
        </div>
        <div className="consulation_list_li_data_inner">
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Email Address
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.email}
            </h6>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
            Query
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.query}
            </h6>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
            User Type
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.status === 0 ? "Patient" : "Doctor" }
            </h6>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
            Date/Time
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.createdAt}
            </h6>
          </div>
        </div>
      </li>
    </>
  );
};

export default CustomerSupportCard;
