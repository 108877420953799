import React from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Auth from '../helpers/Auth'

export const Nomatch = () => {
    const history = useHistory()
    useEffect(() => {
        if (Auth.isUserAuthenticated())
            history.push('/AdminDashboard')
        else
            history.push('/')

    }, [Auth.isUserAuthenticated()])
    return (
        <></>
    )
}
