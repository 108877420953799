import './Medicine.css';
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import DashHeader from '../../Component/DashHeader';
import { Row, Col, Form, Button }

    from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import SideDrawer from '../SideDrawer/SideDrawer';
import {
    ApiGet,
    ApiPost,
    ApiUploadPost,
    Bucket,
} from "../../helpers/API/API_data";
import { RxCrossCircled } from "react-icons/rx"
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import { Select } from 'antd';
let cond = []

const AddTreatment = props => {
    const [data, setdata] = useState({});
    const [value, setValue] = useState([])
    const [img, setImg] = useState();
    const [error, setError] = useState(false);
    const history = useHistory();
    
    const handleChange = (e) => {
        const { name, value } = e.target;

        setdata({
            ...data,
            [name]: value,
        });
    };


    const saveBtn = async () => {
        const body = {
            itemNo: data?.itemNo,
            condition: data?.condition,
            provider: value,
            drugName: data?.activeDrug,
            quantity: Number(data?.quentity),
            form: data?.form,
            price: Number(data?.price),
            wholeSalePrice: data?.wholeSalePrice ? Number(data?.wholeSalePrice) : 0,
            treatmentName: data?.treatmentName,
            medicineImages: [img],
            number_of_repeats: Number(data?.numberOfRepeats),
            route: data?.route,
            dose: Number(data?.dose),
            administration_frequency: data?.administration_frequency,
            detail_information: [
                {
                    title: "What is this medication for",
                    description: data?.q1
                },
                {
                    title: "How do the ingredients work?",
                    description: data?.q2
                },
                {
                    title: "How do I take this treatment?",
                    description: data?.q3
                },
                {
                    title: "How long does it take to work?",
                    description: data?.q4
                },
                {
                    title: "How long does the treatment last?",
                    description: data?.q5
                },
                {
                    title: "How do I store my medication?",
                    description: data?.q6
                },
                {
                    title: "What side effects can be experienced?",
                    description: data?.q7
                },
                {
                    title: "What precautions are there with this medication?",
                    description: data?.q8
                }
            ],
            monthly_subscription_cost: Number(data?.monthly_subscription_cost),
            quantity_of_unit_per_subscription: Number(data?.quantity_of_unit_per_subscription),
            subscription_frequency: Number(data?.subscription)
        };
        
        if (data?.itemNo && data?.condition && value?.length > 0 && data?.activeDrug && data?.quentity && data?.form && data?.price && data?.wholeSalePrice && data?.treatmentName && img && data?.numberOfRepeats && data?.route && data?.dose && data?.administration_frequency && data?.q1 && data?.q2 && data?.q3 && data?.q4 && data?.q5 && data?.q6 && data?.q7 && data?.q8 && data?.monthly_subscription_cost && data?.quantity_of_unit_per_subscription && data?.subscription) {
            ApiPost("admin/main_treatment", body)
                .then((res) => {
                    
                    setdata(res?.data?.data);
                    SuccessToast(res?.data?.message);
                    history.push("/Treatments");
                })
                .catch((e) => {
                    
                    ErrorToast(e?.message);
                });
        } else {
            setError(true)
        }


    };
    const uploadImage = (e) => {

        let formData = new FormData();
        formData.append("image", e.target.files[0]);
        ApiUploadPost(`upload/document`, formData)
            .then((res) => {
                
                setImg(res?.data?.data?.image)
            })
            .catch((error) => {
                
            })
    }
    const [screenSize, setScreenSize] = useState(0);

    useEffect(() => {
        let a = document.getElementById("sidebar");
        
        if (a && screenSize <= 1440) {
            a.classList.add("d-none");
            a.classList.remove("d-block");

        }
        else {
            a.classList.add("d-block");
            a.classList.remove("d-none");
        }
        const handleResize = () => {
            setScreenSize(window.innerWidth);

        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);

    }, [screenSize])
    return (
        <>
            <div className="chatscreen admindashboard">
                <Row>
                    <Col lg={2}>
                        <SideDrawer />

                    </Col>
                    <Col lg={10}>
                        <DashHeader></DashHeader>

                        <div className="activepatient">
                            <div className="titlepatient">
                                <h4>Add Treatment </h4>
                            </div>

                            <div className="medicinlist">
                                <Form>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="medicininput">
                                                <span>Treatment Name</span>
                                                <input type="text" className='rounded-3' placeholder="Enter Treatment Name" name='treatmentName'
                                                    value={data?.treatmentName}
                                                    onChange={handleChange} />
                                                {error && !data?.treatmentName && <div className='text-danger'>Treatment Name Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Number Of Repeats</span>
                                                <input type="number" placeholder="Enter Number of Repeats"
                                                    name='numberOfRepeats'
                                                    min={0}
                                                    className='rounded-3'
                                                    value={data?.numberOfRepeats}
                                                    onChange={handleChange} />
                                                {error && !data?.numberOfRepeats && <div className='text-danger'>Number Of Repeats Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Subscription Frequency</span>
                                                <input type="number" placeholder="Enter Subscription Frequency"
                                                    name='subscription'
                                                    className='rounded-3'
                                                    min={0}
                                                    value={data?.subscription}
                                                    onChange={handleChange} />
                                                {error && !data?.subscription && <div className='text-danger'>Subscription Frequency Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Item No</span>
                                                <input type="text" placeholder="Enter Item No"
                                                    name='itemNo'
                                                    className='rounded-3'
                                                    value={data?.itemNo}
                                                    onChange={handleChange} />
                                                {error && !data?.itemNo && <div className='text-danger'>Item No Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Quantity</span>
                                                <input type="number" placeholder="Enter Quantity"
                                                    name='quentity'
                                                    min={0}
                                                    className='rounded-3'
                                                    value={data?.quentity}
                                                    onChange={handleChange} />
                                                {error && !data?.quentity && <div className='text-danger'>Quantity Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Route</span>
                                                <input type="text" placeholder="Enter Route"
                                                    name='route'
                                                    className='rounded-3'
                                                    value={data?.route}
                                                    onChange={handleChange} />
                                                {error && !data?.route && <div className='text-danger'>Route Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Dose</span>
                                                <input type="number" placeholder="Enter Dose"
                                                    name='dose'
                                                    min={0}
                                                    className='rounded-3'
                                                    value={data?.dose}
                                                    onChange={handleChange} />
                                                {error && !data?.dose && <div className='text-danger'>Dose Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="medicininput">
                                                <span>Condition</span>
                                                <textarea type="text" placeholder="Enter Condition" name='condition'
                                                    value={data?.condition}
                                                    rows={3}
                                                    style={{ outline: "none" }}
                                                    className='rounded-3 w-100 ps-3'
                                                    onChange={handleChange} />
                                                {error && !data?.condition && <div className='text-danger'>Condition Is Required !</div>}
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Active Drug</span>
                                                <input type="text" placeholder="Enter Active Drug"
                                                    name='activeDrug'
                                                    className='rounded-3'
                                                    value={data?.activeDrug}
                                                    onChange={handleChange}

                                                />
                                                {error && !data?.activeDrug && <div className='text-danger'>Active Drug Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Form</span>
                                                <input type="text" placeholder="Enter form"
                                                    name='form'
                                                    value={data?.form}
                                                    className='rounded-3'
                                                    onChange={handleChange}
                                                />
                                                {error && !data?.form && <div className='text-danger'>Form Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Monthly Subscription Cost</span>
                                                <input type="number" placeholder="Enter Monthly Subscription Cost"
                                                    name='monthly_subscription_cost'
                                                    min={0}
                                                    className='rounded-3'
                                                    value={data?.monthly_subscription_cost}
                                                    onChange={handleChange}
                                                />
                                                {error && !data?.monthly_subscription_cost && <div className='text-danger'>Monthly Subscription Cost Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Quantity Of Unit Per Subscription</span>
                                                <input type="number" placeholder="Enter Quantity Of Unit Per Subscription"
                                                    name='quantity_of_unit_per_subscription'
                                                    min={0}
                                                    value={data?.quantity_of_unit_per_subscription}
                                                    className='rounded-3'
                                                    onChange={handleChange}
                                                />
                                                {error && !data?.quantity_of_unit_per_subscription && <div className='text-danger'>Quantity Of Unit Per Subscription Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Price</span>
                                                <input type="number" placeholder="Enter Price"
                                                    name='price'
                                                    min={0}
                                                    value={data?.price}
                                                    className='rounded-3'
                                                    onChange={handleChange}
                                                />
                                                {error && !data?.price && <div className='text-danger'>Price Is Required !</div>}
                                            </div>
                                        </Col>
                                          <Col lg={6}>
                                              <div className="medicininput">
                                                 <span>Wholesale Price</span>
                                                 <input
                                                  type="number"
                                                    placeholder="Enter Price"
                                                    name="wholeSalePrice"
                                                    min={0}
                                                    value={data?.wholeSalePrice}
                                                    className="rounded-3"
                                                    onChange={handleChange}
                                                    />
                                                {error && !data?.wholeSalePrice && (
                                                <div className="text-danger">Wholesale Price Is Required !</div>
                                                    )}
                                                </div>
                                                </Col>
                                        <Col lg={6}>
                                            <div className="medicininput w-100">
                                                <span>Provider</span>
                                                <Select
                                                    className=''
                                                    mode="multiple"
                                                    size="large"
                                                    style={{ outline: "none", width: '100%' }}
                                                    placeholder="Please select"
                                                    onChange={(e) => setValue(e)}
                                                    options={[
                                                        { label: 'Infinity Wellness (SA)', value: "Infinity Wellness (SA)" },
                                                        { label: 'Infinity Wellness (QLD)', value: "Infinity Wellness (QLD)" },
                                                        { label: 'Infinity Wellness (NSW)', value: "Infinity Wellness (NSW)" },
                                                        { label: 'Infinity Wellness (VIC)', value: "Infinity Wellness (VIC)" },
                                                    ]}
                                                />
                                                {error && value?.length === 0 && <div className='text-danger'>Provider Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>What is this medication for</span>
                                                <input type="text" placeholder="Information" name='q1'
                                                    value={data?.q1}
                                                    className='rounded-3'
                                                    onChange={handleChange} />
                                                {error && !data?.q1 && <div className='text-danger'>Answer Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How do the ingredients work?
                                                </span>
                                                <input type="text" placeholder="Information" name='q2'
                                                    value={data?.q2}
                                                    className='rounded-3'
                                                    onChange={handleChange} />
                                                {error && !data?.q2 && <div className='text-danger'>Answer Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How do I take this treatment?</span>
                                                <input type="text" placeholder="Information" name='q3'
                                                    value={data?.q3}
                                                    className='rounded-3'
                                                    onChange={handleChange} />
                                                {error && !data?.q3 && <div className='text-danger'>Answer Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How long does it take to work?</span>
                                                <input type="text" placeholder="Information" name='q4'
                                                    value={data?.q4}
                                                    className='rounded-3'
                                                    onChange={handleChange} />
                                                {error && !data?.q4 && <div className='text-danger'>Answer Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How long does the treatment last?</span>
                                                <input type="text" placeholder="Information" name='q5'
                                                    value={data?.q5}
                                                    className='rounded-3'
                                                    onChange={handleChange} />
                                                {error && !data?.q5 && <div className='text-danger'>Answer Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How do I store my medication?
                                                </span>
                                                <input type="text" placeholder="Information" name='q6'
                                                    value={data?.q6}
                                                    className='rounded-3'
                                                    onChange={handleChange} />
                                                {error && !data?.q6 && <div className='text-danger'>Answer Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>What side effects can be experienced?
                                                </span>
                                                <input type="text" placeholder="Information" name='q7'
                                                    value={data?.q7}
                                                    className='rounded-3'
                                                    onChange={handleChange} />
                                                {error && !data?.q7 && <div className='text-danger'>Answer Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>What precautions are there with this medication?
                                                </span>
                                                <input type="text" placeholder="Information" name='q8'
                                                    value={data?.q8}
                                                    className='rounded-3'
                                                    onChange={handleChange} />
                                                {error && !data?.q8 && <div className='text-danger'>Answer Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Upload Medicine Image
                                                </span>
                                                <input type="file" placeholder="Information" name='price'
                                                    className='rounded-3 p-2'
                                                    onChange={uploadImage} />
                                                {error && !img && <div className='text-danger'>Image Is Required !</div>}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>Administration Frequency</span>
                                                <input type="text" placeholder="Enter Administration Frequency"
                                                    name='administration_frequency'
                                                    className='rounded-3'
                                                    value={data?.administration_frequency}
                                                    onChange={handleChange} />
                                                {error && !data?.administration_frequency && <div className='text-danger'>Administration Frequency Is Required !</div>}
                                            </div>
                                        </Col>
                                        {img && <div><img src={Bucket + img} alt="" height={150} width={150} className='rounded-2' /> <RxCrossCircled style={{ cursor: "pointer" }} className='ms-2' onClick={() => setImg()} /></div>}
                                    </Row>
                                    <div className="btnredsubmit">
                                        <Button onClick={saveBtn}> Submit</Button>
                                    </div>
                                </Form>
                            </div>
                            <div className="coptrights">
                                <p>2023 Frenchie M.D Pty Ltd</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}


export default AddTreatment;