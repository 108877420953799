import * as yup from "yup";
// sub admin add schema
export const subAdminAddSchema = yup.object().shape({
    firstName: yup.string().required("FirstName is required "),
    lastName: yup.string().required("LastName is required "),
    email: yup
      .string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    gender: yup.string().required("Gender is required "),
    userType: yup.string().required("Role is required "),
    contactNumber: yup
      .string()
      .test(
        "contactNumber",
        "ContactNumber should start with 0",
        function (value) {
          if (!value) {
            throw new yup.ValidationError(
              "ContactNumber is required",
              null,
              "contactNumber"
            );
          }
          if (value && !value.startsWith("0")) {
            throw new yup.ValidationError(
              "ContactNumber should start with 0",
              null,
              "contactNumber"
            );
          }
          return true;
        }
      ),
      password: yup.string().default("").when("password", {
        is: (exist) => exist,
        then: yup.string()
          .matches(
            /^(?=.*[!@#$%^&*])([A-Za-z\d!@#$%^&*]{8,}|[!@#$%^&*]{1,}\d+)/,
            "Minimum eight characters with at least one special character"
          ),
        otherwise: yup.string().required("Password is required")
    }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Confirm password is required"),
  },[["password","password"]]);

  // sub-admin update schema
  export const subAdminUpdateSchema = yup.object().shape({
    firstName: yup.string().required("FirstName is required "),
    lastName: yup.string().required("LastName is required "),
    email: yup
      .string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    gender: yup.string().required("Gender is required "),
    userType: yup.string().required("Role is required "),
    contactNumber: yup
      .string()
      .test(
        "contactNumber",
        "ContactNumber should start with 0",
        function (value) {
          if (!value) {
            throw new yup.ValidationError(
              "ContactNumber is required",
              null,
              "contactNumber"
            );
          }
          if (value && !value.startsWith("0")) {
            throw new yup.ValidationError(
              "ContactNumber should start with 0",
              null,
              "contactNumber"
            );
          }
          return true;
        }
      ),
    oldpassword: yup.string().when("newpassword", {
      is: (newpassword) => newpassword && newpassword.length > 0,
      then: yup.string().required("Old Password is required"),
    }),
    newpassword: yup.string().default("").when("newpassword", {
      is: (exist) => exist,
      then: yup.string().required()
        .matches(
          /^(?=.*[!@#$%^&*])([A-Za-z\d!@#$%^&*]{8,}|[!@#$%^&*]{1,}\d+)/,
          "Minimum eight characters with at least one special character"
        ),
      otherwise: yup.string().notRequired()
  }),
  },[['newpassword', 'newpassword']]);