import "./PaymentSummary.css";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import DashHeader from "../../Component/DashHeader";
import { ApiPost } from "../../helpers/API/API_data";
import { CSVLink } from "react-csv";
import { useContext } from "react";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import Pagination from "../../Component/Pagination";
import PaymentCard from "../../Component/tableCard/PaymentCard";
import moment from "moment";
import { DatePicker } from 'antd'

const PaymentSummary = (props) => {
  const { RangePicker } = DatePicker;

  const columns = [
    {
      headerName: "Patient Name",
      field: "name",
    },

    {
      headerName: "Doctor Name",
      field: "doctor",

    },
    {
      headerName: "Treatment Name",
      field: "treatment",
      cellRenderer: (params) => (
        <div>
          <div>
            {params.data?.treatment.length > 0 ?
              params.data?.treatment.map((v, index) => (
                <div key={index}>{v.treatmentName}</div>
              ))
              :
              "-"
            }
          </div>
        </div>
      )

    },
    {
      headerName: "Condition Type",
      field: "codition",

    },

    {
      headerName: "Is Subscription",
      field: "subscription",

    },
    {
      headerName: "Subscription Type",
      field: "subscriptiontype",

    },
    {
      headerName: "Payable Amout",
      field: "paymentamout"
    },
    {
      headerName: "Discount Amout",
      field: "discount"
    },
    {
      headerName: "Payment Id",
      field: "Payid",


    },
    {
      headerName: "Payment Type",
      field: "paytype",


    },
    {
      headerName: "Payment Method",
      field: "paymethod",
    },


    {
      headerName: "Payment status",
      field: "status",
      cellRenderer: (params) =>
        params?.value === 0 ? <span style={{ color: "#077E8C" }}>Pending Payment</span> : params?.value === 1 ? <span style={{ color: "#008000" }}>Payment successful</span> : params?.value === 2 ? <span style={{ color: "#ff0000" }}>payment failed</span> : ""


    },
    {
      headerName: "Date/Time",
      field: "datetime",
    },

  ];

  const [subscription, setSubscriptio] = useState()
  const [excel, setExcel] = useState([])
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0)
  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext)
  const [screenSize, setScreenSize] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [startData, setStartDate] = useState("")
  const [endData, setEndDate] = useState("")

  const linkRef = useRef(null)


// modify payment data
  const paymentSummaryData = subscription?.map((payments, index) => {

    return {
      id: index,
      patientName: payments?.name,
      doctorName: payments?.doctor,
      treatmentName: payments?.treatment,
      conditionType: payments?.codition,
      subscription: payments?.subscription,
      subscriptionType: payments?.subscriptiontype,
      paymentAmount: payments?.paymentamout,
      discountAmount: payments?.discount,
      payId: payments?.Payid,
      payType: payments?.paytype,
      payMethod: payments?.paymethod,
      payStatus:
        payments?.status && payments?.status === 0 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#077e8c1a", color: "#077E8C" }}
          >
            Pending Payment
          </h6>
        ) : payments?.status === 1 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#0080001a", color: "#008000" }}
          >
            Payment successful
          </h6>
        ) : payments?.status === 2 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#ff00001a", color: "#ff0000" }}
          >
            Payment Failed
          </h6>
        ) : (
          "-"
        ),
      datetime: moment(payments?.createdAt).format('LLL'),
    };
  });

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);
  const headers = [
    { label: "Name", key: "name" },
    { label: "Doctor", key: "doctor" },
    { label: "Treatment Name", key: "treatment" },
    { label: "Condition Type", key: "codition" },
    { label: "Is Subscription", key: "subscription" },
    { label: "Subscription Type", key: "subscriptiontype" },
    { label: "Payable Amout", key: "paymentamout" },
    { label: "Discount Amout", key: "discount" },
    { label: "Payment Id", key: "Payid" },
    { label: "Payment Type", key: "paytype" },
    { label: "Payment Method", key: "paymethod" },
    { label: "Payment status", key: "status" },
    { label: "Date/Time", key: "datetime" },
  ]


// get subscription_payment data
  const getSubscriptionData = (body) => {
    setLoader(true)
    ApiPost(`admin/subscription_payment/get`, body)
      .then((res) => {
        setLoader(false)
        setSubscriptio(res?.data?.data?.subscription_data && res?.data?.data?.subscription_data.map((element) => {
          return {
            name: `${element?.patient?.[0]?.firstName} ${element?.patient?.[0]?.lastName}`,
            doctor: element?.doctor?.length ? `${element?.doctor?.[0]?.firstName}  ${element?.doctor?.[0]?.lastName}` : "-",
            treatment: element?.treatment,
            codition: element?.condition ? element?.condition === "STI/STD" ? "STI Screening" : element?.condition : "-",
            subscription: element?.treatment_ids?.length > 0 ? "From Yes" : "From No",
            subscriptiontype: element?.treatment_ids?.length > 0 ? 'Treatment' : "Consult",
            paymentamout: element?.price ? element?.price : 0,
            Payid: element?.novatiiPaymentIntentId ? element?.novatiiPaymentIntentId : "-",
            paytype: element?.novatiiPaymentIntentId || element?.novatiiPaymentIntentId ? "Online" : "-",
            paymethod: element?.novatiiPaymentIntentId || element?.novatiiPaymentIntentId ? "Card" : "-",
            status: element?.paymentStatus,
            discount: element?.discount ? element?.discount : "-",
            datetime: element?.createdAt && moment(element?.createdAt).format('LLL'),
          }
        }))
        setTotalData(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        setLoader(false)
        console.log('err', err)
      })
  }

  const body = {
    page: page,
    limit: perpage,
    search,
    status: parseInt(paymentStatus),
    startDate: startData,
    endDate: endData,
  };

  // get payment data on filter and page change
  useEffect(() => {
    getSubscriptionData(body);
  }, [page, perpage, paymentStatus, startData, endData]);

  // handle search 
  const handleSearch = (e) => {
    if (!e.target.value) {
      getSubscriptionData({ ...body, search: "" });
    }
    setSearch(e.target.value);
  };

  // handle search on save botton
  const handleSubmit = () => {
    getSubscriptionData(body);
  };
  useEffect(() => {
    handleSubmit();
  }, [search]);

  // export excel
  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
    };
    ApiPost(`admin/subscription_payment/get`, body)
      .then((res) => {
        setExcel(
          res?.data?.data?.subscription_data &&
          res?.data?.data?.subscription_data.map((element) => {
            return {
              name: `${element?.patient?.[0]?.firstName} ${element?.patient?.[0]?.lastName}`,
              doctor: element?.doctor?.length
                ? `${element?.doctor?.[0]?.firstName}  ${element?.doctor?.[0]?.lastName}`
                : "-",
              treatment: element?.treatment?.[0]?.treatmentName ?? "-",
              codition: element?.condition ? element?.condition : "-",
              subscription: element?.treatment_ids?.length > 0 ? "From Yes" : "From No",
              Payid: element?.novatiiPaymentIntentId ? element?.novatiiPaymentIntentId : "-",
              paytype: element?.novatiiPaymentIntentId || element?.novatiiPaymentIntentId ? "Online" : "-",
              paymethod: element?.novatiiPaymentIntentId || element?.novatiiPaymentIntentId ? "Card" : "-",
              codition: element?.condition ? element?.condition === "STI/STD" ? "STI Screening" : element?.condition : "-",
              subscriptiontype: element?.treatment_ids?.length > 0 ? 'Treatment' : "Consult",
              paymentamout: element?.price ? element?.price : 0,
              discount: element?.discount ? element?.discount : "-",
              status:
                element?.paymentStatus === 0
                  ? "Pending Payment"
                  : element?.paymentStatus === 1
                    ? "Payment successful"
                    : element?.paymentStatus === 2
                      ? "payment failed"
                      : "",
              discount: element?.discount ? `$${element?.discount}` : "-",
              datetime: element?.createdAt && moment(element?.createdAt).format('LLL'),
            };
          })
        );
      })
      .catch((err) => { });
  };

  // hide side bar in responsive
  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main" id="paymenSumm_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>payment summary </h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"PaymentSummary"}
                  headers={headers}
                />
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="Input search text"
                      className="  pe-3 search search1"
                      value={search}
                      onChange={(e) => handleSearch(e)}
                    />
                    <button
                      className="searchbtn searchbtn1 d-flex align-items-center"
                      onClick={() => handleSubmit()}
                    >
                      {" "}
                      Search{" "}
                    </button>
                  </div>
                  <select
                    className="searchfilter"
                    name="TreatmentStatus"
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  >
                    <option selected disabled hidden>
                      All
                    </option>
                    <option value="null">All</option>
                    <option value={1}>Consult</option>
                    <option value={2}>Treatment</option>
                  </select>
                  <RangePicker className='rounded-5'
                    onChange={(e, picker) => { setStartDate(picker[0]); setEndDate(picker[1]) }}
                  />
                  <button
                    className="button button1"
                    type="button"
                    onClick={() => ExportExcel()}
                  >
                    {" "}
                    Export ExcelSheet{" "}
                  </button>
                </div>
              </div>
              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={subscription}
                    page={page}
                    perpage={perpage}
                    setparpage={setPerPage}
                    setpage={setPage}
                    totalData={totalData}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {paymentSummaryData?.map((items, index) => {
                      return <PaymentCard key={index} data={items} />;
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
              {/* </div> */}
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default PaymentSummary;
