import React, { useEffect, useState } from "react";
import user from "../Assets/images/darkuser.png";
import notification from "../Assets/images/notification.png";
import { ApiGet } from "../helpers/API/API_data";
import moment from "moment/moment";
import { Drawer } from "antd";
import SideDrawer from "../Page/SideDrawer/SideDrawer";
import darklogo from "../Assets/images/darklogo.png";
import UpdateAdmin from "./modals/addSubAdmin/UpdateAdmin";
import { ErrorToast } from "../helpers/Toast";
import notificationSVG from '.././Assets/images/svg/notification.svg'

const DashHeader = () => {
  const [nofication, setNofication] = useState([]);
  const [screenSize, setScreenSize] = useState(0);
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth > 1440);
  const [isOpen, setIsOpen] = useState(false);
  const userData = JSON.parse(localStorage?.getItem("userData"));

  // toggle sidebar drawer
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

// handle responsiveness
  useEffect(() => {
    window.addEventListener("resize", () => {
      const isMobileView = window.innerWidth > 1440;
      if (isMobile !== isMobileView) setIsMobile(isMobileView);
    });
  }, [isMobile]);

  // get notifications on resize
  useEffect(() => {
    getnotifications();
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  // get notifications
  const getnotifications = () => {
    ApiGet("admin/admin_notifications")
      .then((res) => {
        setNofication(res?.data);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  return (
    <>
      <header className="chatheader">
        <div className="header">
          {!isMobile && (
            <div className="logo_admin_dash_mobile">
              <img src={darklogo} alt="" />
            </div>
          )}
          {!isMobile && (
            <>
              <Notification nofication={nofication} />
              <div className="profilelogo">
                <span className="d-flex align-items-center ms-0">
                  <img src={user} alt="" onClick={()=>setIsOpen(true)} style={{cursor:'pointer'}}/>
                  <span className="admin_text"> Frenchie M.D Admin </span>
                </span>
              </div>
            </>
          )}
          {isMobile && (
            <>
              <div className="profilelogo">
                <span className="d-flex align-items-center ms-0">
                  <img src={user} alt="" onClick={()=>setIsOpen(true)} style={{cursor:'pointer'}}/>
                  <span className="admin_text"> {`${userData?.firstName ? userData?.firstName : ""} ${userData?.lastName ? userData?.lastName : ""}`}  </span>
                </span>
              </div>
              <Notification nofication={nofication} />
            </>
          )}
          {!isMobile && (
            <div className="menu_button_sidebar">
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
                className="menu_side_button px-0"
                onClick={showDrawer}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5004_70748)">
                    <path
                      d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"
                      fill="#003B61"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5004_70748">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          )}
        </div>
        <Drawer
          placement="right"
          closable={false}
          onClose={onClose}
          open={open}
          key="right"
        >
          <SideDrawer />
        </Drawer>
      </header>
      {/* update subadmins pop-up */}
      {
          isOpen &&
          <UpdateAdmin setopen={setIsOpen} open={isOpen}/>
        }
    </>
  );
};

export default DashHeader;

function Notification({ nofication }) {
  const [isNotification, setisNotification] = useState(false)

  // if no notification notify no notification data
  const handleNoNotificationdata = () => {
    setisNotification(!isNotification)
    if (!nofication?.length) {
      ErrorToast("Notification data not found");
    }
  };
  const [isDashHeaders, setiIDashHeaders] = useState(false);

  // handle responsivenss
  useEffect(() => {
    const handleResize = () => {
      setiIDashHeaders(window.innerWidth > 1440); 
    };

    handleResize(); 
    window.addEventListener('resize', handleResize); 

    return () => {
      window.removeEventListener('resize', handleResize); 
    };
  }, []);

  return (
    <div className= {isDashHeaders ? "dashHeaderNotifications" : "notificationicon" } onClick={handleNoNotificationdata} >
        <img src={notification} alt="" />
      {
        nofication?.length ?
        <ul className="bell noti" style={{display: isNotification ? "block" : "none"}}>
          {nofication?.map((item, i) => {
            return (
              <li
                key={`${i}${item?.message}`}
                style={{
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <p
                  style={{
                    textAlign: "start",
                  }}
                >
                  <span></span>
                  {item?.message}
                </p>
                <div
                  className="notiicon"
                  style={{
                    minWidth: "50px",
                    marginLeft: "0px",
                  }}
                >
                  {/* <span>🔔</span> */}
                  <img src={notificationSVG} style={{width:"20px"}} alt="notification img"/>
                  <p>
                    {moment(new Date()).format("DD/MM/YYYY") ===
                    moment(new Date(item?.createdAt)).format("DD/MM/YYYY")
                      ? "Today"
                      : moment(new Date(item?.createdAt)).format("DD/MM")}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
        : ""
      }
    </div>
  );
}
