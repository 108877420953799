import "./Orders.css";
import "./moodrately.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { RxDownload } from "react-icons/rx";
import { BiChat } from "react-icons/bi";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost,  ApiUploadPost, Bucket } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";
import { CSVLink } from "react-csv";
import { Row, Col } from "react-bootstrap";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import { ErrorToast } from "../../helpers/Toast";
import ShipingTrackNumber from "../../Component/modals/ShipingTrackNumber";
import Pagination from "../../Component/Pagination";
import OrderCard from "../../Component/tableCard/OrderCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { isPharmacyUser } from "../../utils/user.util";

const Orders = (props) => {
  const history = useHistory();


  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [excel, setExcel] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const linkRef = useRef(null);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");
  const [id, setId] = useState();
  const [selectedRows, setSelectedRows] = useState([]);

  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);
  let userData = JSON.parse(localStorage.getItem("userData"));

  // in pharmcy user nagivate to chat screen by getting rooms
  const callroom = async (recieverId) => {
    const body = {
      userIds: [recieverId, userData?._id],
      
    };
    await ApiPost('admin/room/get_roomId', body)
      .then((res) => {
        props?.isMobile ? history.push(`/ChatScreen`, { id: recieverId }) : history.push(`/ChatScreenMobile/id/${recieverId}`, { id: recieverId })
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const columns = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: "Order no.",
      field: "order",
      cellRenderer: (params) => (
        <span className="d-flex align-items-center">{params?.value}</span>
      ),
    },
    {
      headerName: "Patient Name",
      field: "name",
      cellRenderer: (d) => (

        <span
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            !isPharmacyUser(userData) &&
              history.push({
                pathname: `/viewPatient/${d?.data.patientId}`,
                state: { questionType: d?.data?.document?.condition },
              })
          }

          }
        >
          {d?.data?.name}
        </span>
      ),
    },
    {
      headerName: "Contact No",
      field: "contactNumber",
    },
    {
      headerName: "Shipping Address",
      field: "saddress",

    },
    {
      headerName: "Item Number",
      field: "Item",
    },
    {
      headerName: "Treatment Name",
      field: "Treatment",
    },
    {
      headerName: "Quantity",
      field: "Quantity",
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      cellRenderer: (params) => {
        return params.value === 0 ? (
          <span>Pending</span>
        ) : (
          params.value === 1 && <span>Confirm</span>
        );
      },
    },
    {
      headerName: "Order Status",
      field: "status",
      cellRenderer: (params) => {

        return <span
          onClick={() => {
            if (params?.value === 1) {
              setShow(true);
              setId(params?.data?.subscriptionId)
              setStatus(params?.data?.paymentStatus)
            }
          }}

          style={{ cursor: 'pointer', color: `${params?.value === 2 ? '#8b0000' : '#220a0a'}` }}>{params?.data?.status === 0 && "Awaiting Payment"}  {params?.data?.status === 1 && "Dispensing"} {params?.data?.status === 2 && "Shipped"}</span>
      
      }

    },
    {
      headerName: "Shipping Tracking Number",
      field: "tracking",
    },
   
    {
      headerName: "Action",
      field: "",
      width:isPharmacyUser(userData) ? 130 : "auto",
      suppressSizeToFit:isPharmacyUser(userData) ? true : false,
      cellRenderer: (params) => (
        <span style={{ cursor: 'pointer',display:'flex', justifyContent: 'space-between' }}>
          {params?.data?.pdfDownload ? (
            <>
              <AiOutlineEye
                className="cursor-pointer"
                size={20}
                onClick={() => window.open(params?.data?.pdfUrl)}
              />{" "}
              {
                isPharmacyUser(userData) ?
                  <RxDownload
                    className="cursor-pointer"
                    size={20}
                    onClick={() => window.open(params?.data?.pdfDownload)}
                  /> : " "
              }
              { isPharmacyUser(userData) &&
                  <BiChat
                  className="cursor-pointer"
                  size={20}
                  onClick={()=>callroom(params?.data?.patientId)}
                /> 
              }

            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    {
      headerName: "Order number",
      field: "ordernumber",
    },
  ];
  if (!isPharmacyUser(userData)) {
    columns.shift(); // Remove the first object from the array
  }

 

  const ordersListData = patients?.map((orders) => {

    return {
      id: orders?.id,
      patientName: (
        <span
          style={{ cursor: "pointer" }}
          onClick={
            isPharmacyUser(userData) ? () => { } : () => history.push({
              pathname: `/viewPatient/${orders?.patientId}`,
              state: { questionType: orders?.condition },
            })
          }
        >
          {orders?.name}
        </span>
      ),
      orderNumber: orders?.ordernumber,
      contactNumber: orders?.contactNumber,
      email: orders?.email,
      address: orders?.address,
      itemNum: orders?.Item,
      treatmentName: orders?.Treatment,
      quantity: orders?.Quantity,
      paymentStatus:
        orders?.paymentStatus === 0
          ? "Pending"
          : orders?.paymentStatus === 1
            ? "Confirm"
            : "-",
      orderStatus: (
        <h6
          className="consulation_list_li_data_inner_head_data status"
          onClick={() => {
            if (orders?.status === 1) {
              setShow(true);
              setId(orders?.subscriptionId);
              setStatus(orders?.paymentStatus);
            }
          }}
          style={{
            cursor: "pointer",
            backgroundColor: `${orders?.status === 2 ? "#0080001a" : "#220a0a1a"
              }`,
            color: `${orders?.status === 2 ? "#8b0000" : "#220a0a"}`,
          }}
        >
          {orders?.status === 0
            ? "Awaiting Payment"
            : orders?.status === 1
              ? "Dispensing"
              : orders?.status === 2
                ? "Shipped"
                : "-"}
        </h6>
      ),
      shippingTrackNo: orders?.tracking,
      actionView: (
        <>
          <AiOutlineEye
            style={{ width: "21px", height: "18px", cursor: "pointer" }}
            onClick={() => window.open(orders?.pdfUrl)}
          />
          {
            isPharmacyUser(userData) ?
              <RxDownload
                className="ms-2 cursor-pointer"
                size={20}
                onClick={() => window.open(orders?.pdfDownload)}
              /> : " "
          }
              { isPharmacyUser(userData) &&
                  <BiChat
                  className="ms-2 cursor-pointer"
                  size={20}
                  onClick={()=>callroom(orders?.patientId)}
                /> 
              }
        </>

      ),
      subscriptionId: orders?.subscriptionId
    };
  });


  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  const headers = [
    { label: "Order_number", key: "ordernumber" },
    { label: "Patient Name", key: "name" },
    { label: "Contact No", key: "contactNumber" },
    { label: "Email Address", key: "email" },
    { label: "Shipping Address", key: "saddress" },
    { label: "Item Number", key: "Item" },
    { label: "Treatment Name", key: "Treatment" },
    { label: "Quantity", key: "Quantity" },
    { label: "Order Status", key: "status" },
    { label: "Traking_Number", key: "tracking" },
  ];

  

  // get order data
  const getData = async (body) => {
    setShow(false)
    const bodyWithUserType = {
      ...body,
      admintype: isPharmacyUser(userData) ? 1 : 0
    }
    setLoader(true);
    ApiPost(`admin/orders/get`, bodyWithUserType)
      .then((res) => {
        //

        setLoader(false)
        setPatients(res?.data?.data?.orders_data && res?.data?.data?.orders_data.map((val, i) => {

          return ({
            order: (page - 1) * perPage + (i + 1),
            ordernumber: val?.orderNumber,
            name: `${val?.patient?.[0]?.firstName && val?.patient?.[0]?.lastName ? `${val?.patient?.[0]?.firstName} ${val?.patient?.[0]?.lastName}` : '-'}`,
            contactNumber: val?.patient?.[0]?.contactNumber ? val?.patient?.[0]?.contactNumber : "-",
            email: val?.patient?.[0]?.email ? val?.patient?.[0]?.email : "-",
            address: `${val?.patient?.[0]?.billingAddress ? val?.patient?.[0]?.billingAddress : "-"},${val?.patient?.[0]?.city ? val?.patient?.[0]?.city : "-"},
            ${val?.patient?.[0]?.state ? val?.patient?.[0]?.state : "-"},
            ${val?.patient?.[0]?.country ? val?.patient?.[0]?.country : "-"},
            ${val?.patient?.[0]?.postcode ? val?.patient?.[0]?.postcode : "-"}
            `,
            saddress: (val?.treatments?.[0]?.shippingAddress?.Address || val?.patient?.[0]?.billingAddress) + ", " +
              (val?.treatments?.[0]?.shippingAddress?.city || val?.patient?.[0]?.city) + ", " +
              (val?.treatments?.[0]?.shippingAddress?.state || val?.patient?.[0]?.state) + ", " +
              (val?.treatments?.[0]?.shippingAddress?.country || val?.patient?.[0]?.country) + ", " +
              (val?.treatments?.[0]?.shippingAddress?.postcode || val?.patient?.[0]?.postcode)
            ,
            Item: val?.treatments?.[0]?.itemNo ? val?.treatments?.[0]?.itemNo : "-",
            Treatment: val?.treatments?.[0]?.treatmentName ? val?.treatments?.[0]?.treatmentName : "-",
            Quantity: val?.treatments?.[0]?.quantity_of_unit_per_subscription ? val?.treatments?.[0]?.quantity_of_unit_per_subscription : '-',
            Shipment: val?.shipmentForm ? val?.shipmentForm : "-",
            status: val?.status,
            tracking: val?.Subcription?.[0]?.shipmentCode && val?.status === 2 ? val?.Subcription?.[0]?.shipmentCode : "-",
            patientId: val?.patientId,
            document: val?.treatments[0],
            paymentStatus: val?.treatments[0]?.paymentStatus,
            condition: val?.treatments[0]?.condition,
            pdfUrl: val?.Subcription[0]?.pdfPrint,
            pdfDownload: val?.Subcription[0]?.pdfDownload,
            id: val?._id,
            subscriptionId: val?.Subcription[0]?._id,
          })
        }));
        setTotalData(res?.data?.data?.state?.data_count)
      })
      .catch((e) => {
        setLoader(false);
      }).finally(()=>{
       
      });
  };
  const searchdata = search.trim().split(/ +/).join(" ");

  const bodyData = {
    page: page,
    limit: perPage,
    search: searchdata,
    AtoZ: false,
    paymentStatus: paymentStatus,
  };

    // get order data on pagination and filter change
  useEffect(() => {
    getData(bodyData);
  }, [page, perPage, paymentStatus]);

  // handle search
  const handleSearch = (searchData) => {
    if (searchData.length === 0) {
      getData({ ...bodyData, search: "" });
    }
    setSearch(searchData);
  };

  // handle search on search botton
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getData(body);
  };

  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata])

  // export excel
  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);


  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
      admintype: isPharmacyUser(userData) ? 1 : 0
    };

    ApiPost(`admin/orders/get`, body)
      .then((res) => {
        setExcel(
          res?.data?.data?.orders_data &&
          res?.data?.data?.orders_data.map((val, i) => {
            return {
              ordernumber: val?.orderNumber,
              name: `${val?.patient?.[0]?.firstName && val?.patient?.[0]?.lastName
                ? `${val?.patient?.[0]?.firstName} ${val?.patient?.[0]?.lastName}`
                : "-"
                }`,
              contactNumber: val?.patient?.[0]?.contactNumber
                ? val?.patient?.[0]?.contactNumber
                : "-",
              email: val?.patient?.[0]?.email
                ? val?.patient?.[0]?.email
                : "-",
              saddress: (val?.treatments?.[0]?.shippingAddress?.Address || val?.patient?.[0]?.billingAddress) + ", " +
                (val?.treatments?.[0]?.shippingAddress?.city || val?.patient?.[0]?.city) + ", " +
                (val?.treatments?.[0]?.shippingAddress?.state || val?.patient?.[0]?.state) + ", " +
                (val?.treatments?.[0]?.shippingAddress?.country || val?.patient?.[0]?.country) + ", " +
                (val?.treatments?.[0]?.shippingAddress?.postcode || val?.patient?.[0]?.postcode)
              ,
              Item: val?.treatments?.[0]?.itemNo
                ? val?.treatments?.[0]?.itemNo
                : "-",
              Treatment: val?.treatments?.[0]?.treatmentName
                ? val?.treatments?.[0]?.treatmentName
                : "-",
              Quantity: val?.treatments?.[0]
                ?.quantity_of_unit_per_subscription
                ? val?.treatments?.[0]?.quantity_of_unit_per_subscription
                : "-",
              Shipment: val?.shipmentForm ? val?.shipmentForm : "-",
              status:
                val?.status === 0
                  ? "Awaiting Payment"
                  : val?.status === 1
                    ? "Dispensing"
                    : val?.status === 2
                      ? "Shipped"
                      : "-",
              tracking: val?.Subcription?.[0]?.shipmentCode && val?.status === 2 ? val?.Subcription?.[0]?.shipmentCode : "-",

            };
          })
        );
      })
      .catch((e) => { });
  };

  // download images
  const handleDownload = async (val) => {
    if (val) {
      const link = document.createElement("a");
      link.href = Bucket + val; // Replace with the actual path to your image
      link.download = "image.jpg"; // Replace with the desired filename for the downloaded image
      link.click();
    }
  };

// sidebar responsiveness
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  // pharmacy user bulk print
  const handleViewMultiPdf = () => {
    let selectedPdfIds = [];
    if (selectedRows && selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        if (row.subscriptionId) {
          selectedPdfIds.push(row.subscriptionId);
        }
      });
    }
    let body = {
      ids: isTablet ? selectedPdfIds : selectedRows,
    };
    ApiPost("admin/patient/subscription/viewSelectedPdfs", body)
      .then((res) => {
        getData(bodyData);
        let viewdata = res?.data?.data?.mergedPdfURL;
        window.open(viewdata, '_blank');
        setSelectedRows([])
        setLoader(true)
      })
      .catch((err) => {
        console.log("err", err);
      }).finally(() => {
        setLoader(false)
      })
  };

  // import exel
  const HandleCsvImport = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile?.type === "text/csv") {
      setLoader(true);
      const formData = new FormData();
      formData.append("excelfile", selectedFile);
      ApiUploadPost(`admin/order/import_csv`, formData)
        .then((response) => {
          if (response?.data?.data?.status === 1) {
            getData(bodyData);
          } else {
            ErrorToast("Failed to update");
          }
        })
        .catch((error) => {
          ErrorToast(error?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      ErrorToast("Please upload only csv file  ");
    }
  };

  return (
    <>
      <div className="chatscreen admindashboard pathology_main_div">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>ORDERS</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"orders"}
                  headers={headers}
                ></CSVLink>
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="Input search text"
                      className="  pe-3 search search1"
                      value={search}
                      name="search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn searchbtn1 d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                  {!isPharmacyUser(userData) && (
                    <select
                      className="searchfilter"
                      name="userStatus"
                      onChange={(e) => setPaymentStatus(Number(e.target.value))}
                    >
                      <option selected disabled hidden>
                        All
                      </option>
                      <option value="null">All</option>
                      <option value={0}>Pending</option>
                      <option value={1}>Confirm</option>
                    </select>
                  )}

                  {/* bulk download in pharmacy user */}
                  {(selectedRows?.length > 0 && isPharmacyUser(userData)) && (
                    <button
                      className="button"
                      onClick={() => handleViewMultiPdf()}
                    >
                      Print
                    </button>
                  )}
                    {/* bulk import and update status */}
                  {
                    isPharmacyUser(userData) &&
                    <div>
                      <input type="file" id="actual-btn" accept=".csv" hidden onChange={HandleCsvImport} />
                      <label for="actual-btn" className="button button1 fileUploadInput">
                        Import CSV
                      </label>
                    </div>
                  }

                  <button
                    className="button button1"
                    onClick={() => ExportExcel()}
                  >
                    Export to Excel
                  </button>
                </div>
              </div>
              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={patients}
                    page={page}
                    perpage={perPage}
                    setparpage={setPerPage}
                    setpage={setPage}
                    totalData={totalData}
                    setSelectedRows={setSelectedRows}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {ordersListData?.map((items, index) => {
                      return (
                        <OrderCard
                          key={index}
                          data={items}
                          setSelectedRows={setSelectedRows}
                          selectedRows={selectedRows}
                        />
                      );
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
   

{/*  changes dispensing to shipped */}
      {show && (
        <ShipingTrackNumber
          show={show}
          onHide={() => setShow(false)}
          id={id}
          page={page}
          perpage={perPage}
          getdata={getData}
          status={status}
        />
      )}
    </>
  );
};

export default Orders;
