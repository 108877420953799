import React from 'react'
import './AddSubAdmin.css'
import { Row, Col, Modal, Form } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { ApiPost } from '../../../helpers/API/API_data';
import { ErrorToast } from '../../../helpers/Toast';
import { subAdminAddSchema } from '../../../Schema/schema';
import { appContext } from '../../../helpers/AppContext';
import { useContext } from 'react';
const AddSubAdmin = ({open, setopen=()=>{}, setDataRefresh=()=>{}}) => {
    const { setLoader} = useContext(appContext);  
  // react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(subAdminAddSchema),
  });

// handle submit subadmin data
  const submitdata = (data) => {
    setLoader(true)
    delete data?.confirmPassword;
    const body = {
      ...data,
      gender: Number(data?.gender),
      userType: Number(data?.userType)
    };
    

    ApiPost("admin/subadmin/add", body)
    .then(async(res)=>{
        if (res?.data?.data?.response) {
            setopen(false)
            setDataRefresh((prevState) => !prevState);
        }
      return res;
    })
    .catch((e) => {
      ErrorToast(e?.message);
    }).finally(()=>{
        setLoader(false)
    })
  };
  
  return (
    <Modal
    size='xl'
      title="Basic Modal"
      centered
      show={open}
      onHide={() => {
        setopen(false);
      }}
    >
      <Modal.Header closeButton className="modal_header">
        <Modal.Title id="contained-modal-title-vcenter">
          Add Sub-Admin
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="medicinlist doctor_mediation">
          {/* form to creat sub-admin */}
          <Form>
            <Row>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">FirstName *</span>
                  <input
                    type="text"
                    placeholder="Enter Your FirstName"
                    name="firstName"
                    autoComplete="off"
                    {...register("firstName")}
                  />
                  {errors?.firstName && (
                    <p style={{ color: "red" }}>{errors?.firstName?.message}</p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">LastName *</span>
                  <input
                    type="text"
                    placeholder="Enter Your LastName"
                    name="lastName"
                    autoComplete="off"
                    {...register("lastName")}
                  />
                  {errors?.lastName && (
                    <p style={{ color: "red" }}>{errors?.lastName?.message}</p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Gender *</span>
                  <select
                    name="gender"
                    {...register("gender")} 

                  >
                    <option value={0}>Male</option>
                    <option value={1}>Female</option>
                    <option value={2}>Other</option>
                  </select>
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Role *</span>
                  <select
                    name="userType"
                    {...register("userType")} 

                  >
             
                    <option value={4}>Pharmacy</option>
                    <option value={3}>Sub-Admin</option>
                  </select>
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Email *</span>
                  <input
                    type="text"
                    placeholder="Enter Your Email"
                    name="email"
                    autoComplete="off"
                    {...register("email")}
                  />
                  {errors?.email && (
                    <p style={{ color: "red" }}>{errors?.email?.message}</p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Contact Number *</span>
                  <input
                    type="text"
                    placeholder="Enter Contact Number"
                    name="contactNumber"
                    autoComplete="off"
                    {...register("contactNumber")}
                  />
                  {errors?.contactNumber && (
                    <p style={{ color: "red" }}>{errors?.contactNumber?.message}</p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Password *</span>
                  <input
                    type="text"
                    placeholder="Enter Password"
                    name="password"
                    autoComplete="off"
                    {...register("password")}
                  />
                  {errors?.password && (
                    <p style={{ color: "red" }}>{errors?.password?.message}</p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Confirm Password *</span>
                  <input
                    type="text"
                    placeholder="Enter Confirm Password"
                    name="confirmPassword"
                    autoComplete="off"
                    {...register("confirmPassword")}
                  />
                  {errors?.confirmPassword && (
                    <p style={{ color: "red" }}>{errors?.confirmPassword?.message}</p>
                  )}
                </div>
              </Col>
              <div className="btnredsubmit addBtnSubmit">
                <Link
                  to=""
                  className="assignDoctor_btn modal_submit"
                  onClick={handleSubmit((data) => {
                    submitdata(data);
                  })}
                >
                  SUBMIT
                </Link>
              </div>
            </Row>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddSubAdmin