import "./TreatmentList.css";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiDelete, ApiPost } from "../../helpers/API/API_data";
import { SuccessToast } from "../../helpers/Toast";
import DashHeader from "../../Component/DashHeader";
import { FaUserEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useContext } from "react";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../Component/Pagination";
import TreatMentCard from "../../Component/tableCard/TreatMentCard";
import { isPharmacyUser } from "../../utils/user.util";
import { CSVLink } from "react-csv";

const TreatmentList = () => {
  const [coupon, setCoupons] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [excel, setExcel] = useState([]);
  const userData = JSON.parse(localStorage?.getItem("userData"));
  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);
  const linkRef = useRef(null);

  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  const history = useHistory();

  const headers = [
    { label: "Item No", key: "itemno" },
    { label: "Treatment Name", key: "treatmentName" },
    { label: "Condition", key: "condition" },
    { label: "Provider", key: "provider" },
    { label: "Drug Name", key: "drugName" },
    { label: "Quantity", key: "quantity" },
    { label: "Form", key: "form" },
    { label: "Price", key: "price" },
    { label: "Wholesale Price", key: "wholeSalePrice" },
  ];

  const columns = [
    {
      headerName: "Item No",
      field: "itemno",
    },
    {
      headerName: "Treatment Name",
      field: "treatmentName",
    },
    {
      headerName: "Condition",
      field: "condition",
    },
    {
      headerName: "Provider",
      field: "provider",
      cellRenderer: (params) => {

        return (
          <span>
            {" "}
            {params?.data?.provider?.length > 0
              ? params?.data?.provider?.map((ele, i) => (
                <>
                  <div>
                    {ele}
                    <span>{params?.data?.provider?.length !== i + 1 && ", "}</span>
                  </div>
                </>
              ))
              : "-"}
          </span>
        );
      },
    },
    {
      headerName: "Drug Name",
      field: "drugName",
    },
    {
      headerName: "Quantity",
      field: "quantity",
    },
    {
      headerName: "Form",
      field: "form",
    },
    {
      name: "Price",
      field: "price",
    },
    {
      name: "Wholesale Price",
      field: "wholeSalePrice",
    },

    {
      name: "Action",
      field: "Action",
      cellRenderer: (d) => {
        //
        return (
          <span>
            <FaUserEdit
              size={20}
              role="button"
              onClick={() => history.push(`/UpdateTreatment/${d?.data?._id}`)}
            />
            &nbsp;&nbsp;{" "}
            <AiFillDelete
              size={20}
              role="button"
              onClick={() => deleteTreatment(d?.data?._id)}
            />
          </span>
        );
      },
    },
  ];

  // Remove the first object from the array in pharmacy user
  if (isPharmacyUser(userData)) {
    columns.pop(); 
  }

  // modify treatment data
  const treatmentListData = coupon?.map((treatments) => {
    return {
      id: treatments?._id,
      treatmentName: treatments?.treatmentName,
      itemNo: treatments?.itemno,
      condition: treatments?.condition,
      provider: treatments?.provider?.length > 0
        ? treatments?.provider?.map((ele, i) => (
          <div>
            {ele}
            <span>
              {treatments?.provider?.length !== i + 1 && ", "}
            </span>
          </div>
        ))
        : "-",
      // provider: treatments?.provider[0],
      drugName: treatments?.drugName,
      quantity: treatments?.quantity,
      form: treatments?.form,
      price: treatments?.price,
      editTreatment: (
        <FaUserEdit
          size={20}
          role="button"
          onClick={() => history.push(`/UpdateTreatment/${treatments?._id}`)}
        />
      ),
      deleteTreatment: (
        <AiFillDelete
          size={20}
          role="button"
          onClick={() => deleteTreatment(treatments?._id)}
        />
      ),
    };
  });

  const searchdata = search.trim().split(/ +/).join(" ");

  // get treatment data
  const getData = async (body) => {
    setLoader(true);
    await ApiPost("admin/main_treatment/get", body)
      .then((res) => {
        setLoader(false);
        setCoupons(
          res?.data?.data?.treatment_data?.map((ele, i) => {

            return {
              itemno: (page - 1) * perpage + (i + 1),
              _id: ele?._id,
              treatmentName: ele?.treatmentName ? ele?.treatmentName : "-",
              condition: ele?.condition ? ele?.condition : "-",
              provider: ele?.provider,
              // provider: ele?.provider ? ele?.provider : "-",
              drugName: ele?.drugName ? ele?.drugName : "-",
              quantity: ele?.quantity ? ele?.quantity : "-",
              form: ele?.form ? ele?.form : "-",
              price: ele?.price ? ele?.price : "-",
              wholeSalePrice: ele?.wholeSalePrice ? ele?.wholeSalePrice : "-",
              // id: ele?._id ? ele?._id : '-'
            };
          })
        );
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const bodyData = {
    page: page,
    limit: Number(perpage),
    search: searchdata,
  };
  useEffect(() => {
    getData(bodyData);
  }, [page, perpage]);

  // handle search data
  const handleSearch = (searchData) => {
    if (searchData.length === 0) {
      getData({ ...bodyData, search: "" });
    }
    setSearch(searchData);
  };

    // handle search data on submit
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getData(body);
  };
 
  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata])

//  delete treatment
  const deleteTreatment = async (id) => {
    await ApiDelete(`/main_treatment/${id}`).then((res) => {
      const body = {
        ...bodyData,
        search: searchdata,
      };
      getData(body);
      SuccessToast(res?.data?.message);
    });
  };

  // sidebar responsiveness
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  // export csv
  const ExportExcel = () => {
    const body ={
      page: 1,
      search: searchdata
    }
    ApiPost(`admin/main_treatment/get`, body)
      .then((res) => {
        setExcel(
          res?.data?.data?.treatment_data?.map((ele, i) => {

            return {
              itemno: (page - 1) * perpage + (i + 1),
              _id: ele?._id,
              treatmentName: ele?.treatmentName ? ele?.treatmentName : "-",
              condition: ele?.condition ? ele?.condition : "-",
              // provider: ele?.provider,
              provider: ele?.provider ? ele?.provider : "-",
              drugName: ele?.drugName ? ele?.drugName : "-",
              quantity: ele?.quantity ? ele?.quantity : "-",
              form: ele?.form ? ele?.form : "-",
              price: ele?.price ? ele?.price : "-",
              wholeSalePrice: ele?.wholeSalePrice ? ele?.wholeSalePrice : "-",
              // id: ele?._id ? ele?._id : '-'
            };
          })
        );
      })
      .catch((e) => { });
  };
  return (
    <>
      <div className="chatscreen admindashboard">
        {/* <div className="loader"> */}
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Treatment List</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"Treatment"}
                  headers={headers}
                ></CSVLink>
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="Input search text"
                      className="pe-3 search search1"
                      value={search}
                      name="search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn searchbtn1 d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>

                  {!isPharmacyUser(userData) && (
                    <Link className="button button1" to="/AddTreatment">
                      Add Treatment
                    </Link>
                  )}

                  <button
                    className="button button1"
                    onClick={() => ExportExcel()}
                  >
                    Export to Excel
                  </button>
                </div>
              </div>
              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={coupon}
                    page={page}
                    perpage={perpage}
                    setparpage={setPerPage}
                    setpage={setPage}
                    totalData={totalData}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {treatmentListData?.map((items, index) => {
                      return <TreatMentCard key={index} data={items} />;
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
        {/* </div> */}
      </div>
    </>
  );
};

export default TreatmentList;
