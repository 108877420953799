import "./AddCoupons.css";
import React, {useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import DashHeader from "../../Component/DashHeader";
import { ApiPost } from "../../helpers/API/API_data";
import { SuccessToast } from "../../helpers/Toast";
import Select from "react-select";
import {  ApiPut,} from "../../helpers/API/API_data";
import moment from "moment";
const UpdateCoupons = (props) => {
  const [data, setdata] = useState({});
  const id = window.location.pathname?.split("/")[2];
  const history = useHistory();

  // get coupon data
  const getData = async () => {
    const body = {
      page: 1,
      limit: 100,
    };
    await ApiPost("admin/coupon", body)
      .then((res) => {
        const couponsdata = res?.data?.data?.coupon_data?.find(
          (ele) => ele._id === id
        );
        setdata({
          ["couponCode"]: couponsdata?.couponCode,
          ["usePerCoupon"]: couponsdata?.usePerCoupon,
          ["userPerCustomer"]: couponsdata?.userPerCustomer,
          ["customerId"]: couponsdata?._id,
          ["status"]: couponsdata?.status,
          ["minimum_purchase_requirements"]:
            couponsdata?.minimum_purchase_requirements,
          ["startDate"]: moment(new Date(couponsdata.startDate)).format(
            "YYYY-MM-DD"
          ),
          ["endDate"]: moment(new Date(couponsdata.endDate)).format(
            "YYYY-MM-DD"
          ),
          ["discount"]: couponsdata?.discount,
          ["couponName"]: couponsdata?.couponName,
          ["conditionType"]: condition.filter((ele) =>
            couponsdata?.conditionType.includes(ele?.value)
          ),
          ["purchaseType"]: couponsdata?.purchaseType,
          ["discountType"]: couponsdata?.discountType.toString(),
        });
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getData();
  }, []);
  const condition = [
    { label: "Erectile Dysfunction", value: 0 },
    { label: "Premature Ejaculation", value: 1 },
    { label: "STI Screening", value: 2 },
    { label: "Thrush / Bacterial Vaginosis", value: 5 },
    { label: "Urinary Tract Infection", value: 6 },
    { label: "All", value: 7 },
  ];

  // hanlde change input values
  const handleChange = (e) => {
    const { name, value } = e.target;

    setdata({
      ...data,
      [name]: value,
    });
  };
  const handleChangeselect = (e) => {
    setdata({
      ...data,
      conditionType: e,
    });
  };

  // handle submit data
  const submitdata = (data) => {
    const body = {
      id: id,
      couponCode: data?.couponCode.toString(),
      discountType: parseInt(data?.discount),
      usePerCoupon: parseInt(data?.usePerCoupon),
      userPerCustomer: data?.userPerCustomer,
      status: parseInt(data?.status),
      minimum_purchase_requirements: parseInt(
        data?.minimum_purchase_requirements
      ),
      startDate: data?.startDate,
      endDate: data?.endDate,
      couponName: data?.couponName,
      discount: parseInt(data?.discount),
      conditionType: data?.conditionType.map((ele) => ele?.value),
      purchaseType: parseInt(data?.purchaseType),
      discountType: parseInt(data?.discountType),
    };

    ApiPut("admin/update/coupon", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        history.push("/CouponeList");
      })
      .catch((e) => {});
  };

  // sidebar responsiveness
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);
  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <DashHeader></DashHeader>
            <div className="activepatient">
              <div className="titlepatient">
                <h4>Update COUPONS</h4>
              </div>
              {/* update token input fields */}
              <div className="couponsfield">
                <div className="ui form">
                  <div className="two fields gap-2">
                    <div className="field start-date">
                      <label>Start date</label>
                      <div className="ui calendar" id="rangestart">
                        <div className="ui input left icon">
                          <input
                            name="startDate"
                            type="date"
                            placeholder="Start"
                            pattern="\d{10}"
                            value={data?.startDate}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field end-date">
                      <label>End date</label>
                      <div className="ui calendar" id="rangeend">
                        <div className="ui input left icon">
                          <input
                            name="endDate"
                            type="date"
                            value={data?.endDate}
                            placeholder="End"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <label>Coupons Name</label>
                      <input
                        name="couponName"
                        value={data?.couponName}
                        onChange={handleChange}
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <Row>
                    <Row>
                      <Col lg={4}>
                        <div className="field">
                          <label>Coupons Code</label>
                          <input
                            name="couponCode"
                            value={data?.couponCode}
                            onChange={handleChange}
                            type="text"
                            placeholder=""
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="field">
                          <label>Discount</label>
                          <input
                            name="discount"
                            value={data?.discount}
                            type="number"
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="field">
                          <label>Discount Type</label>
                          <select
                            name="discountType"
                            value={data?.discountType}
                            onChange={handleChange}
                          >
                            <option value={0}>Amount</option>
                            <option value={1}>Percentage</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="field">
                        <label>Purchase type</label>
                        <select
                          name="purchaseType"
                          value={data?.purchaseType}
                          onChange={handleChange}
                        >
                          <option value={0}>Consult fee</option>
                          <option value={1}>First treatment</option>
                          <option value={2}>On-going treatment</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="field">
                        <label>Status</label>
                        <select
                          name="status"
                          value={data?.status}
                          onChange={handleChange}
                        >
                          <option value={1}>Active</option>
                          <option value={0}>InActive</option>
                        </select>
                      </div>
                    </Col>
                    {}
                    <Col lg={12}>
                      <div className="field">
                        <label>condition</label>
                        <Select
                          name="conditionType"
                          value={data?.conditionType}
                          onChange={(e) => {
                            handleChangeselect(e);
                          }}
                          options={condition}
                          isMulti={true}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="field">
                        <label>Use per Coupon</label>
                        <select
                          name="usePerCoupon"
                          value={data?.usePerCoupon}
                          onChange={handleChange}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>No Limit</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="field">
                        <label>Minimum purchase requirements</label>
                        <input
                          name="minimum_purchase_requirements"
                          type="number"
                          placeholder="AMOUNT"
                          value={data?.minimum_purchase_requirements}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="field">
                        <label>Use per Customer </label>
                        <select
                          name="userPerCustomer"
                          value={data?.userPerCustomer}
                          onChange={handleChange}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>No Limit</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <div className="btnredsubmit mt-0">
                    <Button onClick={() => submitdata(data)}>Save</Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UpdateCoupons;
