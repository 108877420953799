/**
 * The `ViewDoctorCard` component renders a card with information about a doctor and allows the user to
 * select the card using a checkbox.
 * @returns The `ViewDoctorCard` component is returning JSX elements. Specifically, it is returning a
 * list item (`<li>`) with nested divs and text elements. The content of the list item is dynamically
 * generated based on the `data` prop passed to the component. The component also includes a checkbox
 * from the `antd` library for selecting the item.
 */
import { Checkbox } from "antd";
import React from "react";

const ViewDoctorCard = ({ data }) => {
  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) {
      setIsCheck([...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head d-flex justify-content-between">
          <div className="d-flex">
            <div
              style={{
                marginRight: "8px",
              }}
            >
              <Checkbox
                checked={isCheck.includes(data?.id)}
                onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
              />
            </div>
            <h6 className="consulation_list_li__head">{data?.name}</h6>
          </div>
        </div>
        <div className="consulation_list_li_data_inner">
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Condition</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.condition}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Date</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.date}
              </h6>
            </div>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner mb-0"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Consult Status
            </h6>
            <div className="consulation_list_li_data_inner_head_data">
              {data?.status}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default ViewDoctorCard;
