import React, { useEffect, useState } from "react";
import history from "../../Assets/images/history.png";
import add from "../../Assets/images/addfile.png";
import "./Dashboard.css";
import { ApiGet, ApiPost, ApiPut, ApiUploadPost } from "../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import Lightbox from "react-image-lightbox";
import { useContext } from "react";
import { appContext } from "../../helpers/AppContext";
import "react-image-lightbox/style.css";
import Loader from "../../Component/loader/Loader";

function Document({ patientdataid }) {
  const [documentget, setdocumentget] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const { loader, setLoader } = useContext(appContext);

  const fileUpload = (e) => {
    setLoader(true);
    let extensions = e.target.files[0].type;
    let extensionsValidation = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    if (extensionsValidation.includes(extensions)) {
      if (e?.target?.files && e?.target?.files[0]) {
        let formData = new FormData();
        formData.append(
          "image",
          e.target /* In the code snippet, `files` is a property of the
        `event.target` object. It is used to access the files that
        were selected by the user in the file input field. */.files[0]
        );
        ApiUploadPost(`upload/document`, formData)
          .then((res) => {
            
            setLoader(false);
            SuccessToast("document upload successfully");
            if (res.status === 200) {
              const body = {
                _id: patientdataid,
                documents: [res?.data?.data?.image],
              };
              ApiPut(`admin/upload_patient_document`, body)
                .then((res) => {
                  if (res.data.status === 200) {
                    getdata();
                  }
                })
                .catch((e) => {
                  
                });
            }
          })
          .catch((error) => {
            setLoader(false);
            
          });
      }
    } else {
      setLoader(false);
      ErrorToast("Please select valid document file");
    }
  };

  const getdata = () => {
    ApiGet(`admin/patient_document/${patientdataid}`)
      .then((res) => {
        setdocumentget(res?.data?.[0]?.documents);
      })
      .catch((e) => {
        
      });
  };

  useEffect(() => {
    patientdataid && getdata();
  }, [patientdataid]);
  const HandleView = (data) => {
    const pdfData = data
      ?.split("/")
      ?.[data?.split("/")?.length - 1].split(".")?.[1];
    if (pdfData === "pdf" || pdfData === "docx") {
      window.open(data);
    } else if (pdfData !== "pdf" && pdfData !== "docx") {
      setData(data);
      setIsOpen(true);
    }
  };

  return (
    <div>
      <div className="mytreatmentd">
        <div className="profilepatient">
          <h4>Patient Documents </h4>
        </div>
        <div className="upload" style={{ cursor: "pointer" }}>
          <div className="file_img">
            <img src={add} alt="" />
            <p>Upload a document</p>
          </div>
          <input
            type="file"
            onChange={(e) => fileUpload(e)}
            onClick={(e) => (e.currentTarget.value = null)}
          />
        </div>
        <ul
          className="uploaddata"
          style={{ height: `${documentget?.length > 0 ? "99px" : "30px"}` }}
        >
          {documentget?.length > 0 ? (
            documentget.reverse().map((vel, i) => {
              return (
                <li key={`${i}${vel}`}>
                  <div
                    className="uploaddetail d-flex"
                    onClick={() => HandleView(vel)}
                  >
                    <div>
                      <img src={history} alt="" />
                    </div>
                    <div>
                      <p className="uploadget ms-1">
                        {vel.split("/").at(-1).split("_")[0]}
                      </p>
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <span
              className="d-flex justify-content-center"
              style={{ fontFamily: "Helvetica Neue" }}
            >
              There are no documents
            </span>
          )}
        </ul>
        {isOpen && (
          <Lightbox
            mainSrc={data}
           
            onCloseRequest={() => setIsOpen(false)}
           
          />
        )}
      </div>

      {loader && <Loader />}
    </div>
  );
}

export default Document;
