/**
 * The `PatientsCard` component is a React functional component that renders a card for displaying
 * patient data in a table list format.
 * @returns The `PatientsCard` component is returning a JSX element.
 */
import React from "react";
import "./tableList.css";
import { Checkbox } from "antd";
import moment from "moment";

export const PatientsCard = ({ data }) => {
  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head flex">
          <div
            style={{
              marginRight: "8px",
            }}
          >
            <Checkbox
              checked={isCheck.includes(data?.id)}
              onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
            />
          </div>
          <h6 className="consulation_list_li__head">{data?.patientName}</h6>
        </div>
        <div className="consulation_list_li_data_inner">
        <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head"> Created At</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.createdAt}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">No.</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.no}
            </h6>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">Condition</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.questionType}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Consult Status
            </h6>
            {data?.patient_question === 4 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#a52a2a1a", color: "#a52a2a" }}
              >
                {"Treatment Plan Sent"}
              </h6>
            ) : data?.patient_question === 1 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#6abddf1a", color: "#6ABDDF" }}
              >
                {"Paid Unassigned Doctor"}
              </h6>
            ) : data?.patient_question === 2 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#220a0a1a", color: "#220a0a" }}
              >
                {"Paid Unfulfilled"}
              </h6>
            ) : data?.patient_question === 3 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#0080001a", color: "#008000" }}
              >
                {"Live Consult"}
              </h6>
            ) : data?.patient_question === 0 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#ff00001a", color: "#ff0000" }}
              >
                {"Not Paid"}
              </h6>
            ) : data?.patient_question === 5 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#045fbf1a", color: "#045fbf" }}
              >
                {"Awaiting Pathology"}
              </h6>
            ) : (
              "-"
            )}
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Treatment Status:
            </h6>
            {data?.treatmentStatus === 0 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#ff00001a", color: "#ff0000" }}
              >
                {"Not Assigned"}
              </h6>
            ) : data?.treatmentStatus === 1 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#220a0a1a", color: "#220a0a" }}
              >
                {"Pending"}
              </h6>
            ) : data?.treatmentStatus === 2 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#3e00001a", color: "#3e0000" }}
              >
                {"In Progress"}
              </h6>
            ) : data?.treatmentStatus === 3 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#3e00001a", color: "#3e0000" }}
              >
                {"Completed"}
              </h6>
            ) : (
              "-"
            )}
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Payment Timestemp
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.paymentTime && moment(data?.paymentTime).format("LLL")}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Gender</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.gender === 0
                  ? "Male"
                  : data?.gender === 1
                  ? "Female"
                  : data?.gender === 2
                  ? "Intersex"
                  : "-"}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Gender Identity
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.identityGender ? data?.identityGender : "-"}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Date of birth
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.additionalInfo
                  ? data?.additionalInfo?.replaceAll("-", "/")
                  : "-"}
              </h6>
            </div>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Preference On Gender Of Treating
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.preferenceGender}
            </h6>
          </div>
          {/* <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Consider Cannabis Treatment/s
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.considerCannabis}
            </h6>
          </div> */}
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">Timestamp</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.timeStamp}
            </h6>
          </div>
          <div className="assign_doctor_button">{data?.assignDoctor}</div>
        </div>
      </li>
    </>
  );
};
