/**
 * The DoctorCard component is a reusable component that displays information about a doctor and
 * provides options to edit, delete, and view the doctor's details.
 * @returns The `DoctorCard` component is being returned.
 */
import React from "react";
import { MenuIcon } from "../Icon";
import { Checkbox, Dropdown } from "antd";
import { FaUserEdit } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { useState } from "react";
import DeleteDoctor from "../modals/DeleteDoctor";
import { ViewDoctor } from "../../Page/DoctorList/DoctorList";

const DoctorCard = ({ data, getData }) => {
  const [isCheck, setIsCheck] = React.useState([]);
  const [open, setopen] = useState(false);
  const [id, setId] = useState("");
  const [viewOpen, setViewOpen] = useState(false);
  const [viewData, setViewData] = useState({});
  const history = useHistory();

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  const items = [
    {
      label: (
        <div className="d-flex gap-2">
          <FaUserEdit
            size={20}
            role="button"
            onClick={() => history.push(`/Update-Doctor/${data?.id}`)}
          />
          Edit
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="d-flex gap-2">
          <AiFillDelete
            size={20}
            role="button"
            onClick={() => {
              setopen(true);
              setId(data?.id);
            }}
          />
          Delete
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="d-flex gap-2">
          <AiOutlineEye
            size={20}
            role="button"
            onClick={() => {
              setViewOpen(true);
              setViewData(data?.data);
            }}
          />
          View
        </div>
      ),
      key: "2",
    },
  ];

  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head d-flex justify-content-between">
          <div className="d-flex">
            <div
              style={{
                marginRight: "8px",
              }}
            >
              <Checkbox
                checked={isCheck.includes(data?.id)}
                onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
              />
            </div>
            <h6 className="consulation_list_li__head">{data?.name}</h6>
          </div>
          <div className="action_menu_button">
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              trigger={["click"]}
            >
              <button
                className="menu_button_on_click"
                onClick={(e) => e.preventDefault()}
              >
                <MenuIcon />
              </button>
            </Dropdown>
          </div>
        </div>
        <div className="consulation_list_li_data_inner">
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Contact Details
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.contactNumber}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Gender Identity
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.gender}
              </h6>
            </div>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Condition Specialities
            </h6>
            <div className="consulation_list_li_data_inner_head_data">
              {data?.conditionSpecialties}
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Prescriber No
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.prescriberNumber}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Available Day
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {/* {data?.schedule} */}
                <div>
          <div>
          {data?.schedule?.monday ? "Monday" : ""}
          
          </div>
          <div>
          {data?.schedule?.tuesday ? "Tuesday" : ""}
      
          </div>
          <div>
          {data?.schedule?.wednesday ? "Wednesday" : ""}
          
          </div>
          <div>
          {data?.schedule?.thursday ? "Thursday" : ""}
     
          </div>
          <div>
          {data?.schedule?.friday ? "Friday" : ""}
    
          </div>
          <div>
          {data?.schedule?.saturday ? "Saturday" : ""}
          
          </div>
          <div>
          {data?.schedule?.sunday ? "Sunday" : ""}
        
          </div>
        </div>
              </h6>
            </div>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Dr. Response Time
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.duration}
            </h6>
          </div>
        </div>
      </li>

      {open && (
        <DeleteDoctor
          show={open}
          doctorId={id}
          onHide={() => setopen(false)}
          getDatas={(bodydata) => getData(bodydata)}
        />
      )}

      {/* Model */}
      <ViewDoctor
        viewOpen={viewOpen}
        setViewOpen={setViewOpen}
        viewData={viewData}
      />
    </>
  );
};

export default DoctorCard;
  
