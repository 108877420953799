
import "./UpdateDoctor.css";
import React, { useEffect, useRef, useState } from "react";
import profile from "../../Assets/images/editprofile.png";
import edit from "../../Assets/images/pencile.png";
import SideDrawer from "../SideDrawer/SideDrawer";
import { Row, Col } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { Link, useHistory } from "react-router-dom";
import { ApiPost, ApiPut, ApiUploadPost, Bucket } from "../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import moment from "moment";
import DashHeader from "../../Component/DashHeader";
import { checkKeyLength, convertStringToDate, hasNull, removeBothNullObj, transformScheduleData } from "../../utils/scheduletime.util";
import SetUpAppoinmentSchedule from "../../Component/calendar/doctorScheduleedit/DoctorScheduleEdit";
const UpdateDoctor = () => {
  const history = useHistory();
  const [data, setdata] = useState({});
  const [profileImage, setprofileImage] = useState([]);
  const [checked, setChecked] = useState();
  const [selectedTimes, setSelectedTimes] = useState({});
  const [checkboxStates, setCheckboxStates] = useState({
    isSunday: false,
    isMonday: false,
    isTuesday: false,
    isWednesday: false,
    isThursday: false,
    isFriday: false,
    isSaturday: false,
  });
  const [addressdata, setAddressdata] = useState();
  const [postalcode, setPostalcode] = useState();
  const inputRef = useRef(null);
  const [documentImg, setDocumnetImg] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    getData();
    const CUSTOMlink = document.createElement("link");
    CUSTOMlink.rel = "stylesheet";
    CUSTOMlink.type = "text/css";
    CUSTOMlink.href = `./../font/stylesheet.css`;
    document.head.appendChild(CUSTOMlink);
  }, []);

// get doctor by doctor id
  const getData = async () => {
    const body = {
      doctorId: window.location.pathname?.split("/")[2],
    };
    await ApiPost("admin/doctor/by_id", body)
      .then((res) => {
        const data = res?.data?.data[0]
        setdata({
          ["symptoms"]: res?.data?.data[0]?.symptoms[0],
          ...res?.data?.data[0],

          ...res?.data?.data[0]?.schedule,
          ["medicareNo"]: res?.data?.data[0]?.additionalInfo?.prescriberNo,
          ["frenchieProviderNumber"]:
            res?.data?.data[0]?.additionalInfo?.frenchieProviderNumber,
          ["conditionSpecialities"]:
            res?.data?.data[0]?.additionalInfo?.conditionSpecialities,
          ["prescriberNumber"]:
            res?.data?.data[0]?.additionalInfo?.prescriberNumber,
          ["pronouns"]: res?.data?.data[0]?.additionalInfo?.pronouns,
          ["gender"]: res?.data?.data[0]?.gender,
          ["othersymptom"]: res?.data?.data[0]?.additionalInfo?.othersymptom[0],
          ["bio"]: res?.data?.data[0]?.additionalInfo?.bio,
          ["dob"]: res?.data?.data[0]?.additionalInfo?.dob,
          ["genderIdentify"]: res?.data?.data[0]?.genderIdentify,
        });
        setDocumnetImg(res?.data?.data[0]?.digitalSign);
     
        setSelectedTimes(convertStringToDate(data?.originalSchduleData
          ));
          setCheckboxStates({
            ...checkboxStates,
            isMonday: data?.schedule?.isMonday,
            isSunday: data?.schedule?.isSunday,
            isTuesday: data?.schedule?.isTuesday,
            isWednesday: data?.schedule?.isWednesday,
            isThursday: data?.schedule?.isThursday,
            isFriday: data?.schedule?.isFriday,
            isSaturday: data?.schedule?.isSaturday,
          });
        if (res?.data?.data[0]?.additionalInfo?.conditionSpecialities)
          setChecked([
            ...res?.data?.data[0]?.additionalInfo?.conditionSpecialities,
          ]);
        else setChecked([]);
      })
      .catch((e) => { });
  };

  // handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setdata({
      ...data,
      [name]: value,
    });
  };

  // validate shedule response
  const handleSubmit =  () => {
    const transformData = transformScheduleData(selectedTimes);
  
    if (!checkKeyLength(selectedTimes)) {
      ErrorToast("Please Select Time And Day");
    } else if (hasNull(selectedTimes)) {
      ErrorToast("Start time and end time both are required");
    } else {
      const removedBothNull = removeBothNullObj(selectedTimes);
      for (const day in removedBothNull) {
        if (removedBothNull[day].length === 0) {
          delete removedBothNull[day];
        }
      }
    return {
        schedule: {
          ...transformData,
          ...checkboxStates,
        },
        originalSchduleData: removedBothNull,
      };

    }
  };

  // submit doctor updated data
  const saveBtn = async () => {
    const formData = new FormData();
    formData.append("image", profileImage[0]);
    await ApiUploadPost("upload/profile_image", formData)
      .then(async (res1) => {
        const schedulebody =  handleSubmit()
      if (schedulebody) {
        let body = {
          id: window.location.pathname?.split("/")[2],
          firstName: data?.firstName,
          lastName: data?.lastName,
          gender: data?.gender,
          genderIdentify: data?.genderIdentify,
          address: data?.address,
          postcode: data?.postcode,
          additionalInfo: {
            pronouns: data?.pronouns,
            conditionSpecialities: checked,
            clinicContactNumber: 0,
            prescriberNumber: data?.prescriberNumber,
            dob: data?.dob,
            bio: data?.bio,
            othersymptom: data?.othersymptom,
          },
          contactNumber: data?.contactNumber,
          image: res1?.data?.data?.image,
          digitalSign: documentImg,
          address: data?.address,
          symptoms: [data?.symptoms],
          ...schedulebody,
        };
        await ApiPut("admin/doctor", body)
          .then((res) => {
            setdata(res?.data?.data);
            SuccessToast(res?.data?.message);
            history.push("/DoctorList");
          })
          .catch((e) => {
            ErrorToast(e?.message);
          });
      }
       
      })
      .catch((e) => { });
  };
  const handleimage = (e) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;

    setprofileImage([file]);
  };

  // handle checkbox
  const handleChangeCheckbox = (e) => {
    if (checked?.includes(e.target.value))
      setChecked(checked?.filter((item) => item !== e.target.value));
    else setChecked([...checked, e.target.value]);
  };

  useEffect(() => {
    setdata({
      ...data,
      address: addressdata,
      postcode: postalcode,
    });
  }, [addressdata, postalcode]);

  useEffect(() => {
    if (window) {
      autoCompleteRef.current =
        window.google?.maps &&
        new window.google.maps.places.Autocomplete(inputRef.current);
      autoCompleteRef.current?.addListener("place_changed", handlePlaceChanged);
    }
  }, []);

  const handlePlaceChanged = () => {
    const place = autoCompleteRef.current.getPlace();
    const postalcode = place.address_components.find((ele) =>
      ele.types.includes("postal_code")
    ).long_name;
    setPostalcode(postalcode);
    const Addressdata =
      place.name +
      place.address_components
        .map((item, i) =>
          i !== 0 && i !== place.address_components.length - 1
            ? item.long_name
            : ""
        )
        .toString();
    setAddressdata(Addressdata);
  };

  // handle upload signature
  const uploadDocument = (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    ApiUploadPost(`upload/document`, formData)
      .then((res) => {
        setDocumnetImg(res?.data?.data?.image);
      })
      .catch((error) => { });
  };

  // handle sidebar responsiveness
  const [screenSize, setScreenSize] = useState(0);
  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      <div className="chatscreen admindashboard update_doctor_main_section">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <DashHeader />
            <div className="activepatient">
              <div className="titlepatient adddoctor">
                <h4>Doctor Profile</h4>
              </div>
              <div
                className="editprofiledetail setpro"
                style={{ marginTop: "0px" }}
              >
                <div className="user-avatar position-relative text-center">
                  <img
                    src={
                      profileImage[0]?.fileURL
                        ? profileImage[0]?.fileURL
                        : data.image
                          ? Bucket + data?.image
                          : profile
                    }
                    height={150}
                    width={150}
                    alt="image"
                    className="br-50"
                  />
                  <div className="position-absolute labelFile">
                    <label htmlFor="images" className="">
                      <div className="editIcon">
                        <img src={edit} alt="" />
                      </div>
                      <input
                        type="file"
                        id="images"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        hidden
                        onChange={(e) => handleimage(e)}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {/* form to update doctor */}
              <Row style={{ height: "fit-content" }}>
                <div
                  className="editprofiledetail setpro"
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                >
                  <Row>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="12"
                            height="17"
                            viewBox="0 0 12 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 16.5H10.5V15C10.5 14.4033 10.2629 13.831 9.84099 13.409C9.41903 12.9871 8.84674 12.75 8.25 12.75H3.75C3.15326 12.75 2.58097 12.9871 2.15901 13.409C1.73705 13.831 1.5 14.4033 1.5 15V16.5H0V15C0 14.0054 0.395088 13.0516 1.09835 12.3483C1.80161 11.6451 2.75544 11.25 3.75 11.25H8.25C9.24456 11.25 10.1984 11.6451 10.9017 12.3483C11.6049 13.0516 12 14.0054 12 15V16.5ZM6 9.75C5.40905 9.75 4.82389 9.6336 4.27792 9.40746C3.73196 9.18131 3.23588 8.84984 2.81802 8.43198C2.40016 8.01412 2.06869 7.51804 1.84254 6.97208C1.6164 6.42611 1.5 5.84095 1.5 5.25C1.5 4.65905 1.6164 4.07389 1.84254 3.52792C2.06869 2.98196 2.40016 2.48588 2.81802 2.06802C3.23588 1.65016 3.73196 1.31869 4.27792 1.09254C4.82389 0.866396 5.40905 0.75 6 0.75C7.19347 0.75 8.33807 1.22411 9.18198 2.06802C10.0259 2.91193 10.5 4.05653 10.5 5.25C10.5 6.44347 10.0259 7.58807 9.18198 8.43198C8.33807 9.27589 7.19347 9.75 6 9.75ZM6 8.25C6.79565 8.25 7.55871 7.93393 8.12132 7.37132C8.68393 6.80871 9 6.04565 9 5.25C9 4.45435 8.68393 3.69129 8.12132 3.12868C7.55871 2.56607 6.79565 2.25 6 2.25C5.20435 2.25 4.44129 2.56607 3.87868 3.12868C3.31607 3.69129 3 4.45435 3 5.25C3 6.04565 3.31607 6.80871 3.87868 7.37132C4.44129 7.93393 5.20435 8.25 6 8.25Z"
                              fill="#003B61"
                            />
                          </svg>
                          First Name
                        </span>
                        <input
                          type="text"
                          placeholder=""
                          name="firstName"
                          value={data?.firstName}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>Last Name</span>
                        <input
                          type="text"
                          placeholder=""
                          name="lastName"
                          value={data?.lastName}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.6">
                              <path
                                d="M2.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25V2.25ZM15 5.4285L9.054 10.7535L3 5.412V14.25H15V5.4285ZM3.38325 3.75L9.04575 8.7465L14.6265 3.75H3.38325Z"
                                fill="#003B61"
                              />
                            </g>
                          </svg>
                          Email
                        </span>
                        <input
                          type="email"
                          placeholder=""
                          name="email"
                          value={data?.email}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>Contact Number</span>
                        <input
                          type="text"
                          placeholder=""
                          name="contactNumber"
                          value={data?.contactNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 16.5C3 14.9087 3.63214 13.3826 4.75736 12.2574C5.88258 11.1321 7.4087 10.5 9 10.5C10.5913 10.5 12.1174 11.1321 13.2426 12.2574C14.3679 13.3826 15 14.9087 15 16.5H13.5C13.5 15.3065 13.0259 14.1619 12.182 13.318C11.3381 12.4741 10.1935 12 9 12C7.80653 12 6.66193 12.4741 5.81802 13.318C4.97411 14.1619 4.5 15.3065 4.5 16.5H3ZM9 9.75C6.51375 9.75 4.5 7.73625 4.5 5.25C4.5 2.76375 6.51375 0.75 9 0.75C11.4863 0.75 13.5 2.76375 13.5 5.25C13.5 7.73625 11.4863 9.75 9 9.75ZM9 8.25C10.6575 8.25 12 6.9075 12 5.25C12 3.5925 10.6575 2.25 9 2.25C7.3425 2.25 6 3.5925 6 5.25C6 6.9075 7.3425 8.25 9 8.25Z"
                              fill="#003B61"
                            />
                          </svg>
                          Pronouns
                        </span>
                        <input
                          type="text"
                          maxLength={10}
                          pattern="\d{10}"
                          placeholder=""
                          name="pronouns"
                          value={data?.pronouns}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 16.5C3 14.9087 3.63214 13.3826 4.75736 12.2574C5.88258 11.1321 7.4087 10.5 9 10.5C10.5913 10.5 12.1174 11.1321 13.2426 12.2574C14.3679 13.3826 15 14.9087 15 16.5H13.5C13.5 15.3065 13.0259 14.1619 12.182 13.318C11.3381 12.4741 10.1935 12 9 12C7.80653 12 6.66193 12.4741 5.81802 13.318C4.97411 14.1619 4.5 15.3065 4.5 16.5H3ZM9 9.75C6.51375 9.75 4.5 7.73625 4.5 5.25C4.5 2.76375 6.51375 0.75 9 0.75C11.4863 0.75 13.5 2.76375 13.5 5.25C13.5 7.73625 11.4863 9.75 9 9.75ZM9 8.25C10.6575 8.25 12 6.9075 12 5.25C12 3.5925 10.6575 2.25 9 2.25C7.3425 2.25 6 3.5925 6 5.25C6 6.9075 7.3425 8.25 9 8.25Z"
                              fill="#003B61"
                            />
                          </svg>
                          Digital Signature
                        </span>
                        <input type="file" onChange={uploadDocument} />
                        {documentImg && (
                          <div className="mt-3 d-flex align-items-center">
                            <img
                              src={Bucket + documentImg}
                              alt=""
                              height={100}
                              width={100}
                            />
                            <RxCross2
                              className="ms-2 cursor-pointer"
                              color="#003b61"
                              size={20}
                              onClick={() => setDocumnetImg("")}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25V2.25ZM15 8.25H3V14.25H15V8.25ZM15 6.75V3.75H3V6.75H15ZM10.5 11.25H13.5V12.75H10.5V11.25Z"
                              fill="#003B61"
                            />
                          </svg>
                          Gender
                        </span>
                        <select
                          name="gender"
                          placeholder="Your Enter Gender"
                          value={data?.gender}
                          onChange={handleChange}
                        >
                          <option selected disabled hidden>
                            select
                          </option>
                          <option value={0}>Male</option>
                          <option value={1}>Female</option>
                          <option value={2}>Intersex</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.25 1.5H13.5C13.6989 1.5 13.8897 1.57902 14.0303 1.71967C14.171 1.86032 14.25 2.05109 14.25 2.25V15.75C14.25 15.9489 14.171 16.1397 14.0303 16.2803C13.8897 16.421 13.6989 16.5 13.5 16.5H4.5C4.30109 16.5 4.11032 16.421 3.96967 16.2803C3.82902 16.1397 3.75 15.9489 3.75 15.75V0H5.25V1.5ZM5.25 6.75H12.75V3H5.25V6.75ZM5.25 8.25V15H12.75V8.25H5.25Z"
                              fill="#003B61"
                            />
                          </svg>
                          Current Gender Identity
                        </span>
                        <select
                          name="genderIdentify"
                          placeholder="Your Gender Identity"
                          value={data?.genderIdentify}
                          onChange={handleChange}
                        >
                          <option selected disabled hidden>
                            select
                          </option>
                          <option value={0}>Male</option>
                          <option value={1}>Female</option>
                          <option value={2}>
                            Trans Feminine / Trans Female
                          </option>
                          <option value={3}>
                            I don’t identify with any of the above
                          </option>
                          <option value={4}>
                            Trans Masculine / Trans Male
                          </option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.000000pt"
                            height="auto"
                            viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              fill="#003B61"
                              stroke="none"
                            >
                              <path
                                d="M1315 5108 c-49 -17 -92 -55 -114 -101 -18 -36 -21 -62 -21 -159 l0 -115 -158 -6 c-219 -9 -332 -35 -485 -113 -252 -128 -433 -357 -510 -648 l-22
                        -81 0 -1520 0 -1520 23 -89 c24 -97 91 -246 142 -321 46 -66 154 -179 222 -232 128 -98 307 -172 469 -193 59 -8 586 -10 1754 -8 l1670 3 77 22 c179 51 312 123 429 231 174 162 275 344 314 568 23 131 23 2948 0 3079 -36 204 -127
                        377 -280 530 -210 210 -435 294 -791 295 l-92 0 -3 129 c-4 125 -5 131 -34 172 -87 123 -268 113 -337 -20 -26 -49 -28 -62 -28 -167 l0 -114 -980 0 -980
                        0 0 114 c0 105 -2 118 -28 167 -45 87 -147 128 -237 97z m-135 -993 c0 -230 5 -262 53 -313 102 -110 297 -66 337 76 5 20 10 130 10 245 l0 207 980 0 980 0
                        0 -207 c0 -115 5 -225 10 -245 40 -142 235 -186 337 -76 48 51 53 83 53 314 l0 217 143 -6 c79 -3 168 -13 201 -22 159 -43 302 -162 376 -312 43 -90 60
                        -176 60 -320 l0 -123 -2160 0 -2160 0 0 123 c0 144 17 230 60 320 88 178 253 297 455 327 27 4 98 8 158 9 l107 1 0 -215z m3540 -2078 c0 -1082 -1 -1116
                      -20 -1192 -50 -194 -199 -353 -391 -416 l-74 -24 -1675 0 -1675 0 -74 24 c-89 29 -204 104 -261 169 -60 67 -107 157 -130 247 -19 76 -20 111 -20 1192 l0 1113 2160 0 2160 0 0 -1113z"
                              />
                              <path d="M1105 2541 c-68 -30 -125 -113 -125 -181 0 -68 57 -151 125 -181 35 -16 69 -19 273 -19 257 0 279 4 340 63 20 20 39 52 48 84 14 48 14 58 0 106 -9 32 -28 64 -48 84 -61 59 -83 63 -340 63 -204 0 -238 -3 -273 -19z" />
                              <path d="M2272 2534 c-116 -57 -144 -204 -59 -301 60 -68 82 -73 347 -73 260 0 285 5 344 68 88 94 53 257 -69 313 -35 16 -68 19 -276 19 -233 0 -235 0 -287 -26z" />
                              <path d="M3470 2544 c-166 -72 -165 -299 2 -368 30 -13 82 -16 270 -16 204 0 238 3 273 19 68 30 125 113 125 181 0 68 -57 151 -125 181 -35 16 -69 19 -275 18 -188 0 -242 -3 -270 -15z" />
                              <path d="M1092 1354 c-116 -57 -144 -204 -59 -301 59 -68 83 -73 345 -73 188 0 240 3 270 16 136 56 170 227 65 326 -56 54 -82 58 -336 58 -231 0 -234 0 -285 -26z" />
                              <path d="M2295 1367 c-71 -28 -116 -82 -130 -158 -15 -79 39 -174 120 -210 35 -16 69 -19 275 -19 266 0 290 6 348 75 83 101 47 250 -73 306 -35 16 -67 18 -275 18 -155 0 -245 -5 -265 -12z" />
                              <path d="M3470 1364 c-166 -72 -165 -299 2 -368 30 -13 82 -16 270 -16 262 0 286 5 345 73 86 98 56 244 -61 302 -49 24 -55 25 -285 25 -188 -1 -243 -4 -271 -16z" />
                            </g>
                          </svg>
                          Date Of Birth
                        </span>
                        <input
                          type="Date"
                          maxLength={10}
                          pattern="\d{10}"
                          placeholder="Your Gender Identity"
                          name="dob"
                          max={moment(new Date()).format("YYYY-MM-DD")}
                          value={data?.dob}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.25 0.25H14.75C14.9489 0.25 15.1397 0.329018 15.2803 0.46967C15.421 0.610322 15.5 0.801088 15.5 1V13C15.5 13.1989 15.421 13.3897 15.2803 13.5303C15.1397 13.671 14.9489 13.75 14.75 13.75H1.25C1.05109 13.75 0.860322 13.671 0.71967 13.5303C0.579018 13.3897 0.5 13.1989 0.5 13V1C0.5 0.801088 0.579018 0.610322 0.71967 0.46967C0.860322 0.329018 1.05109 0.25 1.25 0.25V0.25ZM14 7H2V12.25H14V7ZM14 4V1.75H2V4H14Z"
                              fill="#003B61"
                            />
                          </svg>
                          Prescriber No.
                        </span>
                        <input
                          type="text"
                          placeholder=""
                          name="prescriberNumber"
                          value={data?.prescriberNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.25 0.25H14.75C14.9489 0.25 15.1397 0.329018 15.2803 0.46967C15.421 0.610322 15.5 0.801088 15.5 1V13C15.5 13.1989 15.421 13.3897 15.2803 13.5303C15.1397 13.671 14.9489 13.75 14.75 13.75H1.25C1.05109 13.75 0.860322 13.671 0.71967 13.5303C0.579018 13.3897 0.5 13.1989 0.5 13V1C0.5 0.801088 0.579018 0.610322 0.71967 0.46967C0.860322 0.329018 1.05109 0.25 1.25 0.25V0.25ZM14 7H2V12.25H14V7ZM14 4V1.75H2V4H14Z"
                              fill="#003B61"
                            />
                          </svg>
                          Your FRENCHIE M.D Provider Number.
                        </span>
                        <input
                          type="text"
                          placeholder=""
                          name="frenchieProviderNumber"
                          value={data?.frenchieProviderNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="profiledetail">
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.6">
                              <path
                                d="M15.75 15.0026C15.75 15.2016 15.671 15.3923 15.5303 15.533C15.3897 15.6736 15.1989 15.7526 15 15.7526H3C2.80109 15.7526 2.61032 15.6736 2.46967 15.533C2.32902 15.3923 2.25 15.2016 2.25 15.0026V7.12015C2.24992 7.00585 2.27596 6.89306 2.32614 6.79037C2.37631 6.68768 2.44929 6.59782 2.5395 6.52765L8.5395 1.86115C8.67116 1.75873 8.8332 1.70312 9 1.70312C9.1668 1.70312 9.32884 1.75873 9.4605 1.86115L15.4605 6.52765C15.5507 6.59782 15.6237 6.68768 15.6739 6.79037C15.724 6.89306 15.7501 7.00585 15.75 7.12015V15.0026V15.0026ZM14.25 14.2526V7.48615L9 3.40315L3.75 7.48615V14.2526H14.25Z"
                                fill="#003B61"
                              />
                            </g>
                          </svg>
                          Home Address
                        </span>
                        <input
                          ref={inputRef}
                          type="text"
                          placeholder=""
                          name="address"
                          value={data?.address}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="profiledetail">
                        <input
                          type="text"
                          placeholder="Postcode"
                          maxLength={6}
                          minLength={6}
                          pattern="\d{10}"
                          name="postcode"
                          value={data?.postcode}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="profiledetail mb-0">
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.6">
                              <path
                                d="M15 1.5C15.414 1.5 15.75 1.836 15.75 2.25V15.75C15.75 16.164 15.414 16.5 15 16.5H4.5C4.086 16.5 3.75 16.164 3.75 15.75V14.25H2.25V12.75H3.75V11.25H2.25V9.75H3.75V8.25H2.25V6.75H3.75V5.25H2.25V3.75H3.75V2.25C3.75 1.836 4.086 1.5 4.5 1.5H15ZM14.25 3H5.25V15H14.25V3ZM10.5 6V8.25H12.75V9.75H10.4992L10.5 12H9L8.99925 9.75H6.75V8.25H9V6H10.5Z"
                                fill="#003B61"
                              />
                            </g>
                          </svg>
                          Doctor Expertise
                        </span>

                        <div className="selecttab select">
                          <div className="male">
                            <input
                              type="checkbox"
                              id="age1"
                              name="age"
                              value="Erectile Dysfunction"
                              checked={checked?.includes(
                                "Erectile Dysfunction"
                              )}
                              onChange={(e) => handleChangeCheckbox(e)}
                            />
                            <label htmlFor="age1">
                              Erectile Dysfunction123
                            </label>
                          </div>
                          <div className="male">
                            <input
                              type="checkbox"
                              id="age2"
                              name="age"
                              value="Premature Ejaculation"
                              checked={checked?.includes(
                                "Premature Ejaculation"
                              )}
                              onChange={(e) => handleChangeCheckbox(e)}
                            />
                            <label htmlFor="age2">Premature Ejaculation</label>
                          </div>
                          <div className="male">
                            <input
                              type="checkbox"
                              id="age3"
                              name="age"
                              value="STI/STD"
                              checked={checked?.includes("STI/STD")}
                              onChange={(e) => handleChangeCheckbox(e)}
                            />
                            <label htmlFor="age3">STI/STD</label>
                          </div>
                          <div className="male">
                            <input
                              type="checkbox"
                              id="age6"
                              name="age"
                              value="Thrush / Bacterial Vaginosis"
                              checked={checked?.includes(
                                "Thrush / Bacterial Vaginosis"
                              )}
                              onChange={(e) => handleChangeCheckbox(e)}
                            />
                            <label htmlFor="age6">
                              Thrush / Baceterial Vaginosis
                            </label>
                          </div>
                          <div className="male">
                            <input
                              type="checkbox"
                              id="age7"
                              name="age"
                              value="Urinary Tract Infection"
                              checked={checked?.includes(
                                "Urinary Tract Infection"
                              )}
                              onChange={(e) => handleChangeCheckbox(e)}
                            />
                            <label htmlFor="age7">
                              Urinary Tract Infection
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="profiledetail mt-0">
                        <input
                          type="text"
                          name="othersymptom"
                          value={data?.othersymptom}
                          onChange={handleChange}
                          placeholder="Other Symptoms"
                        />
                      </div>
                      <Col lg={12}>
                        <div className="profiledetail">
                          <span>Bio*</span>
                          <textarea
                            placeholder="Tell the patient a bit about yourself"
                            name="bio"
                            value={data?.bio}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </Col>
                    </Col>
                    <Col lg={12}>
                      <div className="profiledetail mb-0">
                        <span>
                          <svg
                            width="22"
                            height="23"
                            viewBox="0 0 22 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_742_66506)">
                              <path
                                d="M10.9987 20.6668C5.93595 20.6668 1.83203 16.5629 1.83203 11.5002C1.83203 6.43741 5.93595 2.3335 10.9987 2.3335C16.0614 2.3335 20.1654 6.43741 20.1654 11.5002C20.1654 16.5629 16.0614 20.6668 10.9987 20.6668ZM10.9987 18.8335C12.9436 18.8335 14.8089 18.0609 16.1841 16.6856C17.5594 15.3103 18.332 13.4451 18.332 11.5002C18.332 9.55524 17.5594 7.68998 16.1841 6.31471C14.8089 4.93945 12.9436 4.16683 10.9987 4.16683C9.05378 4.16683 7.18851 4.93945 5.81325 6.31471C4.43798 7.68998 3.66536 9.55524 3.66536 11.5002C3.66536 13.4451 4.43798 15.3103 5.81325 16.6856C7.18851 18.0609 9.05378 18.8335 10.9987 18.8335ZM11.9154 11.5002H15.582V13.3335H10.082V6.91683H11.9154V11.5002Z"
                                fill="#628396"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_742_66506">
                                <rect
                                  width="22"
                                  height="22"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Available Day & Times
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div
                  className="editprofiledetail setpro"
                  style={{
                    background: "transparent",
                    padding: "0px",
                    marginBottom: "0px",
                    height: "fit-content",
                  }}
                >
                  <SetUpAppoinmentSchedule setSelectedTimes={setSelectedTimes} selectedTimes={selectedTimes} checkboxStates={checkboxStates} setCheckboxStates={setCheckboxStates}  />
                
                </div>
                <div className="editprofiledetail setpro update_doctor_save_button">
                  <Col lg={12}>
                    <div className="text-center editprofilebtn d-block mt-0 ">
                      <p className="mb-3">
                        *Your information is safe with us. Your personal
                        information can only be viewed by you and your doctor.
                        If you have any concern, please visit{" "}
                        <Link>Privacy Policy.</Link>
                      </p>
                      <Link className="redbtn" onClick={saveBtn}>
                        SAVE
                      </Link>
                    </div>
                  </Col>
                </div>
              </Row>
              <div className="copyright">
                <p>2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UpdateDoctor;
