import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { ApiDelete, ApiPost } from "../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import AssignDoctor from "./AssignDoctor";

const DeleteDoctor = (props) => {
  const [paientData, setPatientData] = useState([]);
  const [open, setopen] = useState(false);
  const [patientId, setPatientId] = useState([]);
  const [patientId2, setPatientId2] = useState();
  const [IsDeleteDoctor, setIsDeleteDoctor] = useState(false);
  const history = useHistory();

  // get patient by doctor id
  const getData = async () => {
    const body = {
      doctorId: props.doctorId,
    };
    await ApiPost("admin/patient/get_by_doctor", body)
      .then((res) => {
        setPatientData(res?.data?.data);
      })
      .catch((e) => { });
  };
  useEffect(() => {
    getData();
  }, []);
  const HandleAllpatientId = () => {
    const Allid = [];
    paientData.forEach((ele) => {
      Allid.push(ele?.patient?._id);
    });
    setPatientId2(Allid);
    setIsDeleteDoctor(true);
  };

  // handle patient delete
  const handleDelete = async () => {
    const bodydata = {
      page: 1,
      limit: 10,
      search: "",
    };
    if (paientData.length) {
      ErrorToast("Pls Assign Doctor For All Patient");
    } else {
      await ApiDelete(`/doctor/${props.doctorId}`)
        .then((res) => {
          SuccessToast(res?.data?.message);
        })
        .catch((e) => {
          ErrorToast(e?.message);
        });
      props.getDatas(bodydata);
      props.onHide();
      history.push("/DoctorList");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="assign-doctor-modals add-customer assign_doctor"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="d-flex">
            Assign Doctor For Patient
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "300px", overflow: "auto" }}>
          <div className="d-flex justify-content-between mb-5 flex-wrap gap-3">
            <div>
              <a
                className="assignDoctor_btn"
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete()}
              >
                {" "}
                Delete Doctor
              </a>
            </div>
            <div>
              <a
                className="assignDoctor_btn"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setopen(true);
                  HandleAllpatientId();
                }}
              >
                {" "}
                Assign Doctor For All Patient
              </a>
            </div>
          </div>
          {paientData.length ? (
            paientData.map((ele, i) => (
              <div
                key={`${i}${ele?.patient?.firstName}`}
                className="assing_doctor d-block"
              >
                <div className="basicinfo_assign_doctor">
                  <div className="johnphillips_assign_doctor">
                    <h5>
                      {ele?.patient?.firstName
                        ? `${ele?.patient?.firstName} ${ele?.patient?.lastName}`
                        : ""}
                    </h5>
                    {ele?.condition.map((item, i) => {
                      return (
                        <>
                          <p>{`${i + 1}.${item}`}</p>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap justify-content-between">
                  <div className="assign_doctor_gender">
                    <label>Gender</label>
                    <p>
                      {ele?.patient?.gender === 0
                        ? "Male"
                        : ele?.patient?.gender === 1
                          ? "Female"
                          : "other"}
                    </p>
                  </div>
                  <div>
                    <a
                      className="assignDoctor_btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setopen(true);
                        setIsDeleteDoctor(true);
                        setPatientId(ele?.patient?._id);
                      }}
                    >
                      + Assign Doctor
                    </a>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
      {open && (
        <AssignDoctor
          show={open}
          onHide={() => setopen(false)}
          oldDoctorId={props.doctorId}
          patientIds={[patientId]}
          patientIds2={patientId2}
          IsDeleteDoctor={IsDeleteDoctor}
          getData={getData}
        />
      )}
    </>
  );
};

export default DeleteDoctor;
