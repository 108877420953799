import "./ChatScreen.css";
import React, { useContext, useEffect, useState, useRef } from "react";
import chatprofile from "../../Assets/images/chat/avatarBlue.png";
import "bootstrap/dist/css/bootstrap.min.css";
import DashHeader from "../../Component/DashHeader";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { appContext } from "../../helpers/AppContext";
import Auth from "../../helpers/Auth";
import Massages from "../../Assets/images/chat/Massage.svg";
import { ReactComponent as SingleTick } from "../../Assets/images/chat/singleTick.svg";
import { ReactComponent as DoubleTicks } from "../../Assets/images/chat/doubleTicks.svg";
import { ReactComponent as BlueTicks } from "../../Assets/images/chat/blueTicks.svg";
import Say_hi from "../../Assets/images/Say_hi.gif";
import close_img from "../../Assets/images/chat/close.png";
import Search_img from "../../Assets/images/chat/Search.png";
import TextareaAutosize from "react-textarea-autosize";
import moment from "moment";
import ChatLoader from "../../Component/ChatLoader/ChatLoader";
import { ApiPost, Bucket } from "../../helpers/API/API_data";
const ChatScreen = (props) => {
  const { socket, loader, connectSocket } = useContext(appContext);
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState();
  const [receiverId, setReceiverId] = useState();
  const [userMessage, setUserMessage] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [chatData, setchatData] = useState([]);
  const [id, setId] = useState();
  const [userRoomId, setUserRoomId] = useState();
  const [name, setName] = useState();
  const [rommSearch, SetRommSearch] = useState("");
  const [page, setPage] = useState(1);
  const [flag, setflag] = useState(false);
  let pathName = window.location.pathname;
  const focusref = useRef();
  const messageEl = useRef(null);
  const userData = Auth.getUserData();
  const location = useLocation();

  // handle sent Message
  const handleSendMessage = (e, message) => {
    e.preventDefault();
    if (message.trim()) {
      socket?.emit("send_message", {
        senderId: userData?._id,
        roomId: userRoomId ? userRoomId?._id : roomId,
        receiverId: receiverId,
        message: message.trim(),
      });
      setMessage("");
    }
  };
  let timeOutId;

  // search rooms by name
  const handleSearch = (e) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      getRoomData(e.target.value);
    }, 500);
  };

  // load message when scroll
  const firstEvent = async (e) => {
    const element = e.target;
    const currentScrollPos = element.scrollTop;
    if (currentScrollPos === 0) {
      // Get the current scroll height before loading new data
      const prevScrollHeight = element.scrollHeight;
      const newScrollHeight = element.scrollHeight;
      // Calculate the difference in scroll height
      const scrollHeightDiff = newScrollHeight - prevScrollHeight;
      // Set the scroll position to maintain the user's position
      element.scrollTop = currentScrollPos + scrollHeightDiff;
      // Make your API call here
      await loadMoreMessages(page + 1);
      // Get the new scroll height after loading new data
    }
  };
  const loadMoreMessages = async (i) => {
    let body = {
      page: i,
      limit: 20,
      roomId: roomId,
    };
    try {
      const res = await ApiPost("admin/room/message/get", body);
      const newMessages = res?.data?.data?.message_data.reverse();
      if (page >= res?.data?.data?.state?.page_limit) {
        return;
      } else {
        setUserMessage((prevMessages) => [...newMessages, ...prevMessages]);
        setPage((page) => page + 1);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // select and open rooms chat when navigate from initaite chat and chat with doctor or patient
  useEffect(() => {
    if (location?.state?.id && !flag && rooms?.length > 0) {
      const userRoomId = rooms?.find(
        (ele, i) => ele?.user?._id === location?.state?.id
      );
      const index = rooms.findIndex(
        (ele) => ele?.user?._id === location?.state?.id
      );
      setId(userRoomId?.user?._id);
      setRoomId(userRoomId._id);
      setReceiverId(userRoomId.user._id);
      setunreed(index);
      setflag(true);
    }
  }, [rooms]);

  // sent message on hit enter
  const handleKeySendMsg = (e) => {
    e.preventDefault();
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage(e, message);
    }
  };

  // handle sent and recieve msg by socket methods
  const sendermsg = [];
  const receivermsg = [];
  for (let i = 0; i < userMessage.length; i++) {
    sendermsg.push(
      userMessage[i - 1]?.senderId !== userData?._id &&
        userMessage[i]?.senderId === userData?._id &&
        userMessage[i]
    );
    receivermsg.push(
      userMessage[i - 1]?.senderId === userData?._id &&
        userMessage[i]?.senderId !== userData?._id &&
        userMessage[i]
    );
  }
  socket?.off("receive_message").on("receive_message", (data) => {
    socket?.emit("message_deliver_status", {
      messageId: data._id,
      status:
        data?.roomId === (userRoomId?._id ? userRoomId?._id : roomId) &&
        data?.receiverId === userData?._id
          ? 2
          : 1,
      roomId: data?.roomId,
    });

    if (data?.roomId === (userRoomId ? userRoomId?._id : roomId)) {
      setUserMessage((prev) => {
        return JSON.parse(JSON.stringify([...prev, data]));
      });
    }
  });
  socket
    ?.off("receive_message_with_updated_status")
    .on("receive_message_with_updated_status", (resData) => {
      if (resData?.roomId === (userRoomId?._id ? userRoomId?._id : roomId)) {
        setUserMessage(resData?.response);
      }
    });
  socket
    ?.off("message_response")
    .on("message_response", async (messageResponse) => {
      setUserMessage((prev) => [...prev, messageResponse]);
    });

  useEffect(() => {
    socket?.emit("online", { userId: userData?._id });
  }, [socket]);

  // get message data
  const callmessage = async () => {
    socket?.emit("join_room", { roomId: roomId, userId: userData?._id });
    let body = {
      page: 1,
      limit: 20,
      roomId: roomId,
    };
    await ApiPost("admin/room/message/get", body)
      .then((res) => {
        setUserMessage(res?.data?.data?.message_data.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    const name = rooms?.find((ele) => ele?.user?._id === id);
    setName(name);
    focusref?.current?.focus();
    callmessage();
  }, [roomId]);

  // get admin room
  const getRoomData = async (i) => {
    let body = {
      search: i,
      page: 1,
      limit: rooms?.length > 0 ? 10000 : 20,
    };
    await ApiPost("admin/room/get", body)
      .then((res) => {
        setRooms(res?.data?.data?.room_data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // fetch rooms and message data at every 5sec
  useEffect(() => {
    connectSocket();
    let interval;
    if (pathName === "/ChatScreen") {
      interval = setInterval(async () => {
        await getRoomData();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [rooms?.length]);

  const scrollToBottomWithSmoothScroll = () => {
    messageEl.current?.addEventListener("DOMNodeInserted", (event) => {
      const { currentTarget: target } = event;
      target.scroll({ top: target.scrollHeight, behavior: "auto" });
    });
  };

  // modify usermessage
  useEffect(() => {
    const temp = [];
    if (userMessage.length) {
      userMessage.forEach((ele, i) => {
        if (
          userMessage[i - 1]?.createdAt &&
          moment(userMessage[i - 1]?.createdAt).format("DD/MM/YYY") ===
            moment(ele?.createdAt).format("DD/MM/YYY")
        ) {
          temp[temp.length - 1].item.push(ele);
        } else {
          temp.push({
            date: ele.createdAt,
            item: [ele],
          });
        }
        setchatData(temp);
      });
    } else {
      setchatData([]);
    }
    scrollToBottomWithSmoothScroll();
  }, [userMessage]);

  // set un-read msg
  const setunreed = (i) => {
    let extdata = rooms;
    extdata[i].unReadCount = 0;

    setRooms(extdata);
  };
  return (
    <>
      <div
        className="chatscreen admindashboard chatHidescrolling"
        style={{ msOverflowY: "hidden !important" }}
      >
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10} style={{ height: "100%" }}>
            <DashHeader />
            <Row style={{ paddingTop: "74px" }}>
              <Col lg={3} className="h-100">
                <div className="customerlist">
                  <div className="customersearch">
                    <input
                      type="text"
                      placeholder="Search"
                      value={rommSearch}
                      className="h-auto py-2"
                      onChange={(e) => {
                        handleSearch(e);
                        SetRommSearch(e.target.value);
                      }}
                    />
                    <span>
                      {rommSearch.length ? (
                        <img
                          src={close_img}
                          onClick={() => {
                            SetRommSearch("");
                            getRoomData("");
                          }}
                        />
                      ) : (
                        <img src={Search_img} />
                      )}
                    </span>
                  </div>
                  {/* room data */}
                  <ul className="chatlists m-0" style={{ height: "90%" }}>
                    {rooms?.length ? (
                      rooms?.map((element, i) => {
                        return (
                          <li className="activechats" key={i}>
                            <Link
                              to="#"
                              className={
                                element.user?._id ===
                                (userRoomId?.user?._id
                                  ? userRoomId?.user?._id
                                  : id)
                                  ? "active_user"
                                  : ""
                              }
                              onClick={() => {
                                setUserRoomId("");
                                setId(element?.user?._id);
                                setRoomId(element._id);
                                setReceiverId(element.user._id);
                                setunreed(i);
                              }}
                            >
                              <img
                                src={
                                  element?.user?.image
                                    ? element?.user?.image
                                    : chatprofile
                                }
                                className="userAvatar"
                                alt=""
                              />
                              <div className="profileinfochat">
                                <h4>{`${element?.user?.firstName} ${element?.user?.lastName} `}</h4>
                                <p>{element?.message?.message}</p>
                              </div>
                              {element?.unReadCount ? (
                                <span className="massage_count">
                                  {element?.unReadCount}
                                </span>
                              ) : (
                                ""
                              )}
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          height: "80vh",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <ChatLoader />
                      </div>
                    )}
                  </ul>
                </div>
              </Col>
              <Col lg={9} style={{ height: "calc(100vh - 75px)" }}>
                {name ? (
                  <>
                    <ul className="chatprofileheader">
                      <li>
                        <img
                          src={
                            name?.user?.image ? name?.user?.image : chatprofile
                          }
                          alt=""
                          className="userAvatar"
                        />
                        <div className="chatmeninfo">
                          <h4>{`${name?.user?.firstName} ${name?.user?.lastName}`}</h4>
                          <p>{name && name?.user?.email}</p>
                        </div>
                      </li>
                      <li>
                        <Link>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.7924 4.81154C19.6637 4.73524 19.5044 4.73226 19.373 4.80393L14.7842 7.30955V5.80614C14.7828 4.63659 13.8351 3.68882 12.6655 3.6875H2.11864C0.949086 3.68882 0.00132415 4.63659 0 5.80614V14.1715C0.00132415 15.341 0.949086 16.2888 2.11864 16.2901H12.6655C13.8351 16.2888 14.7828 15.341 14.7842 14.1715V12.6954L19.3732 15.201C19.5044 15.2727 19.6638 15.2699 19.7924 15.1936C19.9212 15.1171 20 14.9785 20 14.8291V5.17585C20 5.02622 19.921 4.88784 19.7924 4.81154ZM13.9364 14.1716C13.9357 14.8734 13.367 15.442 12.6652 15.4428H2.11864C1.41684 15.442 0.848285 14.8734 0.847458 14.1716V5.80614C0.848285 5.10451 1.41684 4.53579 2.11864 4.53496H12.6655C13.3672 4.53579 13.9359 5.10451 13.9367 5.80614L13.9364 14.1716ZM19.1525 14.115L14.7842 11.7299V8.27503L19.1525 5.89006V14.115Z"
                              fill="#003B61"
                            />
                          </svg>
                        </Link>
                      </li>
                    </ul>
                    {loader ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <ChatLoader />
                      </div>
                    ) : (
                      <div
                        className="msgsend mainchat"
                        style={{
                          height: "calc(100% - 81px )",
                          position: "relative",
                        }}
                      >
                        {!chatData.length && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100vh",
                            }}
                          >
                            <div className="modal_msg">
                              <img style={{ height: "50px" }} src={Say_hi} />
                              <p>
                                Say hi{" "}
                                {name?.user?.firstName
                                  ? name?.user?.firstName +
                                    " " +
                                    name?.user?.lastName
                                  : "-"}{" "}
                                with a wave
                              </p>
                              <button
                                className="button"
                                onClick={(e) => {
                                  handleSendMessage(e, "Hi");
                                }}
                              >
                                Say hi
                              </button>
                            </div>
                          </div>
                        )}
                        <ul
                          className="chats"
                          ref={messageEl}
                          onScroll={firstEvent}
                          id="chat-feed"
                          style={{
                            height: "auto",
                            paddingBottom: "80px",
                          }}
                        >
                          {chatData.length
                            ? chatData.map((item, i) => (
                                <>
                                  <li
                                    key={Math.random()}
                                    className="display_date"
                                  >
                                    {moment(new Date()).format("DD-MM-YYYY") ===
                                    moment(new Date(item?.date)).format(
                                      "DD-MM-YYYY"
                                    )
                                      ? "Today"
                                      : moment(new Date(item?.date)).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </li>
                                  {item.item.map((res, i) => (
                                    <>
                                      {res.senderId === userData._id && (
                                        <li
                                          className="send_massage"
                                          style={{ marginTop: "3px" }}
                                          key={`sender${i}`}
                                        >
                                          <div
                                            key={Math.random()}
                                            className="massage_sent"
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "end",
                                              }}
                                            >
                                              <div style={{ padding: "10px" }}>
                                                {res.message}
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "end",
                                                  gap: "5px",
                                                  margin: "5px",
                                                  minWidth: "45px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "10px",
                                                    padding: "0px",
                                                    color: "darkgrey",
                                                  }}
                                                >{`${new Date(
                                                  res?.createdAt
                                                ).getHours()}:${new Date(
                                                  res?.createdAt
                                                ).getMinutes()}`}</span>
                                                {res?.status === 0 ? (
                                                  <SingleTick
                                                    width="12px"
                                                    height="12px"
                                                  />
                                                ) : res?.status === 1 ? (
                                                  <DoubleTicks
                                                    width="12px"
                                                    height="12px"
                                                  />
                                                ) : (
                                                  <BlueTicks
                                                    width="16px"
                                                    height="16px"
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="ms-2 me-2"
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                            }}
                                          >
                                            {sendermsg.includes(res) ? (
                                              <img
                                                src={
                                                  userData?.image
                                                    ? Bucket + userData?.image
                                                    : chatprofile
                                                }
                                                alt="sender"
                                                style={{
                                                  height: "30px",
                                                  width: "30px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </li>
                                      )}
                                      {res.senderId !== userData._id && (
                                        <li
                                          className="mt-2 receiv_massage"
                                          key={`receiver${i}`}
                                        >
                                          <div
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                            }}
                                          >
                                            {receivermsg.includes(res) ? (
                                              <img
                                                src={
                                                  name?.user?.image
                                                    ? name?.user?.image
                                                    : chatprofile
                                                }
                                                alt="receiver"
                                                style={{
                                                  height: "30px",
                                                  width: "30px",
                                                  borderRadius: "50%",
                                                }}
                                                className="userAvatar"
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div
                                            className="massage_sent"
                                            style={{
                                              backgroundColor: "#E3F8FF",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "5px",
                                                  minWidth: "10px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "10px",
                                                    padding: "0px",
                                                    backgroundColor: "#E3F8FF",
                                                    color: "darkgrey",
                                                  }}
                                                >{`${new Date(
                                                  res?.createdAt
                                                ).getHours()}:${new Date(
                                                  res?.createdAt
                                                ).getMinutes()}`}</span>
                                              </div>
                                              <div style={{ padding: "10px" }}>
                                                {res.message}
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </>
                                  ))}
                                </>
                              ))
                            : ""}
                        </ul>
                        <div
                          className="entermsgsends"
                          style={{
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            bottom: "23px",
                            justifyContent: "center",
                            margin: "0% 2% !important",
                            width: "96%",
                          }}
                        >
                          <TextareaAutosize
                            ref={focusref}
                            style={{ padding: "15px 20px" }}
                            minRows={1}
                            maxRows={5}
                            placeholder={`Write a message for ${
                              name?.user?.firstName
                                ? name?.user?.firstName +
                                  " " +
                                  name?.user?.lastName
                                : "-"
                            } `}
                            value={message}
                            onChange={(e) => {
                              socket?.emit("is_user_typing", true);
                              setTimeout(() => {
                                socket?.emit("is_user_typing", false);
                              }, 2000);
                              setMessage(e?.target?.value);
                            }}
                            onKeyUp={(e) => handleKeySendMsg(e)}
                            className={message ? "set_button" : "set_input"}
                          />
                          {message?.length ? (
                            <Link
                              onClick={(e) => handleSendMessage(e, message)}
                              style={{ position: "unset" }}
                            >
                              Send
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "92vh",
                      }}
                    >
                      <img
                        src={Massages}
                        style={{ height: "500px", width: "100%" }}
                      ></img>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ChatScreen;
