import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/css/responsive.css";
import AppProvider from "./helpers/AppContext";
import RouteLink from "./RouteLink/RouteLink.js";

function App() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/serviceWorker.js")
          .then((reg) => console.log("Success: ", reg.scope))
          .catch((err) => console.log(err));
      });
    }
  }, []); 
  return (
    <>
      <AppProvider>
          <RouteLink />
        <Toaster position="top-center" reverseOrder={false} />
      </AppProvider>
    </>
  );
}
export default App;
