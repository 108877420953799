import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { ApiGet, ApiPost, Bucket } from "../../helpers/API/API_data";
import noProfile from "../../Assets/images/blank-profile-picture.png";
import doctor_avtar from "../../Assets/images/avatarBlue.png";
import { ErrorToast } from "../../helpers/Toast";
import Loader from "../loader/Loader";
import { appContext } from "../../helpers/AppContext";

// assign and re-assign doctor to patient
const AssignDoctor = (props) => {
  const [doctors, setDoctors] = useState([]);
  const { loader, setLoader } = useContext(appContext);

  const getData = async (doctorid) => {
    setLoader(true);
    // re-assign doctor
    if (props.IsDeleteDoctor) {
      const body = {
        oldDoctorId: props.oldDoctorId,
        newDoctorId: doctorid,
        patientIds: props.patientIds2 ? props.patientIds2 : props.patientIds,
      };
      await ApiPost("admin/patient/reAssign_doctor", body).then((res) => {
        props.onHide();
        setTimeout(() => {
          props.getData();
        }, 500);
      });
    } else {
          // assign doctor
      const body = {
        patientId: props.patientId,
        doctorId: doctorid,
        date: new Date(),
        condition: props.questionType,
      };
      props.onHide();
      ApiPost("admin/patient/assign_treatment", body)
        .then((response) => {
          setLoader(false);
          props.patientgetdata();
        })
        .catch((e) => {
          ErrorToast(e.message);
          setLoader(false);
          props.onHide();
        });
    }
  };

  // get doctor data for assign to patints
  useEffect(() => {
    ApiGet("admin/doctor")
      .then((res) => {
        const temp = res?.data?.map((doctor, i) => {
          return {
            ...doctor,
            doctorAvailable: doctor?.availability ? "Yes" : "No",
          };
        });
        setDoctors(temp);
        props.patientgetdata();
      })
      .catch((e) => { });
  }, []);

  return (
    <>
      {loader && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="assign-doctor-modals add-customer assign_doctor mobile_assign_doctor"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Your Assign Doctor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "300px", overflow: "auto" }}>
          {doctors?.length
            ? doctors?.map((doctor, i) => (
              <div key={`${i}${doctor?.firstName}`} className="assing_doctor assing_doctor1 d-flex">
                <div className="basicinfo_assign_doctor">
                  <img
                    style={{ borderRadius: "50%" }}
                    src={
                      doctor?.image ? Bucket + doctor?.image : doctor_avtar
                    }
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = noProfile;
                    }}
                  />
                  <div className="johnphillips_assign_doctor d-block">
                    <h5>
                      {doctor?.firstName} {doctor?.lastName}{" "}
                    </h5>
                    <div className=" d-block assignres">
                      {doctor?.additionalInfo?.conditionSpecialities?.map(
                        (v, i) => {
                          let abbreviation = "";

                          switch (v) {
                            case "Erectile Dysfunction":
                              abbreviation = "ED";
                              break;
                            case "Premature Ejaculation":
                              abbreviation = "PE";
                              break;
                            case "Thrush / Bacterial Vaginosis":
                              abbreviation = "TBV";
                              break;
                            case "STI/STD":
                              abbreviation = "STI/STD";
                              break;
                            case "Urinary Tract Infection":
                              abbreviation = "UTI";
                              break;
                            default:
                              abbreviation = v;
                              break;
                          }
                          return <div key={i}>{abbreviation}</div>;
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex mobile_assign_doctor_div_flex gap-3 align-items-center">
                  <div className="assign_doctor_gender assign_doctor_gender1 d-block">
                    <div className="availbility_main mb-2">
                      <label>Gender</label>
                      <p>
                        {doctor?.gender === 0
                          ? "Male"
                          : doctor?.gender === 1
                            ? "Female"
                            : "Other"}
                      </p>
                    </div>
                    <div className="availbility_main">
                      <label>Availbility</label>
                      <h6
                        className={
                          doctor?.doctorAvailable === "Yes"
                            ? "assign_doctor_availble"
                            : "assign_doctor_not_availble"
                        }
                      >
                        {doctor?.doctorAvailable}
                      </h6>
                    </div>
                  </div>
                  <div className="d-flex">
                    <a
                      className="assignDoctor_btn assignDoctor_btn_mobile"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        getData(doctor?._id);
                      }}
                    >
                      + Assign Doctor
                    </a>
                  </div>
                </div>
              </div>
            ))
            : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssignDoctor;
