import "./QuestionSummary.css";
import React, { useEffect, useState } from "react";
import user from "../../Assets/images/darkuser.png";
import notification from "../../Assets/images/notification.png";

import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiGet } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";

const QuestionSummary = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const [patientQuestion, setpatientQuestion] = useState("");

  useEffect(() => {
    getpatientQuestion();
  }, []);

  const getpatientQuestion = () => {
    ApiGet(`admin/patient_question/${window.location.pathname?.split("/")[2]}`)
      .then((res) => {
        let data = res?.data?.data?.patient_question_data?.find(
          (item, index) => index.toString() === id
        );
        setpatientQuestion(data);
      })
      .catch((e) => {});
  };

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>

          <Col lg={10}>
            <DashHeader />
            <div className="summrytitle">
              <h2>Question Summary</h2>
              <Link to="/chatscreen" className="chatrequest">
                Request for chat
              </Link>
            </div>

            <div className="questionlist questionary">
              <div className="questionmain questionmain__main">
                <Row>
                  {patientQuestion?.questions &&
                    patientQuestion.questions.slice(1).map((item, i) => (
                      <Col lg={6}>
                        <ul
                          key={`${i}${item.question}`}
                          className="questionmainlist questionmainlist__inner"
                        >
                          <div className="questionmain__header">
                            <span style={{ minWidth: "30px" }}>{i + 1}</span>{" "}
                            <h1>{item.question}</h1>
                          </div>
                          <div className="questionmain__body">
                            {item.answer}
                          </div>
                        </ul>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default QuestionSummary;
