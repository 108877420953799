import moment from "moment";
import dayjs from 'dayjs'

export const checkKeyLength = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].length > 0) {
        return true;
      }
    }
    return false;
  };
  export const hasNull = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const daysData = obj[key];
        for (const day of daysData) {
           
            if (!day.startTime && !day.endTime) {
              return false;
            }

            if (!day.startTime || !day.endTime) {
              return true;
            } 
        }
      }
    }
    return false;
  };

  export const removeBothNullObj = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value.filter(
          (obj) => !(obj.startTime === null && obj.endTime === null)
        ),
      ])
    );
    return filteredData;
  };
  export   const transformScheduleData = (scheduleData) => {
    const transformedData = {};

    for (const day in scheduleData) {
      if (Object.hasOwnProperty.call(scheduleData, day)) {
        const dayTimes = scheduleData[day].map((event) => ({
          starttime: {
            h: new Date(event.startTime).getHours().toString().padStart(2, "0"),
            m: new Date(event.startTime)
              .getMinutes()
              .toString()
              .padStart(2, "0"),
          },
          endtime: {
            h: new Date(event.endTime).getHours().toString().padStart(2, "0"),
            m: new Date(event.endTime).getMinutes().toString().padStart(2, "0"),
          },
        }));

        transformedData[`${day.toLowerCase()}Time`] = dayTimes;
      }
    }

    return transformedData;
  };

  export function convertStringToDate(scheduleData) {
    console.log("scheduleData===>", scheduleData);
    const newData = {};
  
    for (const day in scheduleData) {
      if (Object.hasOwnProperty.call(scheduleData, day)) {
        const events = scheduleData[day].map((event) => ({
          startTime: dayjs(event.startTime),
          endTime: dayjs(event.endTime),
        }));
  
        newData[day] = events;
      }
    }
  
    return newData;
  }