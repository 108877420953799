import { Checkbox } from "antd";
import React from "react";

// treatment card
const TreatMentCard = ({ data }) => {

  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head flex">
          <h6 className="consulation_list_li__head">{data?.itemNo}</h6>
          <div
            style={{
              margin: "0 8px",
            }}
          >
            <Checkbox
              checked={isCheck.includes(data?.id)}
              onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <h6 className="consulation_list_li__head">{data?.treatmentName}</h6>
          </div>
        </div>
        <div className="consulation_list_li_data_inner">
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">Provider</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.provider}
            </h6>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Treatment Name
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.treatmentName}
            </h6>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Drug Name</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.drugName}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Price</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.price}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Quantity</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.quantity}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Form</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.form}
              </h6>
            </div>
          </div>

          <div className="consulation_list_data_left_inner mb-0">
            <h6 className="consulation_list_li_data_inner_head">Action</h6>
            <div className="d-flex">
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                {data?.editTreatment}
              </button>
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                {data?.deleteTreatment}
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default TreatMentCard;
