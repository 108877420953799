/**
 * The Pagination component is a React component that displays a set of buttons for navigating through
 * pages, with previous and next buttons and clickable page numbers.
 * @param props - The `props` parameter is an object that contains all the properties passed to the
 * `Pagination` component. These properties can be accessed using dot notation, such as
 * `props.handlePrevClick`, `props.page`, `props.totalPages`, etc.
 * @returns The Pagination component is returning a JSX element.
 */
import React from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

function Pagination(props) {
  return (
    <div className="consultation_num_add_inner_main gap-1">
      <button
        onClick={props.handlePrevClick}
        disabled={props.page === 1}
        className="consultation_bottom_left_pagination"
      >
        <MdKeyboardArrowLeft />
      </button>
      {Array.from(
        {
          length: props.totalPages,
        },
        (_, index) => {
          if (index >= props.page - 1 && index <= props.page + 3) {
            return (
              <button
                className={`consultation_div29 ${
                  props.page === index + 1 ? "active" : ""
                }`}
                key={index + 1}
                onClick={() => props.handlePageChange(index + 1)}
                disabled={props.page === index + 1}
              >
                {index + 1}
              </button>
            );
          }
          return null;
        }
      )}
      <button
        onClick={props.handleNextClick}
        disabled={props.page === props.totalPages}
        className="consultation_bottom_left_pagination"
      >
        <MdKeyboardArrowRight />
      </button>
    </div>
  );
}

export default Pagination;
