import "./logindoctor.css";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../Assets/images/lightlogo.png";

import {  Row, Col } from "react-bootstrap";
import { ApiPostNoAuth } from "../../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import Auth from "../../../helpers/Auth";
import { isPharmacyUser } from "../../../utils/user.util";

const LoginDoctor = (props) => {
  const [userData, setUserData] = useState({});


  const history = useHistory();

  // handle input chages
  const handleChnage = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  // submit login data
  const login = () => {
    ApiPostNoAuth("admin/login", userData)
      .then((res) => {
        localStorage.setItem("userData", JSON.stringify(res?.data?.data));
        Auth.setAuthToken(res?.data?.data?.token)
        if (isPharmacyUser(userData)) {
          history.push("/Order");
        } else {
          history.push("/AdminDashboard");
        }
        SuccessToast(res?.data?.message);
      })
      .catch((e) => {
        ErrorToast(e?.message);
      });
  };

  return (
      <section className="login mainlogin">
        <header className="docheader">
          <img src={logo} alt="" />
        </header>
        <Row>
          <Col lg={12}>
            <div className="loginuserdetail doc">
              <h3>Admin Portal </h3>
              <div className="inputdetail">
                <div className="userinput">
                  {" "}
                  <span>Email address</span>{" "}
                  <input
                    type="email"
                    value={userData?.email}
                    placeholder="Email address"
                    onChange={handleChnage}
                    name="email"
                  />{" "}
                </div>
                <div className="userinput">
                  {" "}
                  <span>Password</span>{" "}
                  <input
                    type="password"
                    name="password"
                    value={userData?.password}
                    placeholder="Password"
                    onChange={handleChnage}
                  />{" "}
                </div>
                <div className="btnred">
                  {" "}
                  <Link onClick={login}>LOG IN</Link>{" "}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
  );
};

export default LoginDoctor;
