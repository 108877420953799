import "./SetUpAppoinmentSchedule.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Button, Form } from "react-bootstrap";
import { TimePicker } from "antd";
import moment from "moment";
import dayjs from 'dayjs'

const SetUpAppoinmentSchedule = ({setCheckboxStates, selectedTimes, setSelectedTimes, checkboxStates}) => {
const defaultStartTime = dayjs().startOf('day');
const defaultEndTime = dayjs().startOf('day');
const [isMobile, setIsMobile] = useState(false)

// calnder responsiveness
useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768); 
    };

    handleResize(); 
    window.addEventListener('resize', handleResize); 

    return () => {
      window.removeEventListener('resize', handleResize); 
    };
  }, []);


// handle time select
  const handleTimeSelect = (day, index, type, value, dayOfWeek) => {
    const updatedTimes = { ...selectedTimes };
    if (!updatedTimes[dayOfWeek]) {
      updatedTimes[dayOfWeek] = [];
    }
    if (!updatedTimes[dayOfWeek][index]) {
      updatedTimes[dayOfWeek][index] = {};
    }
    updatedTimes[dayOfWeek][index][type] = value;
    setSelectedTimes(updatedTimes);
  };

  // add new slot by click on plus
  const addNewTimeSlot = (day, dayOfWeek) => {
    const updatedTimes = { ...selectedTimes };
    if (!updatedTimes[dayOfWeek]) {
      updatedTimes[dayOfWeek] = [];
    }
    updatedTimes[dayOfWeek].push({
      startTime: defaultStartTime,
      endTime: defaultEndTime,
    });
    setSelectedTimes(updatedTimes);
  };

  // remove slot by click on minus
  const removeTimeSlot = (dayOfWeek, indexToRemove) => {
    const updatedTimes = { ...selectedTimes };
    if (
      updatedTimes[dayOfWeek] &&
      updatedTimes[dayOfWeek].length > indexToRemove
    ) {
      updatedTimes[dayOfWeek].splice(indexToRemove, 1);
      setSelectedTimes(updatedTimes);
    }
  };

  // handle checkbox to decide apply for next all weeks or singles
  const handleCheckboxChange = (day) => { 
    setCheckboxStates((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));

    // if checkbox checked than set default
    if (!checkboxStates[`is${day}`]) {
    const dayOfWeek = day.slice(2); 
    const selectedTimeSlots = selectedTimes[dayOfWeek];

    if (selectedTimeSlots && selectedTimeSlots.length) {     
      delete selectedTimes[dayOfWeek];
      setSelectedTimes(selectedTimes);
    }}
  
  };

  return (
      <div className=" setpro setappo setup_appointment_schedule p-0">
        {/* <div className="paymentinfo "> */}
          <Row>
            <Col lg="12" md="12" className="p-0">
              <div className="setupappo">
                <Row>
                  <Col lg="12" md="12" >
                    <div className="setproheading">
                      <h3>Select your available slot</h3>
                    </div>
                  </Col>
                </Row>
                {!isMobile && (
                  <Row>
                    <Col lg="4" md="4"></Col>
                    <Col lg="8" md="8">
                      <Row className="h-100 d-flex justify-content-end">
                        <Col lg="5.5" md="5" className="h-100">
                          <div className="h-100 d-flex align-items-end">
                            <span className="start_time">Start Time</span>
                          </div>
                        </Col>
                        <Col lg="5.5" md="5" className="h-100">
                          <div className="h-100 d-flex align-items-end">
                            <span className="start_time">End Time</span>
                          </div>
                        </Col>
                        <Col lg="1" md="1" className="h-100">
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {/* render time picker daywise */}
                {[...Array(7)].map((_, index) => {
                  const day = moment().startOf("week").add(index, "days");
                  const dayFormatted = day.format("YYYY-MM-DD");
                  const dayOfWeek = day.format("dddd");
                  console.log(
                    "day===>",
                    day,
                    "dayFormatted===>",
                    dayFormatted,
                    "dayOfWeek==>",
                    dayOfWeek
                  );

                  const timeSlots = selectedTimes[dayOfWeek] || [
                    { startTime: defaultStartTime, endTime: defaultEndTime },
                  ];
                  return (
                    <Col key={dayOfWeek} className="calendar-day">
                    <Row className="m-0">
                      <Col
                        lg="4"
                        md="4"
                        sm="12"
                        xs="12"
                        className="day_wrapper"
                        style={{ paddingRight: "16px" }}
                      >
                        <Row className="justify-content-end m-0">
                          <Col
                            className="p-0"
                            lg="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <div className="selecttab days">
                              <div className="male">
                                <input
                                  type="checkbox"
                                  id="age1"
                                  disabled
                                  name="age"
                                  value="1"
                                />
                                <label for="age1">{dayOfWeek}</label>
                              </div>
                           
                            </div>
                            <div  className="day_checkBox">
                            <Form.Check
                              type="checkbox"
                              // id="custom-checkbox"
                              id={`checkbox-${dayOfWeek}`}
                              checked={checkboxStates[`is${dayOfWeek}`]}
                              onChange={() =>
                                handleCheckboxChange(`is${dayOfWeek}`)
                              }
                              label="Day off"
                              className="dayOfCheckbox"
                            />
                            </div>
                          
                          </Col>
                        </Row>
                      </Col>
                      <Col lg="8" md="8" sm="12" xs="12">
                        <div className="time-range">
                          {/* <> */}
                          {isMobile && (
                            <Row style={{ margin: "6px 0px" }}>
                              <Col lg="5" md="5" xs="5" className="p-0">
                                <span className="start_time m-0 d-flex align-items-end">
                                  Start Time
                                </span>
                              </Col>
                              <Col lg="5" md="5" xs="5">
                                <span className="start_time m-0 d-flex align-items-end">
                                  End Time
                                </span>
                              </Col>
                            </Row>
                          )}
                          {/* </> */}
                          {timeSlots.map((timeSlot, idx) => (
                            <div key={idx} className="time-slot">
                              <Row className="justify-content-end">
                                <Col lg="5.5" md="5.5" xs="5" className="startTimePickerWrapper">
                                  <Form.Group>
                                    {/* <Form.Label>Start Time</Form.Label> */}
                                    <TimePicker
                                      disabled={
                                        checkboxStates[`is${dayOfWeek}`]
                                      }
                                      // inputReadOnly={true}

                                      className="form-control"
                                      style={{
                                        padding: "10px",
                                        backgroundColor: "transparent",
                                      }}
                                      value={timeSlot.startTime}
                                      format="HH:mm"
                                      // onChange={handlecheck}
                                      onChange={(time) =>
                                        handleTimeSelect(
                                          day,
                                          idx,
                                          "startTime",
                                          time,
                                          dayOfWeek
                                        )
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col
                                  lg="5.5"
                                  md="5.5"
                                  xs="5"
                                  style={{ marginBottom: "16px",paddingLeft:'0px !important' }}
                                  className="pr-0"
                                >
                                  {/* {isMobile && (
                                    <span className="start_time">
                                      End Time
                                    </span>
                                  )} */}
                                  <Form.Group>
                                    {/* <Form.Label>End Time</Form.Label> */}
                                    <TimePicker
                                      disabled={
                                        checkboxStates[`is${dayOfWeek}`]
                                      }
                                      className="form-control"
                                      style={{
                                        padding: "10px",
                                        backgroundColor: "transparent",
                                      }}
                                      value={timeSlot.endTime}
                                      format="HH:mm"
                                      onChange={(time, timeString) =>
                                        handleTimeSelect(
                                          day,
                                          idx,
                                          "endTime",
                                          time,
                                          dayOfWeek
                                        )
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col
                                  lg="1"
                                  md="1"
                                  xs="2"
                                  className="d-flex justify-content-end "
                                >
                                  {timeSlots?.length > 1 && (
                                    <Button
                                      onClick={() =>
                                        removeTimeSlot(dayOfWeek, idx)
                                      }
                                      className="add-time-btn plus_minus_btn"
                                    >
                                      -
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <Row className={`d-flex ${isMobile ? "" :"justify-content-end"}`} style={{marginTop:"1.5px"}}>
                            <Col lg="5.5" md="5" xs="5"></Col>
                            <Col lg="5.5" md="5" xs="5" className={`d-flex justify-content-end`}>
                              <Button
                                onClick={() => addNewTimeSlot(day, dayOfWeek)}
                                className="add-time-btn plus_btn "
                                disabled={checkboxStates[`is${dayOfWeek}`]}
                              >
                                +
                              </Button>
                            </Col>
                            <Col lg="1" md="1" xs="1"></Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  );
                })}
           
              </div>
            </Col>
          </Row>
        {/* </div> */}
      </div>
  );
};

export default SetUpAppoinmentSchedule;
