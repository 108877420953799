/**
 * The above code is a React component that creates a context and provider for managing state related
 * to a socket connection, rooms, and unread messages.
 * @returns The AppProvider component is being returned.
 */
import React, { createContext, useState } from "react";
import socketIO from "socket.io-client";
import config from "../config/API/api-dev";

export const appContext = createContext();

const AppProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const [rooms, setRooms] = useState();
  const [UnreadMsg, setUnreadMsg] = useState();
  const [isTablet, setIsTablet] = useState(window.innerWidth > 576);
const [roomContexData, setRoomContexData] = useState([])
  const [socket, setSocket] = useState(null);

  // itemPerPage for Pagination data
  const itemPerPage = 10;

  // handle responsiveness
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      const mobile = window.innerWidth > 576;
      if (isTablet) setIsTablet(mobile);
    });
  }, [isTablet]);

  // connect to socket and get room
  const connectSocket = () => {
    if (!socket) {
      const socket1 = socketIO.connect(`${config.endpoint}/room`);
      setSocket(socket1);
    }
  };
  const getRoom = () => {
    socket.off("get_rooms").on("get_rooms", async (rooms) => {
      rooms?.rooms &&
        rooms?.rooms.map((res) => {
          socket.emit("join_room", {
            roomId: res?._id,
            userId: res?.user?._id,
          });
        });
      setRooms(rooms?.rooms);
    });
  };
  return (
    <appContext.Provider
      value={{
        socket,
        loader,
        setLoader,
        getRoom,
        rooms,
        UnreadMsg,
        setUnreadMsg,
        connectSocket,
        isTablet,
        setIsTablet,
        itemPerPage,
        setRoomContexData,
        roomContexData
        
      }}
    >
      {children}
    </appContext.Provider>
  );
};
export default AppProvider;
