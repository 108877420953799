
import '../Orders/moodrately.css'
import '../Orders/Orders'
import React, { useEffect, useState,  useContext } from "react";
import { AiFillDelete } from "react-icons/ai";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";
import { Row, Col} from "react-bootstrap";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import Pagination from "../../Component/Pagination";
import SubAdminCard from '../../Component/tableCard/SubAdminCard';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AddSubAdmin from '../../Component/modals/addSubAdmin/AddSubAdmin';

const SubAdmin = () => {
const [subAdminData, setSubAdminData] = useState([])
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [screenSize, setScreenSize] = useState(0);
  const [open, setopen] = useState(false);
  const [dataRefresh, setDataRefresh] = useState(false)
  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);  

  // hide side onn responsiveness
  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  // delete subadmin
  const handleDeleteSubAdmin = (id)=>{
    const deleteBody = {
        subadminId : id
    }
    setLoader(true);
    ApiPost(`admin/subadmin/delete`, deleteBody)
      .then((res) => {
        getData(bodyData)
      })
      .catch((e) => {

      }).finally(()=>{
        setLoader(false);
      })
  }
  const columns = [
    {
      headerName: "Item No. ",
      field: "itemno",
    },
    {
      headerName: "Name",
      field: "subAdminName",
    },
    {
      headerName: "Email",
      field: "email",

    },
    {
      headerName: "Gender",
      field: "gender",
    },
    {
        headerName: "CreatedAt",
        field: "createdAt",
      },
      {
        headerName: "User Type",
        field: "userType",
        cellRenderer: (d) => (
          <span>
             {d?.value == 3 ? "Sub-admin" :d?.value == 4 ? "Pharmacy" : "-" }
          </span>
     )
      },
    {
        headerName: "Action",
        field: "id",
        cellRenderer: (d) => (
  
            <span>
                <AiFillDelete
                    size={20}
                    role="button"
                onClick={() => handleDeleteSubAdmin(d?.data?.id)}
                />
            </span>
       )
    },
 
  ];


  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  // get subadmin data
  const getData = async (bodyData) => {

    setLoader(true);
    ApiPost(`admin/subadmin/get`, bodyData)
      .then((res) => {

        setLoader(false)
        if (res?.data?.data?.subadmin) {
            setSubAdminData(res?.data?.data?.subadmin && res?.data?.data?.subadmin.map((val, i) => {
      
                return ({
                  itemno: (page - 1) * 10 + (i + 1),
                  id: val?._id,
                  subAdminName: `${val?.firstName && val?.firstName} ${val?.lastName && val?.lastName}`,
                  email: val?.email,
                  gender: val?.gender === 0 ? "Male" : val?.gender === 1 ? "Female" : val?.gender === 2 ? "Intersex" : "-", 
                  createdAt: val?.createdAt
                  ? moment(val?.createdAt).format("LLL")
                  : "-",
                  userType: val?.userType,
                })
              }));
        }
        setTotalData(res?.data?.data?.state?.data_count)
      })
      .catch((e) => {

      }).finally(()=>{
        setLoader(false);
      })
  };
  const searchdata = search.trim().split(/ +/).join(" ");

  const bodyData = {
    page: page,
    limit: perPage,
    search: searchdata,
  };

    // get subadmin data on page change
  useEffect(() => {
      getData(bodyData);
  }, [page, perPage,dataRefresh]);

//   handle search
  const handleSearch = (searchParams) => {
      getData({ ...bodyData, search: searchParams});
    setSearch(searchParams);
  };

//   handle submit
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getData(body);
  };


  return (
    <>
    <div className="chatscreen admindashboard pathology_main_div">
      <Row>
        <Col lg={2}>
          <SideDrawer />
        </Col>
        <Col
          lg={10}
          style={{
            height: "100vh",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <DashHeader />
          <div className="activepatient table_main">
            <div className="titlepatient d-flex justify-content-between align-items-center">
              <h4>  SUB-ADMIN</h4>
              <div className="searchbox">
                <div className="main_search_box">
                  <input
                    placeholder="Input search text"
                    className="  pe-3 search search1"
                    value={search}
                    name="search"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <button
                    className="searchbtn searchbtn1 d-flex align-items-center"
                    onClick={HandleSubmit}
                  >
                    Search
                  </button>
                </div>
                   <Link onClick={()=>setopen(true)}className="button button1" to="#">
                      Add Sub-Admin
                    </Link>
              </div>
            </div>
            {isTablet ? (
              <div className="admindatatble">
                <Table
                  columns={columns}
                  data={subAdminData}
                  page={page}
                  perpage={perPage}
                  setparpage={setPerPage}
                  setpage={setPage}
                  totalData={totalData}
                />
              </div>
            ) : (
              <>
                <ul className="active_patient_ul">
                  {subAdminData?.map((items, index) => {
                    return <SubAdminCard handleDeleteSubAdmin={handleDeleteSubAdmin} data={items} />;
                  })}
                </ul>
                {totalData > itemPerPage && (
                  <Pagination
                    page={page}
                    handlePageChange={handlePageChange}
                    handleNextClick={handleNextClick}
                    handlePrevClick={handlePrevClick}
                    totalPages={totalPages}
                  />
                )}
              </>
            )}
            <div className="copyright">
              <p>©2023 Frenchie M.D Pty Ltd</p>
            </div>
          </div>
        {
          open &&
          <AddSubAdmin setopen={setopen} open={open} setDataRefresh={setDataRefresh}/>
        }
        </Col>
      </Row>
      {loader && <Loader />}
    </div>
 
  </>
  )
}

export default SubAdmin