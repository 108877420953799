import "./DoctorDashboard.css";
import React from "react";

import Logo from "../../Assets/images/darklogo.png";
import notification from "../../Assets/images/notification.png";
import treat from "../../Assets/images/profiletreat.png";
import Delete from "../../Assets/images/delete.png";
import Folder from "../../Assets/images/folder.png";

import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const DoctorDashboard = (props) => {
  return (
    <>
      <header className="dashheader">
        <div className="header">
          <div className="profilelogo">
            <img src={Logo} alt="" />
            <span>
              <Link to="/AdminDashboard" className="active">
                Dashboard
              </Link>
            </span>
            <span>
              <Link to="/PatientList">Patient List</Link>
            </span>
          </div>
          <div className="notificationicon">
            <Link>
              <img src={notification} alt="" />
            </Link>
            <input type="checkbox"></input>
            <ul className="bell noti">
              <li>
                <p>
                  <span></span>Your treatments is coming up tomorrow
                </p>
                <div className="notiicon">
                  <span>🔔</span>
                  <p>Today</p>
                </div>
              </li>
              <li>
                <p>
                  <span></span>Your treatments is coming up tomorrow
                </p>
                <div className="notiicon">
                  <span>🔔</span>
                  <p>Today</p>
                </div>
              </li>
              <li>
                <p>
                  <span></span>Your treatments is coming up tomorrow
                </p>
                <div className="notiicon">
                  <span>🔔</span>
                  <p>Today</p>
                </div>
              </li>
              <li>
                <p>
                  <span></span>Your treatments is coming up tomorrow
                </p>
                <div className="notiicon">
                  <span>🔔</span>
                  <p>Today</p>
                </div>
              </li>
              <li className="disabled">
                <p>
                  <span></span>Your treatments is coming up tomorrow
                </p>
                <div className="notiicon">
                  <span>🔔</span>
                  <p>Today</p>
                </div>
              </li>
              <li className="disabled">
                <p>
                  <span></span>Your treatments is coming up tomorrow
                </p>
                <div className="notiicon">
                  <span>🔔</span>
                  <p>Today</p>
                </div>
              </li>
              <li className="disabled">
                <p>
                  <span></span>Your treatments is coming up tomorrow
                </p>
                <div className="notiicon">
                  <span>🔔</span>
                  <p>Today</p>
                </div>
              </li>
              <li className="disabled">
                <p>
                  <span></span>Your treatments is coming up tomorrow
                </p>
                <div className="notiicon">
                  <span>🔔</span>
                  <p>Today</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div className="dashinfo">
        <Row>
          <Col lg={4}>
            <div className="nexttratment">
              <div className="treatmentheading">
                <h3>Next Treatments</h3>
                <a href="#" className="btnredpro">
                  Profile
                </a>
              </div>
              <div className="nexttreatdetail">
                <div className="todaydetail">
                  <h5>Today</h5>
                  <p>
                    <span></span>09:00AM - 09:30AM
                  </p>
                </div>
                <div className="treatpro">
                  <img src={treat} alt="" />
                  <div className="treatdetail">
                    <h3>Kellie Carson</h3>
                    <p>0412 121 121</p>
                  </div>
                </div>
                <div className="meeting">
                  <a href="#">Meeting room</a>
                </div>
              </div>
            </div>
            <div className="nexttratment">
              <div className="treatmentheading">
                <h3>Profile</h3>
              </div>
              <div className="nexttreatdetail">
                <div className="treatpro dashdoc">
                  <img src={treat} alt="" />
                  <div className="treatdetail">
                    <h3>Dr. Kellie Carson</h3>
                    <p>1234 5678 9874</p>

                    <ul className="profiledetaildash">
                      <li>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
                            fill="#003B61"
                          />
                        </svg>
                        <span>dustin@realista.com.au</span>
                      </li>
                      <li>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.366 10.682C10.3043 12.3305 11.6695 13.6957 13.318 14.634L14.202 13.396C14.3442 13.1969 14.5543 13.0569 14.7928 13.0023C15.0313 12.9478 15.2814 12.9825 15.496 13.1C16.9103 13.8729 18.4722 14.3378 20.079 14.464C20.3298 14.4839 20.5638 14.5975 20.7345 14.7823C20.9052 14.9671 21 15.2094 21 15.461V19.923C21.0001 20.1706 20.9083 20.4094 20.7424 20.5932C20.5765 20.777 20.3483 20.8927 20.102 20.918C19.572 20.973 19.038 21 18.5 21C9.94 21 3 14.06 3 5.5C3 4.962 3.027 4.428 3.082 3.898C3.10725 3.6517 3.22298 3.42352 3.40679 3.25763C3.5906 3.09175 3.82941 2.99995 4.077 3H8.539C8.79056 2.99997 9.0329 3.09475 9.21768 3.26545C9.40247 3.43615 9.51613 3.67022 9.536 3.921C9.66222 5.52779 10.1271 7.08968 10.9 8.504C11.0175 8.71856 11.0522 8.96874 10.9977 9.2072C10.9431 9.44565 10.8031 9.65584 10.604 9.798L9.366 10.682ZM6.844 10.025L8.744 8.668C8.20478 7.50409 7.83535 6.26884 7.647 5H5.01C5.004 5.166 5.001 5.333 5.001 5.5C5 12.956 11.044 19 18.5 19C18.667 19 18.834 18.997 19 18.99V16.353C17.7312 16.1646 16.4959 15.7952 15.332 15.256L13.975 17.156C13.4287 16.9437 12.898 16.6931 12.387 16.406L12.329 16.373C10.3676 15.2567 8.74328 13.6324 7.627 11.671L7.594 11.613C7.30691 11.102 7.05628 10.5713 6.844 10.025Z"
                            fill="#003B61"
                          />
                        </svg>
                        <span>0418 288 073</span>
                      </li>
                      <li>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13 19.0003H19V9.97827L12 4.53427L5 9.97827V19.0003H11V13.0003H13V19.0003ZM21 20.0003C21 20.2655 20.8946 20.5198 20.7071 20.7074C20.5196 20.8949 20.2652 21.0003 20 21.0003H4C3.73478 21.0003 3.48043 20.8949 3.29289 20.7074C3.10536 20.5198 3 20.2655 3 20.0003V9.49027C2.99989 9.33788 3.03462 9.18749 3.10152 9.05057C3.16841 8.91365 3.26572 8.79384 3.386 8.70027L11.386 2.47827C11.5615 2.34172 11.7776 2.26758 12 2.26758C12.2224 2.26758 12.4385 2.34172 12.614 2.47827L20.614 8.70027C20.7343 8.79384 20.8316 8.91365 20.8985 9.05057C20.9654 9.18749 21.0001 9.33788 21 9.49027V20.0003Z"
                            fill="#003B61"
                          />
                        </svg>
                        <span>19 Karadoc Street, Dromana VIC 3936</span>
                      </li>
                    </ul>
                    <span className="dashedit">
                      <Link to="/EditProfile">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.345 13.3337L13.7967 4.88206L12.6183 3.70373L4.16667 12.1554V13.3337H5.345ZM6.03583 15.0004H2.5V11.4646L12.0292 1.9354C12.1854 1.77917 12.3974 1.69141 12.6183 1.69141C12.8393 1.69141 13.0512 1.77917 13.2075 1.9354L15.565 4.2929C15.7212 4.44917 15.809 4.66109 15.809 4.88206C15.809 5.10303 15.7212 5.31496 15.565 5.47123L6.03583 15.0004V15.0004ZM2.5 16.6671H17.5V18.3337H2.5V16.6671Z"
                            fill="#B4555D"
                          />
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="nexttratment nexttreat">
              <div className="treatmentheading  ">
                <h3>Summary</h3>
                <a href="#" className="btnredpro patient">
                  PATIENT LIST
                </a>
              </div>
              <ul className="summrydetail">
                <li>
                  <div className="summ">
                    <h4>412</h4>
                    <p>Treatmentss This Month</p>
                  </div>
                </li>
                <li>
                  <div className="summ">
                    <h4>49</h4>
                    <p>Treatmentss This Week</p>
                  </div>
                </li>
                <li>
                  <div className="summ">
                    <h4>231</h4>
                    <p>Total Patients</p>
                  </div>
                </li>
              </ul>
            </div>

            <Row>
              <Col lg={6}>
                <div className="nexttratment nexttreat patientdash">
                  <div className="treatmentheading  ">
                    <h3>Upcoming Treatmentss</h3>
                    <div className="dropdown">
                      <select className="list">
                        <option className="item">Today</option>
                        <option className="item">Today</option>
                        <option className="item">Today</option>
                        <option className="item">Today</option>
                      </select>
                    </div>
                  </div>
                  <div className="upcomingtreatdata">
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span>10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bgpurple">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bgpink">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bgpurple">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bggreen">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bggreen">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span>10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bgpurple">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bgpink">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bgpurple">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bggreen">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="upcomingtreat">
                      <div className="checkdetailup">
                        <input type="checkbox" />
                        <span className="bggreen">10:00 AM</span>
                        <div className="checksubdetailup">
                          <h3>David Johnson</h3>
                          <p>0421 123 456</p>
                        </div>
                      </div>
                      <ul className="delbtns">
                        <li>
                          <a href="#">
                            <img src={Delete} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Folder} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="nexttratment nexttreat patientdash">
                  <div className="treatmentheading  ">
                    <h3>Notification</h3>
                  </div>
                  <ul className="bell">
                    <li>
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li className="disabled">
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li className="disabled">
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li className="disabled">
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li className="disabled">
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li className="disabled">
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li className="disabled">
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li className="disabled">
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                    <li className="disabled">
                      <p>
                        <span></span>Your treatments is coming up tomorrow
                      </p>
                      <div className="notiicon">
                        <span>🔔</span>
                        <p>Today</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DoctorDashboard;
