import "./MasterPatient.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";
import moment from "moment";
import { CSVLink } from "react-csv";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import { useHistory } from "react-router-dom";
import AssignDoctor from "../../Component/modals/AssignDoctor";
import { SuccessToast } from "../../helpers/Toast";
import { FiFilter } from "react-icons/fi";
import { Drawer } from "antd";
import { PatientsCard } from "../../Component/tableCard/PatientsCard";
import Pagination from "../../Component/Pagination";

const MasterPatient = () => {
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [TreatmentStatus, setTreatmentStatus] = useState(null);
  const [ConsultStatus, setConsultStatus] = useState(null);
  const [UserStatus, setUserStatus] = useState(null);
  const [DoctorStatus, setDoctorStatus] = useState(null);
  const [atoz, setAtoz] = useState(true);
  const [excel, setExcel] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [currentPatient, setCurrentPatient] = useState();
  const [modelType, setModelType] = useState();
  const [condition, setCondition] = useState("");
  const [screenSize, setScreenSize] = useState(0);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);

  const QuestionAnswers =
    "Do you have preference for the gender of your treating Doctor?";
  const QuestionAnswers1 =
    `Would you consider plant or cannabis based treatment/s to compliment the suggested treatment plan?`;
  const linkRef = useRef(null);
  const searchdata = search.trim().split(/ +/).join(" ");

  const headers = [
    { label: "Patient Name", key: "PatientName" },
    { label: "Condition", key: "Condition" },
    { label: "Consult Status", key: "ConsultStatus" },
    { label: "Treatment Status", key: "TreatmentStatus" },
    { label: "Gender", key: "Gender" },
    { label: "Gender Identify", key: "identityGender" },
    { label: "Date of Birth", key: "DateofBirth" },
    { label: "Preference On Gender Of Treating", key: "Preferenceon" },
    { label: "Assigned Doctor", key: "AssignedDoctor" },
    { label: "Payment Timestemp", key: "Paymenttimestemp" },
    { label: "Timestemp", key: "Timestemp" },
  ];

  const columns = [
    {
      headerName: "Created At",
      field: "createdAt",
      width: 160,
      suppressSizeToFit: true,
      cellRenderer: (d) => (
        <span>{d?.value && moment(d?.value).format("LLL")}</span>
      ),
    },
    {
      headerName: "Patient Name",
      field: "firstName",
      width: 160,
      suppressSizeToFit: true,
      cellRenderer: (d) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push({
              pathname: `/viewPatient/${d?.data._id}`,
              state: { questionType: d?.data?.patient_question?.questionType },
            })
          }
        >
          {`${d?.value} ${d?.data?.lastName}`}
        </span>
      ),
    },
    {
      headerName: "Condition",
      field: "questionType",
      width: 170,
      suppressSizeToFit: true,
      cellRenderer: (d) => (
        <span>{`${d?.value === "STI/STD" ? "STI Screening" : d?.value}`}</span>
      ),
    },
    {
      headerName: "Consult Status",
      field: "patient_question",
      width: 160,
      suppressSizeToFit: true,
      cellRenderer: (d) =>
        d?.value?.consultStatus === 4 ? <span style={{ color: '#a52a2a' }}>{'Completed'}</span> : d?.value?.consultStatus === 1 ? <span style={{ color: '#6ABDDF' }}>{'Unassigned Doctor'}</span> : d?.value?.consultStatus === 3 ? <span style={{ color: '#008000' }}>{'Live Consult'}</span> : d?.value?.consultStatus === 0 ? <span style={{ color: '#ff0000' }}>{'Payment Pending'}</span> : d?.value?.consultStatus === 5 ? <span style={{ color: '#045fbf' }}>{'Awaiting Pathology'}</span> : '-',
    },
    {
      headerName: "Treatment Status",
      field: "patient_question",
      width: UserStatus === false ? 154 : "",
      suppressSizeToFit: UserStatus === false ? true : "",
      cellRenderer: (d) =>
        d?.value?.treatmentStatus == 0 ? <span style={{ color: '#ff0000' }}>{'Not Assigned'}</span> : d?.value?.treatmentStatus == 1 ? <span style={{ color: '#220a0a' }}>{'Payment Pending'}</span> : d?.value?.treatmentStatus == 2 ? <span style={{ color: '#3e0000' }}>{'In Progress'}</span> : d?.value?.treatmentStatus == 3 ? <span style={{ color: '#3e0000' }}>{'Completed'}</span> : '-',
    },

    {
      headerName: "Gender",
      field: "gender",
      cellRenderer: (d) =>
        <span>{d?.value === 0 ? "Male" : d?.value === 1 ? "Female" : d?.value === 2 ? "Intersex" : "-"}</span>
    },
    {
      headerName: "Gender Identify",
      field: "identityGender",
      cellRenderer: (d) => <span>{d?.value ? d?.value : "-"}</span>,
    },
    {
      headerName: "Date of Birth",
      field: "additionalInfo",
      cellRenderer: (d) => {
        return (
          <span>
            {d?.value?.dob ? d?.value?.dob?.replaceAll("-", "/") : "-"}
          </span>
        );
      },
    },
    {
      headerName: "Preference On Gender Of Treating",
      field: "questions",
      cellRenderer: (d) =>
        d?.value && (
          <span>
            {d?.value?.find((item) => item?.question === QuestionAnswers)
              ?.answer ?? "-"}
          </span>
        ),
    },
    {
      headerName: "Assigned Doctor",
      field: "patient_question",
      minWidth: 200,
      cellRenderer: (d) =>
      (d?.value?.doctor?.[0]?.firstName || d?.value?.doctor?.[0]?.lastName ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <a
            className="assignDoctor_btn assignedDoctor_btn"
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
              width: "150px",
              overflow: "hidden",
              padding: "7px",
              display: "block",
              textOverflow: "ellipsis",
            }}
          >
            {`${d?.value?.doctor?.[0]?.firstName} ${d?.value?.doctor?.[0]?.lastName}`}
          </a>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <a
            onClick={() => {
              setCurrentPatient(d?.data?._id);
              setModalShow(true);
              setCondition(d?.value?.questionType);
            }}
            className="assignDoctor_btn"
            style={{
              display: "block",
              cursor: "pointer",
              whiteSpace: "nowrap",
              width: "150px",
              padding: "7px",
            }}
          >
            + Assign Doctor
          </a>
        </div>
      ))

    },
    {
      headerName: "Payment Timestemp",
      field: "paymentTime",
      cellRenderer: (d) => (
        <span>{d?.value && moment(d?.value).format("LLL")}</span>
      ),
    },
    {
      headerName: "Timestamp",
      field: "patient_question",
      cellRenderer: (d) => (
        <span>
          {d?.value?.createdAt
            ? moment(d?.value?.createdAt).format("LLL")
            : "-"}
        </span>
      ),
    },
    {
      headerName: "Patient Id",
      field: "No",
      cellRenderer: (params) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleCopyClick(params?.value);
              SuccessToast("Copy Successfully!");
            }}
          >
            {params?.value}
          </span>
        </>
      ),
    },
  ];

  if (UserStatus === false) {
    const columnIndexToRemove = columns.findIndex(column => column.headerName === "Assigned Doctor");
    if (columnIndexToRemove !== -1) {
      columns.splice(columnIndexToRemove, 1);
    }
  }

//modify patient data
  const activePatientData = patients?.map((patient) => {
    return {
      id: patient?._id,
      createdAt:  patient?.createdAt && moment(patient?.createdAt).format("LLL") ,
      no: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCopyClick(patient?._id);
            SuccessToast("Copy Successfully!");
          }}
        >
          {patient?._id}
        </span>
      ),
      patientName: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push({
              pathname: `/viewPatient/${patient?._id}`,
              state: { questionType: patient?.patient_question?.questionType },
            })
          }
        >
          {`${patient?.firstName} ${patient?.lastName}`}
        </span>
      ),
      questionType: `${patient?.questionType === "STI/STD"
        ? "STI Screening"
        : patient?.questionType
        }`,
      patient_question: patient?.patient_question?.consultStatus,
      treatmentStatus: patient?.patient_question?.treatmentStatus,
      paymentTime: patient?.paymentTime,
      gender: patient?.gender,
      identityGender: patient?.identityGender,
      additionalInfo: patient?.additionalInfo?.dob,
      preferenceGender:
        patient?.questions &&
        (patient?.questions?.find((item) => item?.question === QuestionAnswers)
          ?.answer ??
          "-"),
      considerCannabis:
        patient?.questions &&
        (patient?.questions?.find((item) => item?.question === QuestionAnswers1)
          ?.answer ??
          "No"),
      timeStamp: patient?.patient_question?.createdAt
        ? moment(patient?.patient_question?.createdAt).format("LLL")
        : "-",
      assignDoctor:
        patient?.isEmailVerified === true ? (
          patient?.patient_question?.doctor[0]?.firstName ||
            patient?.patient_question?.doctor[0]?.lastName ? (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <a
                  className="assignDoctor_btn assignedDoctor_btn"
                  style={{
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    display: "block",
                    textOverflow: "ellipsis",
                    padding: "15px 18px",
                    height: "auto",
                  }}
                >
                  {`${patient?.patient_question?.doctor?.[0]?.firstName} ${patient?.patient_question?.doctor?.[0]?.lastName}`}
                </a>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <a
                onClick={() => {
                  setCurrentPatient(patient?._id);
                  setModalShow(true);
                  setCondition(patient?.questionType);
                }}
                className="assignDoctor_btn mobile_assignDoctor_btn"
                style={{
                  display: "block",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  width: "100%",
                  padding: "15px 18px",
                  height: "auto",
                }}
              >
                + Assign Doctor
              </a>
            </div>
          )
        ) : ""
    };
  });

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  // handle copy patient id
  const handleCopyClick = (value) => {
    // Create a temporary textarea element to copy the text
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = value;
    document.body.appendChild(tempTextarea);
    // Select the text inside the textarea and copy it
    tempTextarea.select();
    document.execCommand("copy");
    // Remove the temporary textarea element
    document.body.removeChild(tempTextarea);
  };

  // get patient data
  const getData = async (body) => {
    setLoader(true);
    const bodyData = {
      page: page,
      limit: perpage,
      search: search,
      treatmentStatus: parseInt(TreatmentStatus),
      consultStatus: parseInt(ConsultStatus),
      AtoZ: atoz,
    };
    await ApiPost("admin/patient/get", body ? body : bodyData)
      .then((res) => {
        setLoader(false);
        const temp = res?.data?.data?.patient_data.map((ele, i) => {
          return {
            ...ele,
            No: (page - 1) * perpage + (i + 1),
            No: ele?._id,
          };
        });

        setPatients(temp);
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const bodyData = {
    page: page,
    limit: perpage,
    search: searchdata,
    treatmentStatus: parseInt(TreatmentStatus),
    consultStatus: parseInt(ConsultStatus),
    AtoZ: atoz,
    isEmailVerified: UserStatus,
    assignDoctor: DoctorStatus,
  };

    // get patient data based on filter
  useEffect(() => {
    getData(bodyData);
  }, [
    page,
    perpage,
    TreatmentStatus,
    ConsultStatus,
    atoz,
    UserStatus,
    DoctorStatus,
  ]);


  // handle search
  const handleSearch = (searchData) => {
    if (searchData.length === 0) {
      getData({ ...bodyData, search: "" });
    }
    setSearch(searchData);
  };

  // handle search on submit
  const HandlseSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getData(body);
  };

  const showDrawer = () => setOpen(true);
  const onCloseDrawer = () => setOpen(false);


  // export excel
  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  useEffect(() => {
    // HandlseSubmit()
    if (searchdata) {
      HandlseSubmit();
    }
  }, [searchdata])

  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
    };
    ApiPost("admin/patient/get", body)
      .then((res) => {
        setExcel(res?.data?.data?.patient_data.length ? res.data?.data?.patient_data?.map((item, i) => {

          return {

            no: item?._id,
            PatientName: `${item?.firstName} ${item?.lastName}`,
            Condition: item?.patient_question?.questionType === "STI/STD" ? "STI Screening" : item?.patient_question?.questionType,
            ConsultStatus: item?.patient_question?.consultStatus === 4
              ? "Completed"
              : item?.patient_question?.consultStatus === 1
                ? "Unassigned Doctor"
                : item?.patient_question?.consultStatus === 3
                  ? "live consult"
                  : item?.patient_question?.consultStatus === 0
                    ? "Payment Pending"
                    : item?.patient_question?.consultStatus === 5
                      ? "Awaiting pathology"
                      : "-",
            TreatmentStatus: item?.patient_question?.treatmentStatus == 0 ? "Not Assigned" : item?.patient_question?.treatmentStatus == 1 ? "Payment Pending" : item?.patient_question?.treatmentStatus == 2 ? "In Progress" : item?.patient_question?.treatmentStatus == 3 ? "Completed" : "-",
            Paymenttimestemp: item?.paymentTime ? moment(item?.paymentTime).format("LLL") : "-",
            Gender: item?.gender === 0 ? "Male" : item?.gender === 1 ? "Female" : item?.gender === 2 ? "Intersex" : "-",
            identityGender: item?.identityGender ? item?.identityGender : "-",
            AssignedDoctor: (item?.patient_question?.doctor?.[0]?.firstName || item?.patient_question?.doctor?.[0]?.lastName) ? `${item?.patient_question?.doctor?.[0]?.firstName} ${item?.patient_question?.doctor?.[0]?.lastName}` : '-',
            DateofBirth: item?.additionalInfo?.dob ? item?.additionalInfo?.dob?.replaceAll("-", "/") : "-",
            Preferenceon: item?.questions?.find((item) => item?.question === "Do you have preference for the gender of your treating Doctor?")?.answer ? item?.questions?.find((item) => item?.question === "Do you have preference for the gender of your treating Doctor?")?.answer : "-",
            Timestemp: item?.patient_question?.createdAt ? moment(item?.patient_question?.createdAt).format("LLL") : '-'

          }
        }) : []);
      })
      .catch((e) => { });
  };

  useEffect(() => {
    let a = document.getElementById("sidebar");
    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      <div className="chatscreen admindashboard master_patient_main_section">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Active Patients</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"patient"}
                  headers={headers}
                />
                <div className="addpatient">
                  <button
                    className="button btn2 ms-3"
                    type="button"
                    onClick={(e) => ExportExcel()}
                  >
                    Export ExcelSheet
                  </button>
                </div>
              </div>
              <div className="searchbox searchbox1 mb-3 master_patient_search_div">
                <div className="d-flex mt-4">
                  <input
                    placeholder="Input search text"
                    className="pe-3 search"
                    value={search}
                    name="search"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <button
                    className="searchbtn d-flex align-items-center"
                    onClick={HandlseSubmit}
                  >
                    Search
                  </button>
                </div>
                {isTablet ? (
                  <>
                    <Filter
                      setTreatmentStatus={setTreatmentStatus}
                      setConsultStatus={setConsultStatus}
                      setUserStatus={setUserStatus}
                      setDoctorStatus={setDoctorStatus}
                      setAtoz={setAtoz}
                    />
                  </>
                ) : (
                  <button onClick={showDrawer} className="button btn2 mt-4">
                    <FiFilter /> <span className="ms-2"> Filter </span>
                  </button>
                )}
              </div>
              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={patients}
                    page={page}
                    perpage={perpage}
                    setparpage={setPerPage}
                    setpage={setPage}
                    totalData={totalData}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {activePatientData?.map((items, index) => {
                      return <PatientsCard key={index} data={items} />;
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
        {modalShow && (
          <AssignDoctor
            patientId={currentPatient}
            questionType={condition}
            type={modelType}
            show={modalShow}
            onHide={() => setModalShow(false)}
            onOpen={() => setModalShow(true)}
            patientgetdata={() => getData()}
          />
        )}
        <Drawer
          title="Filter Active Patient"
          placement="right"
          onClose={onCloseDrawer}
          open={open}
        >
          <div className="master_patient_list_data">
            <Filter
              onCloseDrawer={onCloseDrawer}
              setTreatmentStatus={setTreatmentStatus}
              setConsultStatus={setConsultStatus}
              setUserStatus={setUserStatus}
              setDoctorStatus={setDoctorStatus}
              setAtoz={setAtoz}
            />
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default MasterPatient;

/**
 * The Filter function is a React component that renders a set of select dropdowns for filtering data.
 * @returns The Filter component is returning a JSX fragment containing multiple select elements with
 * various options.
 */
const Filter = ({
  setTreatmentStatus,
  setConsultStatus,
  setUserStatus,
  setDoctorStatus,
  setAtoz,
}) => {
  return (
    <>
      <div>
        <p></p>
        <select
          className="searchfilter mt-2"
          name="atoz"
          onChange={(e) => setAtoz(e.target.value === "true" ? true : false)}
        >
          <option value={true}>A - Z</option>
          <option value={false}>Z - A</option>
        </select>
      </div>
      <div>
        <p className="mb-0 text-center" style={{ color: '#003b61', fontWeight: 500 }}>User Status</p>
        <select
          className="searchfilter"
          name="userStatus"
          onChange={(e) =>
            setUserStatus(e.target.value === "true"
              ? true
              : e.target.value === "false"
                ? false
                : null)
          }
        >
          <option selected disabled hidden>
            All
          </option>
          <option value='null'>All</option>
          <option value={true}>Verifired</option>
          <option value={false}>UnVerifired</option>
        </select>
      </div>
      <div>
        <p className="mb-0 text-center" style={{ color: '#003b61', fontWeight: 500 }}>Treatment Status</p>
        <select
          className="searchfilter"
          name="TreatmentStatus"
          onChange={(e) => setTreatmentStatus(e.target.value)}
        >
          <option selected disabled hidden>
            All
          </option>
          <option value='null'>All</option>
          <option value={0}>Not Assigned</option>
          <option value={1}>Payment Pending</option>
          <option value={2}>In Progress</option>
          <option value={3}>Completed</option>
        </select>
      </div>
      <div>
        <p className="mb-0 text-center" style={{ color: '#003b61', fontWeight: 500 }}>Consult Status</p>
        <select
          className="searchfilter"
          name="ConsultStatus"
          onChange={(e) => setConsultStatus(e.target.value)}
        >
          <option selected disabled hidden>
            All
          </option>
          <option value='null'>All</option>
          <option value={0}>Payment Pending</option>
          <option value={1}>Unassigned Doctor</option>
          <option value={3}>Live Consult</option>
          <option value={4}>Completed</option>
          <option value={5}>Awaiting Pathology</option>
        </select>
      </div>
      <div>
        <p className="mb-0 text-center" style={{ color: '#003b61', fontWeight: 500 }}>Doctor Status</p>
        <select
          className="searchfilter"
          name="DoctorStatus"
          onChange={(e) =>
            setDoctorStatus(
              e.target.value === "true"
                ? true
                : e.target.value === "false"
                  ? false
                  : "other"
            )
          }
        >
          <option selected disabled hidden>
            All
          </option>
          <option value="other">All</option>
          <option value={true}>Assign Doctor</option>
          <option value={false}>Unassign Doctor</option>
        </select>
      </div>
    </>
  );
};
