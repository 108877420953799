// sub-admin card
import React from "react";
import { AiFillDelete } from "react-icons/ai";

const SubAdminCard = ({ data,handleDeleteSubAdmin }) => {

  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div
          className="consulation_list_li_data_head flex"
          style={{ border: "none", paddingBottom: "0" }}
        >
          {/* <Checkbox
            checked={isCheck.includes(data?.id)}
            onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
          /> */}
          {/* <h6 className="consulation_list_li_data_inner_head_data ms-2">
            {data?.id}
          </h6> */}
        </div>
        <div className="consulation_list_li_data_inner">
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
              Item No.
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.itemno}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Name
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.subAdminName}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Email
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
               {data?.email}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
          <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
              Gender
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
              {data?.gender}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
              User Type
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
               {data?.userType === 4 ? "Pharmacy" : data?.userType === 3 ? "Sub-admin " : "-"}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
          {/* <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
              Gender
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
              {data?.gender}
              </h6>
            </div> */}
            {/* <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Action
              </h6>
              <AiFillDelete
                    size={20}
                    role="button"
                onClick={() => handleDeleteSubAdmin(data?.id)}
                />
            </div> */}
              <div className="d-flex consulation_list_data_left_main_flex">
          <div className="consulation_list_data_left_inner">
          <h6 className="consulation_list_li_data_inner_head">
              CreatedAt
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
               {data?.createdAt}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
                Action
              </h6>
              <AiFillDelete
                    size={20}
                    role="button"
                onClick={() => handleDeleteSubAdmin(data?.id)}
                />
            </div>
          </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default SubAdminCard;
