import './AddSubscription.css';
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col }
  from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import {
  ApiGet,
  ApiPost,
} from "../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import SideDrawer from '../SideDrawer/SideDrawer';
import DashHeader from '../../Component/DashHeader';
const AddSubscription = props => {

  const [data, setdata] = useState({});
  const [profileImage, setprofileImage] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await ApiGet("subscription")
      .then((res) => {

        setdata(res?.data?.data);
      })
      .catch((e) => {

      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setdata({
      ...data,
      [name]: value,
    });
  };

  const saveBtn = async () => {

    // debugger
    const formData = new FormData();
    formData.append("image", profileImage[0]);
    await ApiPost("upload/profile_image", formData)
      .then(async (res1) => {

        let body = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          additionalInfo: {
            specialty: data?.age,
            clinicContactNumber: "dfs",
            prescriberNo: data?.medicareNo,
            bio: "12"
          },
          contactNumber: data?.contactNumber,
          address: data?.address,
          symptoms: [data?.symptoms]
        };
        ApiGet("admin/patient/subscription", body)
          .then((res) => {

            setdata(res?.data?.data);
            SuccessToast(res?.data?.message);
            history.push("/SubscriptionModel");
          })
          .catch((e) => {

            ErrorToast(e?.message);
          });
      })
      .catch((e) => {

      });
  };

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />

          </Col>
          <Col lg={10}>
            <DashHeader></DashHeader>

            <div className="activepatient">
              <div className="titlepatient">
                <h4>Add subscription</h4>
              </div>
              <div className="couponsfield">
                <Row>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Name</span>
                      <input type="text" placeholder="Enter your name"
                        name='firstName'
                        value={data?.firstName}
                        onChange={handleChange} />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Description</span>
                      <input type="text" placeholder="Enter Your Time duration" />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Price</span>
                      <input type="text" placeholder="Enter your Price" />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Treatmen catgory</span>
                      <select>
                        <option>Enter Your Treatmen catgory</option>
                        <option>Enter Your Treatmen catgory</option>

                        <option>Enter Your Treatmen catgory</option>
                        <option>Enter Your Treatmen catgory</option>

                      </select>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Plan Duration</span>
                      <select>
                        <option>0-5</option>
                        <option>05-10</option>

                        <option>10-15</option>
                        <option>15-20</option>

                      </select>
                    </div>
                  </Col>
                  <div className="col-lg-12"><div className="editprofilebtn"><Link className="redbtn" href="" onClick={saveBtn}>SAVE</Link></div></div>

                </Row>

              </div>

            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}


export default AddSubscription;