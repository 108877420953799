import { Checkbox } from "antd";
import React from "react";

// coupon list card
const CouponCard = ({ data }) => {
  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div
          className="consulation_list_li_data_head flex"
          style={{ border: "none", paddingBottom: "0" }}
        >
          <div
            style={{
              marginRight: "8px",
            }}
          >
            <Checkbox
              checked={isCheck.includes(data?.id)}
              onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
            />
          </div>
        </div>
        <div className="consulation_list_li_data_inner">
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Coupon</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.couponName}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Code</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.couponCode}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Discount</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                ${data?.discount}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Start date
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.startDate}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">End Date</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.endDate}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Treatment Status
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.status}
              </h6>
            </div>
          </div>
          <div className="consulation_list_data_left_inner mb-0">
            <h6 className="consulation_list_li_data_inner_head">Action</h6>
            <div className="d-flex">
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                {data?.editCoupon}
              </button>
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                {data?.deleteCoupon}
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default CouponCard;
