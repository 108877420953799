import { Checkbox } from "antd";
import React from "react";

// payment card
const PaymentCard = ({ data }) => {

  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head flex">
          <div
            style={{
              marginRight: "8px",
            }}
          >
            <Checkbox
              checked={isCheck.includes(data?.id)}
              onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
            />
          </div>
          <h6 className="consulation_list_li__head">{data?.patientName}</h6>
        </div>
        <div className="consulation_list_li_data_inner">
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Doctor Name
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.doctorName}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Condition Type
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.conditionType}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Payable Amount
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.paymentAmount}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Discount Amount
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.discountAmount}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Payment Id
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.payId}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Payment Type
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.payType}
              </h6>
            </div>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Payment Method
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.payMethod}
            </h6>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Treatment Name
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {/* {data?.treatmentName} */}
              {data?.treatmentName?.length ?
                data?.treatmentName?.map((item) => {
                  return (

                    <h6 className="consulation_list_li_data_inner_head_data">{`${item?.treatmentName}`}</h6>
                  )
                }) :
                "-"}
            </h6>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Is Subscription
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.subscription}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Subscription Type
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.subscriptionType}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div
              style={{ width: "100%" }}
              className="consulation_list_data_left_inner mb-0"
            >
              <h6 className="consulation_list_li_data_inner_head">
                Payment Status
              </h6>
              {data?.payStatus}
            </div>
            <div className="consulation_list_data_left_inner" style={{ width: "100%" }}>
              <h6 className="consulation_list_li_data_inner_head">
                Date/Time
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.datetime}
              </h6>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default PaymentCard;
