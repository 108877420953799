import "../Orders/Orders.css";
import "../Orders/moodrately.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import { AiOutlineEye } from "react-icons/ai";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";
import { CSVLink } from "react-csv";
import { Row, Col } from "react-bootstrap";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import Pagination from "../../Component/Pagination";
import OrderCard from "../../Component/tableCard/OrderCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BiChat } from "react-icons/bi";

const CompleteOrder = (props) => {
  const history = useHistory();
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [excel, setExcel] = useState([]);
  const linkRef = useRef(null);

  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);
  let userData = JSON.parse(localStorage.getItem("userData"));

  const columns = [
    {
      headerName: "Patient Name",
      field: "name",
      cellRenderer: (d) => <span>{d?.data?.name}</span>,
    },
    {
      headerName: "Contact No",
      field: "contactNumber",
    },
    {
      headerName: "Shipping Address",
      field: "saddress",
    },
    {
      headerName: "Item Number",
      field: "Item",
    },
    {
      headerName: "Treatment Name",
      field: "Treatment",
    },
    {
      headerName: "Quantity",
      field: "Quantity",
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      cellRenderer: (params) => {
        return params.value === 0 ? (
          <span>Pending</span>
        ) : (
          params.value === 1 && <span>Confirm</span>
        );
      },
    },
    {
      headerName: "Order Status",
      field: "status",
      cellRenderer: (params) => {
        return (
          <span
           
            style={{
              cursor: "pointer",
              color: `${params?.value === 2 ? "#8b0000" : "#220a0a"}`,
            }}
          >
            {params?.data?.status === 0 && "Awaiting Payment"}{" "}
            {params?.data?.status === 1 && "Dispensing"}{" "}
            {params?.data?.status === 2 && "Shipped"}
          </span>
        );
      },
    },
    {
      headerName: "Shipping Tracking Number",
      field: "tracking",
    },
    {
      headerName: "Action",
      cellRenderer: (params) => {
        return (
          <span
            style={{
              width: "55px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <AiOutlineEye
              style={{ width: "21px", height: "18px", cursor: "pointer" }}
              onClick={() => window.open(params?.data?.pdfUrl)}
            />

            <BiChat
              style={{ cursor: "pointer" }}
              className="cursor-pointer"
              size={20}
              onClick={() => callroom(params?.data?.patientId)}
            />
          </span>
        );
      },
    },
    {
      headerName: "Order number",
      field: "ordernumber",
    },
  ];

  // modify order data
  const ordersListData = patients?.map((orders) => {
    return {
      id: orders?.id,
      patientName: <span>{orders?.name}</span>,
      orderNumber: orders?.ordernumber,
      contactNumber: orders?.contactNumber,
      email: orders?.email,
      address: orders?.address,
      itemNum: orders?.Item,
      treatmentName: orders?.Treatment,
      quantity: orders?.Quantity,
      paymentStatus:
        orders?.paymentStatus === 0
          ? "Pending"
          : orders?.paymentStatus === 1
          ? "Confirm"
          : "-",
      orderStatus: (
        <h6
          className="consulation_list_li_data_inner_head_data status"
          style={{
            cursor: "pointer",
            backgroundColor: `${
              orders?.status === 2 ? "#0080001a" : "#220a0a1a"
            }`,
            color: `${orders?.status === 2 ? "#8b0000" : "#220a0a"}`,
          }}
        >
          {orders?.status === 0
            ? "Awaiting Payment"
            : orders?.status === 1
            ? "Dispensing"
            : orders?.status === 2
            ? "Shipped"
            : "-"}
        </h6>
      ),
      shippingTrackNo: orders?.tracking,
      actionView: (
        <AiOutlineEye
          style={{ width: "21px", height: "18px", cursor: "pointer" }}
          onClick={() => window.open(orders?.pdfUrl)}
        />
      ),
    };
  });

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  const headers = [
    { label: "Patient Name", key: "name" },
    { label: "Contact No", key: "contactNumber" },
    { label: "Email Address", key: "email" },
    { label: "Billing Address", key: "address" },
    { label: "Shipping Address", key: "saddress" },
    { label: "Item Number", key: "Item" },
    { label: "Treatment Name", key: "Treatment" },
    { label: "Quantity", key: "Quantity" },
    { label: "Shipment Form", key: "Shipment" },
    { label: "Order Status", key: "status" },
    { label: "Shipment Form", key: "tracking" },
  ];


  //  get complate order data
  const getData = async (body) => {
    const bodyWithUserType = {
      ...body,
      orderstatus: 2, //complete order 2 status
    };
    setLoader(true);
    ApiPost(`admin/orders/get`, bodyWithUserType)
      .then((res) => {
        //

        setLoader(false);
        setPatients(
          res?.data?.data?.orders_data &&
            res?.data?.data?.orders_data.map((val, i) => {
              return {
                ordernumber: val?.orderNumber,
                name: `${
                  val?.patient?.[0]?.firstName && val?.patient?.[0]?.lastName
                    ? `${val?.patient?.[0]?.firstName} ${val?.patient?.[0]?.lastName}`
                    : "-"
                }`,
                contactNumber: val?.patient?.[0]?.contactNumber
                  ? val?.patient?.[0]?.contactNumber
                  : "-",
                email: val?.patient?.[0]?.email
                  ? val?.patient?.[0]?.email
                  : "-",
                // address: val?.patient?.[0]?.address ? val?.patient?.[0]?.address : "-",
                address: `${
                  val?.patient?.[0]?.billingAddress
                    ? val?.patient?.[0]?.billingAddress
                    : "-"
                },${val?.patient?.[0]?.city ? val?.patient?.[0]?.city : "-"},
            ${val?.patient?.[0]?.state ? val?.patient?.[0]?.state : "-"},
            ${val?.patient?.[0]?.country ? val?.patient?.[0]?.country : "-"},
            ${val?.patient?.[0]?.postcode ? val?.patient?.[0]?.postcode : "-"}
            `,
                saddress:
                  (val?.treatments?.[0]?.shippingAddress?.Address ||
                    val?.patient?.[0]?.billingAddress) +
                  ", " +
                  (val?.treatments?.[0]?.shippingAddress?.city ||
                    val?.patient?.[0]?.city) +
                  ", " +
                  (val?.treatments?.[0]?.shippingAddress?.state ||
                    val?.patient?.[0]?.state) +
                  ", " +
                  (val?.treatments?.[0]?.shippingAddress?.country ||
                    val?.patient?.[0]?.country) +
                  ", " +
                  (val?.treatments?.[0]?.shippingAddress?.postcode ||
                    val?.patient?.[0]?.postcode),
                Item: val?.treatments?.[0]?.itemNo
                  ? val?.treatments?.[0]?.itemNo
                  : "-",
                Treatment: val?.treatments?.[0]?.treatmentName
                  ? val?.treatments?.[0]?.treatmentName
                  : "-",
                Quantity: val?.treatments?.[0]
                  ?.quantity_of_unit_per_subscription
                  ? val?.treatments?.[0]?.quantity_of_unit_per_subscription
                  : "-",
                Shipment: val?.shipmentForm ? val?.shipmentForm : "-",
                status: val?.status,
                tracking:
                  val?.Subcription?.[0]?.shipmentCode && val?.status === 2
                    ? val?.Subcription?.[0]?.shipmentCode
                    : "-",
                patientId: val?.patientId,
                document: val?.treatments[0],
                paymentStatus: val?.treatments[0]?.paymentStatus,
                condition: val?.treatments[0]?.condition,
                pdfUrl: val?.Subcription[0]?.pdfPrint,
                id: val?._id,
                subscriptionId: val?.Subcription[0]?._id,
              };
            })
        );
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const searchdata = search.trim().split(/ +/).join(" ");
  // get complate order data
  const bodyData = {
    page: page,
    limit: perPage,
    search: searchdata,
    AtoZ: false,
  };
  useEffect(() => {
    getData(bodyData);
  }, [page, perPage]);

  // handle search
  const handleSearch = (searchData) => {
    if (searchData.length === 0) {
      getData({ ...bodyData, search: "" });
    }
    setSearch(searchData);
  };

  // handle search on search button
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getData(body);
  };

  useEffect(() => {
    // HandleSubmit()
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata]);

  // export excel
  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);
  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
      orderstatus: 2,
    };

    ApiPost(`admin/orders/get`, body)
      .then((res) => {
        setExcel(
          res?.data?.data?.orders_data &&
            res?.data?.data?.orders_data.map((val, i) => {
              return {
                ordernumber: val?.orderNumber,
                name: `${
                  val?.patient?.[0]?.firstName && val?.patient?.[0]?.lastName
                    ? `${val?.patient?.[0]?.firstName} ${val?.patient?.[0]?.lastName}`
                    : "-"
                }`,
                contactNumber: val?.patient?.[0]?.contactNumber
                  ? val?.patient?.[0]?.contactNumber
                  : "-",
                email: val?.patient?.[0]?.email
                  ? val?.patient?.[0]?.email
                  : "-",
                address: val?.patient?.[0]?.address
                  ? val?.patient?.[0]?.address
                  : "-",
                Item: val?.treatments?.[0]?.itemNo
                  ? val?.treatments?.[0]?.itemNo
                  : "-",
                Treatment: val?.treatments?.[0]?.treatmentName
                  ? val?.treatments?.[0]?.treatmentName
                  : "-",
                Quantity: val?.treatments?.[0]
                  ?.quantity_of_unit_per_subscription
                  ? val?.treatments?.[0]?.quantity_of_unit_per_subscription
                  : "-",
                Shipment: val?.shipmentForm ? val?.shipmentForm : "-",
                status:
                  val?.status === true
                    ? "Active"
                    : val?.status === false
                    ? "inActive"
                    : "",
                tracking: val?.trackingNumber ? val?.trackingNumber : "-",
              };
            })
        );
      })
      .catch((e) => {});
  };

  // sidebar responsiveness
  const [screenSize, setScreenSize] = useState(0);
  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  // initate chat between pharmacy and patient
  const callroom = async (recieverId) => {
    const body = {
      userIds: [recieverId, userData?._id],
    };

    await ApiPost("admin/room/get_roomId", body)
      .then((res) => {
        props?.isMobile
          ? history.push(`/ChatScreen`, { id: recieverId })
          : history.push(`/ChatScreenMobile/id/${recieverId}`, {
              id: recieverId,
            });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  return (
    <>
      <div className="chatscreen admindashboard pathology_main_div">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4> COMPLETE ORDERS</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"compelet order"}
                  headers={headers}
                ></CSVLink>
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="Input search text"
                      className="  pe-3 search search1"
                      value={search}
                      name="search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn searchbtn1 d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                  <button
                    className="button button1"
                    onClick={() => ExportExcel()}
                  >
                    Export to Excel
                  </button>
                </div>
              </div>
              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={patients}
                    page={page}
                    perpage={perPage}
                    setparpage={setPerPage}
                    setpage={setPage}
                    totalData={totalData}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {ordersListData?.map((items, index) => {
                      return <OrderCard key={index} data={items} />;
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>

    </>
  );
};

export default CompleteOrder;
