import React, { useContext, useEffect, useState, useRef } from "react";
import DashHeader from "../../Component/DashHeader";
import { Link, useLocation } from "react-router-dom";
import { appContext } from "../../helpers/AppContext";
import close_img from "../../Assets/images/chat/close.png";
import Search_img from "../../Assets/images/chat/Search.png";
import ChatLoader from "../../Component/ChatLoader/ChatLoader";
import { ApiPost } from "../../helpers/API/API_data";
import chatprofile from '../../Assets/images/chat/avatarBlue.png'
import './ChatScreenListMobile.css'
const ChatScreenMobileList = () => {
  const {roomContexData,setRoomContexData} = useContext(appContext);
  const [roomId, setRoomId] = useState();
  const [id, setId] = useState();
  const [userRoomId, setUserRoomId] = useState();
  const [rommSearch, SetRommSearch] = useState("");
  const [search, setsearch] = useState('');
  const focusref = useRef();
  const location = useLocation();
  let pathName = window.location.pathname;

  // get admin room data
  const getRoomData = async (i) => {
    let body = {
      search: i,
      page: 1,
      limit: 10000
    };
    await ApiPost("admin/room/get", body)
      .then((res) => {
        setsearch(i)
        setRoomContexData(res?.data?.data?.room_data)
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // search room by name
  let timeOutId;
  const handleSearch = (e) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
     
      getRoomData(e.target.value);
     
    }, 500);
  };

  // get room data every 5 sec
  useEffect(() => {

    let interval;

    if (pathName === "/ChatScreen") {
      
      interval = setInterval(async () => {
        await 
        getRoomData(search);
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };

  }, []);

  // set rooms when navigate from initiate chat or chat with patient and doctor
  useEffect(() => {
    if (location?.state?.id) {
      const userRoomId = roomContexData?.find(
        (ele) => ele?.user?._id === location?.state?.id
      );
      setUserRoomId(userRoomId);
    }
  }, [roomContexData]);

  



  useEffect(() => {
    focusref?.current?.focus();
    return () => {
    };
  }, [roomId]);

  useEffect(() => {
    if (userRoomId) {
      focusref?.current?.focus();
      return () => {
      };
    }
  }, [userRoomId?._id]);


  return (
    <>
    <div className="chatscreen admindashboard chat_with_admin mobile_chat_screen_list">
    <DashHeader />
        <div className="inner_div_dashboard" style={{paddingTop:"65px"}}>
          <div className="chat_screen_mobile_back_btn">
            <Link className="chat_screen_mobile_back_link" to={"/"}>
              <span className="chat_screen_mobile_back_span">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5079_71993)">
                    <path
                      d="M10.828 12.0002L15.778 16.9502L14.364 18.3642L8 12.0002L14.364 5.63623L15.778 7.05023L10.828 12.0002Z"
                      fill="#003B61"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5079_71993">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Back
            </Link>
          </div>
          <div className="customerlists customerlist">
            <div className="customersearch">
              <input
                type="text"
                placeholder="Search"
                value={rommSearch}
                onChange={(e) => {
                  handleSearch(e);
                  SetRommSearch(e.target.value);
                }}
              />
              <span>
                {rommSearch.length ? (
                  <img
                    alt=""
                    src={close_img}
                    onClick={() => {
                      SetRommSearch("");
                    }}
                  />
                ) : (
                  <img src={Search_img} />
                )}
              </span>
            </div>
            {/* room list */}
            <ul className="chatlists">
              {roomContexData?.length ? (
                roomContexData?.map((ele, i) => {
                  return (
                    <li className="activechats" key={i}>
                      <Link
                        to={`/ChatScreenMobile/${ele?._id}/${ele?.user?._id}`}
                        className={
                          ele.user?._id ===
                            (userRoomId ? userRoomId?.user?._id : id)
                            ? "active_user"
                            : ""
                        }
                      >
                        {" "}
                        <img
                          src={ele?.user?.image ? ele?.user?.image : chatprofile}
                          className="userAvatarMain"
                          alt=""
                        />
                        <div className="profileinfochat">
                          <h4>
                            {ele?.user?.firstName &&
                              ele?.user?.firstName + " " + ele?.user?.lastName}
                          </h4>
                          <p>{ele?.message?.message}</p>
                        </div>
                        {ele?.unReadCount ? (
                          <span className="massage_count">
                            {ele?.unReadCount}
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                    </li>
                  );
                })
              ) : (
                <div
                  className="chat_screen_loader"
                  style={{
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <ChatLoader />
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default ChatScreenMobileList;
