import React from "react";
import "./AddSubAdmin.css";
import { Row, Col, Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiPost, ApiUploadPost, Bucket } from "../../../helpers/API/API_data";
import { ErrorToast } from "../../../helpers/Toast";
import { subAdminUpdateSchema } from "../../../Schema/schema";
import edit from "../../../Assets/images/pencile.png";
import { useState } from "react";
import adminDefault from "../../../Assets/images/svg/adminDefault.svg";

const UpdateAdmin = ({
  open,
  setopen = () => {},
  setDataRefresh = () => {},
}) => {
  const [isloading, setIsloading] = useState(false);
  const userData = JSON.parse(localStorage?.getItem("userData"));
  const [profileImage, setprofileImage] = useState(userData?.image);

  // intial form values
  const initialFormValues = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    gender: userData?.gender,
    userType: userData?.userType,
    email: userData?.email,
    contactNumber: userData?.contactNumber,
    firstName: userData?.firstName,
  };

  // upload image ===>
  const handleimage = (e) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;

    setprofileImage([file]);
  };

  // react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(subAdminUpdateSchema),
    defaultValues: initialFormValues,
  });

  // handle update sub-admin data and update profile img
  const submitdata = async (data) => {
    let updatedLocalStore = { ...userData };
    setIsloading(true);

    if (!profileImage || profileImage.length === 0) {
      console.error("Profile image is null or empty");
      ErrorToast("Profile image is null or empty");
    } else {
      const formData = new FormData();
      formData.append("image", profileImage[0]);
      await ApiUploadPost("upload/profile_image", formData)
        .then((imgRes) => {
          if (imgRes?.data?.data?.image) {
            updatedLocalStore = {
              ...updatedLocalStore,
              image: imgRes?.data?.data?.image,
            };
          }
        })
        .catch((err) => {
          ErrorToast(err?.message);
        });
    }

    const body = {
      ...data,
      gender: Number(data?.gender),
      userType: Number(data?.userType),
      oldpassword: data?.oldpassword?.length ? data?.oldpassword : null,
      newpassword: data?.newpassword?.length ? data?.newpassword : null,
      image: updatedLocalStore?.image,
    };
    ApiPost("admin/subadmin/update", body)
      .then(async (res) => {
        const response = res?.data?.data?.response;
        if (response) {
          setopen(false);
          let storeUpdate = {
            ...updatedLocalStore,
            firstName: response?.firstName,
            lastName: response?.lastName,
            gender: response?.gender,
            contactNumber: response?.contactNumber,
            userType: response?.userType,
          };
          localStorage.setItem("userData", JSON.stringify(storeUpdate));
          setDataRefresh((prevState) => !prevState);
        }
        return res;
      })
      .catch((e) => {
        ErrorToast(e?.message);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  return (
    <Modal
      size="xl"
      title="Basic Modal"
      centered
      show={open}
      onHide={() => {
        setopen(false);
      }}
    >
      <Modal.Header closeButton className="modal_header">
        <Modal.Title id="contained-modal-title-vcenter">
          Update Admin
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="doctor_mediation">
          {/* form to update subadmin */}
          <Form>
            <Row>
              <Col lg={12} style={{ height: "120px" }}>
                <div className="editprofile updateEditProfile">
                  <img
                    src={
                      profileImage?.length > 0 || profileImage !== null
                        ? profileImage[0]?.fileURL
                          ? profileImage[0]?.fileURL
                          : profileImage
                          ? Bucket + profileImage
                          : adminDefault
                        : adminDefault
                    }
                    style={{
                      height: "100px ",
                      width: "100px",
                      backgroundColor: "#dfdcd8",
                    }}
                    alt=""
                    className="br-50 rounded-circle"
                  />
                  <div className="position-absolute labelFile">
                    <label htmlFor="images" className="">
                      <div className="editIcon">
                        <img src={edit} alt="" />
                      </div>
                      <input
                        type="file"
                        id="images"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        hidden
                        onChange={(e) => handleimage(e)}
                      />
                    </label>
                  </div>
                </div>
              </Col>
              {/*  */}
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">FirstName *</span>
                  <input
                    type="text"
                    placeholder="Enter Your FirstName"
                    name="firstName"
                    autoComplete="off"
                    {...register("firstName")}
                  />
                  {errors?.firstName && (
                    <p style={{ color: "red" }}>{errors?.firstName?.message}</p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">LastName *</span>
                  <input
                    type="text"
                    placeholder="Enter Your LastName"
                    name="lastName"
                    autoComplete="off"
                    {...register("lastName")}
                  />
                  {errors?.lastName && (
                    <p style={{ color: "red" }}>{errors?.lastName?.message}</p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Gender *</span>
                  <select
                    name="gender"
                    {...register("gender")} // Register the "gender" field
                  >
                    <option value={0}>Male</option>
                    <option value={1}>Female</option>
                    <option value={2}>Other</option>
                  </select>
                </div>
              </Col>
              {userData?.userType !== 2 ? (
                <Col lg={6} style={{ height: "108px" }}>
                  <div className="medicininput">
                    <span className="label_input">Role *</span>
                    <select
                      disabled
                      name="userType"
                      {...register("userType")} // Register the "gender" field
                    >
                      <option value={4}>Pharmacy</option>
                      <option value={3}>Sub-Admin</option>
                    </select>
                  </div>
                </Col>
              ) : (
                ""
              )}

              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Email</span>
                  <input
                    type="text"
                    placeholder="Enter Your Email"
                    name="email"
                    readOnly
                    autoComplete="off"
                    {...register("email")}
                  />
                  {errors?.email && (
                    <p style={{ color: "red" }}>{errors?.email?.message}</p>
                  )}
                </div>
              </Col>

              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Contact Number *</span>
                  <input
                    type="text"
                    placeholder="Enter Contact Number"
                    name="contactNumber"
                    autoComplete="off"
                    {...register("contactNumber")}
                  />
                  {errors?.contactNumber && (
                    <p style={{ color: "red" }}>
                      {errors?.contactNumber?.message}
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">Old Password</span>
                  <input
                    type="text"
                    placeholder="Enter Password"
                    name="oldpassword"
                    autoComplete="off"
                    {...register("oldpassword")}
                  />
                  {errors?.oldpassword && (
                    <p style={{ color: "red" }}>
                      {errors?.oldpassword?.message}
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={6} style={{ height: "108px" }}>
                <div className="medicininput">
                  <span className="label_input">New Password</span>
                  <input
                    type="text"
                    placeholder="Enter New Password"
                    name="newpassword"
                    autoComplete="off"
                    {...register("newpassword")}
                  />
                  {errors?.newpassword && (
                    <p style={{ color: "red" }}>
                      {errors?.newpassword?.message}
                    </p>
                  )}
                </div>
              </Col>

              <div className="btnredsubmit addBtnSubmit">
                <Link
                  to=""
                  className="assignDoctor_btn modal_submit"
                  onClick={handleSubmit((data) => {
                    submitdata(data);
                  })}
                >
                  {isloading ? "Updating.." : "UPDATE"}
                </Link>
              </div>
            </Row>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAdmin;
