import "./PatientSummryCard.css";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../../SideDrawer/SideDrawer";
import DashHeader from "../../../Component/DashHeader";
import { useHistory, useLocation } from "react-router-dom";

const PatientSummryCard = (props) => {
  const history = useHistory()
  const location = useLocation()

  const patientData = location?.state?.state?.patientData


  const patient = history?.location?.state?.state?.patient
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");

    }
    else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);

    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      {location?.state?.state?.type === "consultation" ? (
        <div className="chatscreen admindashboard patient_summary_card_main">
          <Row>
            <Col lg={2}>
              <SideDrawer />
            </Col>
            <Col lg={10}>
              <DashHeader />
              <div className="docsummry patientsum">
                <h3 className="mainheading ">
                  Patient summary card <span>#000123</span>
                </h3>
                <div className="patiemtsubdetail">
                  <ul className="patientdetail">
                    <li>
                      <span>Patient’s Name </span>{" "}
                      <p>{`${patientData?.patient[0]?.firstName} ${patientData?.patient?.[0]?.lastName}`}</p>
                    </li>
                    <li>
                      <span>Patient’s Address </span>{" "}
                      <p> {patientData?.patient?.[0]?.billingAddress ? patientData?.patient?.[0]?.billingAddress : "-"},{" "}
                        {patientData?.patient?.[0]?.city ? patientData?.patient?.[0]?.city : "-"},{" "}
                        {patientData?.patient?.[0]?.state ? patientData?.patient?.[0]?.state : "-"},{" "}
                        {patientData?.patient?.[0]?.country ? patientData?.patient?.[0]?.country : "-"},{" "}
                        {patientData?.patient?.[0]?.postcode ? patientData?.patient?.[0]?.postcode : "-"}</p>
                    </li>
                  </ul>
                  <h4 className="patienttitle">Patient Consult Notes:</h4>
                  <ul className="patientdetail">
                    <li>
                      <span>Current Condition</span>{" "}
                      <p>
                        {patientData?.condition === "STI/STD"
                          ? "STI Screening"
                          : patientData?.condition}
                      </p>
                    </li>
                    <li>
                      <span>Current Medication</span>{" "}
                      <p>
                          {
                            patientData?.patient[0]?.currentMedicine ? patientData?.patient[0]?.currentMedicine : "-"
                          }
                        {/* {patientData?.questions?.[0]?.questions.find(
                          (it) =>
                            it?.question === "What have you taken in the past?"
                        )?.answer
                          ? patientData?.questions?.[0]?.questions.find(
                            (it) =>
                              it?.question ===
                              "What have you taken in the past?"
                          )?.answer
                          : "-"} */}
                      </p>
                    </li>
                  </ul>
                  <h4 className="patienttitle">Product Selection</h4>
                  {patientData?.treatment?.map((item, i) => {
                    return (
                      <ul
                        key={`${i}${item?.treatmentName}`}
                        className="productselection"
                      >
                        <li>
                          <div className="productsubsele">
                            <span>Item Number</span>
                            <p>{item?.itemNo ? item?.itemNo : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>QTY</span>
                            <p>{item?.quantity ? item?.quantity : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Tratment</span>
                            <p>
                              {item?.treatmentName ? item?.treatmentName : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Provider</span>
                            <p>{item?.provider ? item?.provider : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Patient Name</span>
                            <p>{`${patientData?.patient?.[0]?.firstName} ${patientData?.patient?.[0]?.lastName}`}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Drug</span>
                            <p>{item?.active_drug ? item?.active_drug : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Form</span>
                            <p>{item?.form ? item?.form : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Route</span>
                            <p>{item?.route ? item?.route : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Dose</span>
                            <p>{item?.dose ? item?.dose : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Summary</span>
                            <p>
                              {item?.card?.[0]?.summery
                                ? item?.card?.[0]?.summery
                                : "-"}
                            </p>
                          </div>
                        </li>
                      </ul>
                    );
                  })}
                </div>
                <div
                  className="assigndoctor"
                  style={{ textAlign: "end", padding: "30px" }}
                >
                  <a href="" onClick={() => history.push("/ConsultationList")}>
                    Go to list{" "}
                  </a>
                </div>
              </div>
              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="chatscreen admindashboard patient_summary_card_main">
          <Row>
            <Col lg={2}>
              <SideDrawer />
            </Col>
            <Col lg={10}>
              <DashHeader />
              <div className="docsummry patientsum">
                <h3 className="mainheading ">
                  Patient summary card <span>#000123</span>
                </h3>
                <div className="patiemtsubdetail">
                  <ul className="patientdetail">
                    <li>
                      <span>Patient’s Name </span>{" "}
                      <p>{`${patient?.firstName} ${patient?.lastName}`}</p>
                    </li>
                    <li>
                      <span>Patient’s Address </span> <p>{patient?.address}</p>
                    </li>
                  </ul>
                  <h4 className="patienttitle">Patient Consult Notes:</h4>
                  <ul className="patientdetail">
                    <li>
                      <span>Current Condition</span>{" "}
                      <p>{patient?.card[0]?.condition}</p>
                    </li>
                    <li>
                      <span>Current Medication</span>{" "}
                      <p>
                        {patient?.currentMedicine ? patient?.currentMedicine : '-' }
                        {/* {patient?.patient_question
                          ? patient?.patient_question?.[0]?.questions.find(
                            (it) =>
                              it?.question ===
                              "What have you taken in the past?"
                          )?.answer
                          : "-"} */}
                      </p>
                    </li>
                  </ul>
                  <h4 className="patienttitle">Product Selection</h4>
                  {patient?.treatment?.map((item, i) => {
                    return (
                      <ul
                        key={`${i}${item?.treatmentName}`}
                        className="productselection"
                      >
                        <li>
                          <div className="productsubsele">
                            <span>Item Number</span>
                            <p>{item?.itemNo ? item?.itemNo : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>QTY</span>
                            <p>{item?.quantity ? item?.quantity : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Tratment</span>
                            <p>
                              {item?.treatmentName ? item?.treatmentName : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Provider</span>
                            <p>{item?.provider ? item?.provider : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Patient Name</span>
                            <p>{`${patient?.firstName} ${patient?.lastName}`}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Drug</span>
                            <p>{item?.active_drug ? item?.active_drug : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Form</span>
                            <p>{item?.form ? item?.form : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Route</span>
                            <p>{item?.route ? item?.route : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Dose</span>
                            <p>{item?.dose ? item?.dose : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span >Summary</span>
                            <p className="wrapit d-block">
                              {item?.card?.[0]?.summery
                                ? item?.card?.[0]?.summery
                                : "-"}
                            </p>
                          </div>
                        </li>
                      </ul>
                    );
                  })}
                  {/* <ul className="patientdetail">
                    <li><span>Summary</span> <p>{""}</p></li>
                  </ul> */}
                </div>
                <div
                  className="assigndoctor"
                  style={{ textAlign: "end", padding: "30px" }}
                >
                  <a href="" onClick={() => history.push("/MasterPatient")}>
                    Go to list{" "}
                  </a>
                </div>
              </div>
            </Col>
          </Row >
          <div className="copyright">
            <p>©2023 Frenchie M.D Pty Ltd</p>
          </div>
        </div >)}
    </>
  );
};

export default PatientSummryCard;
