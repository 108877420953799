import "./ViewPatient.css";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import profile from "../../Assets/images/patientimg.png";
import { Row, Col, Accordion } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost, Bucket } from "../../helpers/API/API_data";
import { useEffect } from "react";
import moment from "moment";
import DashHeader from "../../Component/DashHeader";
import Document from "./Document";
import TextTruncate from "react-text-truncate";
import { appContext } from "../../helpers/AppContext";
import { useContext } from "react";

const ViewPatient = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [patient, setPatient] = useState();
  const [pathologies, setPathologies] = useState();
  const [pdflink, setPdflink] = useState();
  const [card, setCard] = useState();
  const [Age, setAge] = useState();
  const location = useLocation();
  const [screenSize, setScreenSize] = useState(0);
  const { loader, setLoader } = useContext(appContext);
  const type = location?.state?.questionType;
  const date = new Date().getDay();
  const hour = new Date().getHours();

  // get patient detail
  const getData = async () => {
    const body = {
      id: id,
      condition: type,
    };
    await ApiPost(`admin/patientDetails`, body)
      .then(async (res) => {
        await setPatient(res?.data?.data[0]);
        await setPathologies(res?.data?.data?.[0]?.pathologies);
        await setPdflink(res?.data?.data?.[0]?.assingedpathologies[0]);
        await setCard(res?.data?.data?.[0]?.card);
        await calculateAge(res?.data?.data[0]?.additionalInfo?.dob);
        const temp = res?.data?.data[0]?.patient_question;
        const tempArr = [];
        temp.forEach((ele) => {
          ele.questions.map((item) => {
            return tempArr.push(item);
          });
        });
        // setAllergies(tempArr.find((it) => it.qustionType === "allergies").answer)
      })

      .catch((e) => {
        console.log("e", e);
      });
  };

  // calculate age
  const calculateAge = (dob) => {
    const today = new Date();
    let age =
      moment(today).format("DD-MM-YYYY").split("-")[2] - dob?.split("-")[2];
    setAge(age);
  };
  useEffect(() => {
    if (id) getData(id);
  }, [id]);

  // hide sidebar on responsiveness
  useEffect(() => {
    let a = document.getElementById("sidebar");
    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  let noNotesFound = true;
  const [expanded1, setExpanded1] = useState([]);

  // Function to toggle expansion for a specific treatment summary
  const toggleExpansion1 = (index) => {
    const newExpanded1 = [...expanded1];
    newExpanded1[index] = !newExpanded1[index];
    setExpanded1(newExpanded1);
  };

  // navigate to iniate sceen
  const navigateInitiateChat = async () => {
    setLoader(true);
    let body = {
      search: "",
      page: 1,
      limit: 100000,
    };
    await ApiPost("admin/room/get", body)
      .then((res) => {
        const userRoomId = res?.data?.data?.room_data?.find(
          (ele, i) => ele?.user?._id === id
        );

        if (userRoomId) {
          props?.isMobile
            ? history.push(`/ChatScreen`, { id: id })
            : history.push(`/ChatScreenMobile/id/${id}`, { id: id });
        } else {
          ApiPost("admin/subadmin/patientroom/add", { userid: id })
            .then((res) => {
              if (res?.data?.data?.createroom) {
                props?.isMobile
                  ? history.push(`/ChatScreen`, { id: id })
                  : history.push(`/ChatScreenMobile/id/${id}`, { id: id });
              }
            })
            .catch((err) => {})
            .finally(() => {
              setLoader(false);
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <>
      <div className="chatscreen admindashboard view_patient_main_div">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <div className="loader">
              <DashHeader />
              <div className="viewpatientmain activepatient">
                <div className="viewpatienttitle">
                  <Row>
                    <Col lg={6}>
                      <h4>View Patient</h4>
                    </Col>
                    <Col lg={6}>
                      <ul
                        className="uploaddoc"
                        style={{ columnGap: "20px", justifyContent: "end" }}
                      >
                        <li>
                          <a
                            onClick={() => {
                              navigateInitiateChat();
                            }}
                            style={{ color: "white", cursor: "pointer" }}
                          >
                            initiate Chat
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col lg={7}>
                    {patient?.doctor?.firstName ? (
                      <div className="profilepatient">
                        <h4>Treating Doctor</h4>
                        <ul className="patientprofile">
                          <li>
                            <div className="basicinfo">
                              <img
                                src={
                                  patient?.doctor?.image
                                    ? Bucket + patient?.doctor?.image
                                    : profile
                                }
                                alt=""
                                style={{ width: "100px", height: "100px" }}
                                className="rounded-pill"
                              />
                              <div className="johnphillips">
                                <h5>
                                  {patient?.doctor?.firstName ?? "unknown"}{" "}
                                  {patient?.doctor?.lastName ?? "unknown"}{" "}
                                </h5>
                                {patient?.doctor?.additionalInfo?.conditionSpecialities?.map(
                                  (item, i) => {
                                    return <p key={`${i}${item}`}>{item}</p>;
                                  }
                                )}
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="patientbasicdetail">
                              <span>
                                {patient?.doctor?.schedule?.monday === true &&
                                date === 1 &&
                                patient?.doctor?.schedule
                                  ?.mondaymorningStartTime?.h <= hour &&
                                hour <=
                                  patient?.doctor?.schedule
                                    ?.mondaymorningEndTime?.h
                                  ? `${patient?.doctor?.schedule?.mondaymorningStartTime?.h}:${patient?.doctor?.schedule?.mondaymorningStartTime?.m} To ${patient?.doctor?.schedule?.mondaymorningEndTime?.h}:${patient?.doctor?.schedule?.mondaymorningEndTime?.m} `
                                  : patient?.doctor?.schedule?.tuesday ===
                                      true &&
                                    date === 2 &&
                                    patient?.doctor?.schedule
                                      ?.tuesdaymorningStartTime?.h <= hour &&
                                    hour <=
                                      patient?.doctor?.schedule
                                        ?.tuesdaymorningEndTime?.h
                                  ? `${patient?.doctor?.schedule?.tuesdaymorningStartTime?.h}:${patient?.doctor?.schedule?.tuesdaymorningStartTime?.m} To ${patient?.doctor?.schedule?.tuesdaymorningEndTime?.h}:${patient?.doctor?.schedule?.tuesdaymorningEndTime?.m} `
                                  : patient?.doctor?.schedule?.wednesday ===
                                      true &&
                                    date === 3 &&
                                    patient?.doctor?.schedule
                                      ?.wednesdaymorningStartTime?.h <= hour &&
                                    hour <=
                                      patient?.doctor?.schedule
                                        ?.wednesdaymorningEndTime?.h
                                  ? `${patient?.doctor?.schedule?.wednesdaymorningStartTime?.h}:${patient?.doctor?.schedule?.wednesdaymorningStartTime?.m} To ${patient?.doctor?.schedule?.wednesdaymorningEndTime?.h}:${patient?.doctor?.schedule?.wednesdaymorningEndTime?.m} `
                                  : patient?.doctor?.schedule?.thursday ===
                                      true &&
                                    date === 4 &&
                                    patient?.doctor?.schedule
                                      ?.thursdaymorningStartTime?.h <= hour &&
                                    hour <=
                                      patient?.doctor?.schedule
                                        ?.thursdaymorningEndTime?.h
                                  ? `${patient?.doctor?.schedule?.thursdaymorningStartTime?.h}:${patient?.doctor?.schedule?.thursdaymorningStartTime?.m} To ${patient?.doctor?.schedule?.thursdaymorningEndTime?.h}:${patient?.doctor?.schedule?.thursdaymorningEndTime?.m} `
                                  : patient?.doctor?.schedule?.friday ===
                                      true &&
                                    date === 5 &&
                                    patient?.doctor?.schedule
                                      ?.fridaymorningStartTime?.h <= hour &&
                                    hour <=
                                      patient?.doctor?.schedule
                                        ?.fridaymorningEndTime?.h
                                  ? `${patient?.doctor?.schedule?.fridaymorningStartTime?.h}:${patient?.doctor?.schedule?.fridaymorningStartTime?.m} To ${patient?.doctor?.schedule?.fridaymorningEndTime?.h}:${patient?.doctor?.schedule?.fridaymorningEndTime?.m} `
                                  : patient?.doctor?.schedule?.saturday ===
                                      true &&
                                    date === 6 &&
                                    patient?.doctor?.schedule
                                      ?.saturdaymorningStartTime?.h <= hour &&
                                    hour <=
                                      patient?.doctor?.schedule
                                        ?.saturdaymorningEndTime?.h
                                  ? `${patient?.doctor?.schedule?.saturdaymorningStartTime?.h}:${patient?.doctor?.schedule?.saturdaymorningStartTime?.m} To ${patient?.doctor?.schedule?.saturdaymorningEndTime?.h}:${patient?.doctor?.schedule?.saturdaymorningEndTime?.m} `
                                  : patient?.doctor?.schedule?.sunday ===
                                      true &&
                                    date === 0 &&
                                    patient?.doctor?.schedule
                                      ?.sundaymorningStartTime?.h <= hour &&
                                    hour <=
                                      patient?.doctor?.schedule
                                        ?.sundaymorningEndTime?.h
                                  ? `${patient?.doctor?.schedule?.sundaymorningStartTime?.h}:${patient?.doctor?.schedule?.sundaymorningStartTime?.m} To ${patient?.doctor?.schedule?.sundaymorningEndTime?.h}:${patient?.doctor?.schedule?.sundaymorningEndTime?.m} `
                                  : "No Available"}
                              </span>
                              <p className="application">
                                <span className="dot"></span> Application
                                Consultation
                              </p>
                              <p className="text">
                                {patient?.doctor?.additionalInfo?.bio
                                  ? patient?.doctor?.additionalInfo?.bio
                                  : "-"}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* patient profile */}
                    <div
                      className="profilepatient"
                      style={{ marginBottom: "30px" }}
                    >
                      <h4>Profile</h4>
                      <ul className="profilepatientsubdetail">
                        <li>
                          <div className="profilename">
                            <span>Name</span>
                            <p>
                              {patient?.firstName ?? "unknown"}{" "}
                              {patient?.lastName ?? "unknown"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Phone number</span>
                            <p>{patient?.contactNumber ?? 0}</p>
                          </div>
                        </li>{" "}
                        <li>
                          <div className="profilename">
                            <span>Condition</span>=
                            <p>
                              {patient?.condition === "STI/STD"
                                ? "STI Screening"
                                : patient?.condition}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Email</span>
                            <p>{patient?.email ?? "unknown@unknown.com"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Billing Address</span>
                            <p>
                              {patient?.billingAddress
                                ? patient?.billingAddress
                                : "-"}
                              , {patient?.city ? patient?.city : "-"},{" "}
                              {patient?.state ? patient?.state : "-"},{" "}
                              {patient?.country ? patient?.country : "-"},{" "}
                              {patient?.postcode ? patient?.postcode : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Shipping Address</span>
                            <p>
                              {patient?.shippingAddress?.Address
                                ? patient?.shippingAddress?.Address
                                : "-"}
                              ,{" "}
                              {patient?.shippingAddress?.city
                                ? patient?.shippingAddress?.city
                                : "-"}
                              ,{" "}
                              {patient?.shippingAddress?.state
                                ? patient?.shippingAddress?.state
                                : "-"}
                              ,{" "}
                              {patient?.shippingAddress?.country
                                ? patient?.shippingAddress?.country
                                : "-"}
                              ,{" "}
                              {patient?.shippingAddress?.postcode
                                ? patient?.shippingAddress?.postcode
                                : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Allergies</span>
                            <p>
                              {patient?.allergies ? patient?.allergies : "No"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Date of Birth</span>
                            <p>
                              {patient?.additionalInfo?.dob
                                ? patient?.additionalInfo?.dob
                                : "-"}
                            </p>
                          </div>
                        </li>{" "}
                        <li>
                          <div className="profilename">
                            <span>Gender</span>
                            <p>
                              {patient?.gender === 0
                                ? "Male"
                                : patient?.gender === 1
                                ? "Female"
                                : patient?.gender === 2
                                ? "Intersex"
                                : "-"}
                            </p>
                          </div>
                        </li>{" "}
                        <li>
                          <div className="profilename">
                            <span>Gender Identity</span>
                            <p>
                              {patient?.identityGender
                                ? patient?.identityGender
                                : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Regular Medications</span>
                            <p>
                              {patient?.currentMedicine
                                ? patient?.currentMedicine
                                : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Age</span>
                            <p>{Age ? `${Age} Year` : "-"}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* regular doctor */}
                    <div
                      className="profilepatient"
                      style={{ rowGap: "3px", marginBottom: "30px" }}
                    >
                      <h4>Regular Doctor</h4>
                      {patient?.additionalInfo?.regularDoctor ? (
                        <ul className="profilepatientsubdetail">
                          <li>
                            <div className="profilename">
                              <span>First Name</span>
                              <p>
                                {patient?.additionalInfo?.regularDoctor
                                  ?.firstName
                                  ? patient?.additionalInfo?.regularDoctor
                                      ?.firstName
                                  : "-"}
                              </p>
                            </div>
                          </li>

                          <li>
                            <div className="profilename">
                              <span>Last Name</span>
                              <p>
                                {patient?.additionalInfo?.regularDoctor
                                  ?.lastName
                                  ? patient?.additionalInfo?.regularDoctor
                                      ?.lastName
                                  : "-"}
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="profilename">
                              <span>Clinic Name</span>
                              <p>
                                {patient?.additionalInfo?.regularDoctor
                                  ?.clinicName
                                  ? patient?.additionalInfo?.regularDoctor
                                      ?.clinicName
                                  : "-"}
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="profilename">
                              <span>Email</span>
                              <p>
                                {patient?.additionalInfo?.regularDoctor?.email
                                  ? patient?.additionalInfo?.regularDoctor
                                      ?.email
                                  : "-"}
                              </p>
                            </div>
                          </li>
                        </ul>
                      ) : (
                        <span
                          className="d-flex justify-content-center py-3"
                          style={{ fontFamily: "Helvetica Neue" }}
                        >
                          There are no regular doctor
                        </span>
                      )}
                    </div>

                    {/* recent pathology */}
                    <div
                      className="profilepatient"
                      style={{ marginBottom: "30px" }}
                    >
                      <h4>Recent Pathology </h4>
                      <div style={{ maxHeight: "150px", overflow: "auto" }}>
                        {pathologies?.length ? (
                          pathologies.map((val, i) => {
                            return (
                              <>
                                <ul
                                  key={`${i}${val?.itemNumber}`}
                                  className="profilepatientsubdetail"
                                >
                                  <li>
                                    <div className="profilename">
                                      <span>Request Number</span>
                                      <p>{val?.itemNumber}</p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="profilename">
                                      <span>Report Type</span>
                                      <p>
                                        {pdflink?.SonicResultData?.length >
                                        0 ? (
                                          <>
                                            <p
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "start",
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              {pdflink?.SonicResultData?.map(
                                                (v, i) => {
                                                  return (
                                                    <>
                                                      <div>
                                                        {" "}
                                                        {i + 1}.{" "}
                                                        {v?.pathologyName}
                                                      </div>
                                                      <span>
                                                        {pdflink
                                                          ?.SonicResultData
                                                          ?.length !==
                                                          i + 1 && " "}
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </p>
                                          </>
                                        ) : (
                                          <>{val?.reportType}</>
                                        )}
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                                {i !== pathologies?.length - 1 && <hr />}
                              </>
                            );
                          })
                        ) : (
                          <span
                            className="d-flex justify-content-center py-3"
                            style={{ fontFamily: "Helvetica Neue" }}
                          >
                            There are no Recent Pathology{" "}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* Frenchie Rx */}
                    <div
                      className="profilepatient"
                      style={{ marginBottom: "30px" }}
                    >
                      <h4>Frenchie Rx</h4>
                      <div
                        style={{
                          maxHeight: "150px",
                          overflow: "auto",
                          margin: "22px",
                        }}
                      >
                        {patient?.treatment.length ? (
                          patient?.treatment.map((vel, i) => {
                            return (
                              <>
                                <ul
                                  key={`${i}${vel?.treatmentName}`}
                                  className="profilepatientsubdetail"
                                >
                                  <li>
                                    <div className="profilename">
                                      <span>Treatment</span>
                                      <p>
                                        {vel?.treatmentName
                                          ? vel?.treatmentName
                                          : "-"}
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="profilename">
                                      <span>Drug Name</span>
                                      <p>
                                        {vel?.drugName ? vel?.drugName : "-"}
                                      </p>
                                    </div>
                                  </li>{" "}
                                  <li>
                                    <div className="profilename">
                                      <span>Quantity</span>
                                      <p>
                                        {vel?.quantity ? vel?.quantity : "-"}
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="profilename">
                                      <span>Number of repeats</span>
                                      <p>{vel?.number_of_repeats}</p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="profilename">
                                      <span>Next Payment Due</span>
                                      <p>
                                        {vel?.subscription?.nextPaymentDate
                                          ? moment(
                                              vel?.subscription?.nextPaymentDate
                                            )?.format("MM-DD-YYYY")
                                          : "-"}
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                                {i !== patient?.treatment.length - 1 && <hr />}
                              </>
                            );
                          })
                        ) : (
                          <span
                            className="d-flex justify-content-center py-3"
                            style={{ fontFamily: "Helvetica Neue" }}
                          >
                            There are no Frenchie Rx
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={5}>
                    {/* CONSULTATION NOTES*/}
                    <div
                      className="profilepatient p-3"
                      style={{ marginBottom: "16px" }}
                    >
                      <h4 className="p-0">CONSULTATION NOTES</h4>

                      <div
                        style={{
                          maxHeight: "467px",
                          overflow: "auto",
                          cursor: "pointer",
                        }}
                      >
                        <div>
                          {patient?.card?.length ? (
                            patient?.card?.map((v, i) => {
                              noNotesFound = false;
                              return (
                                <div
                                  className="consnotenew mt-3"
                                  key={i}
                                  onClick={() =>
                                    history.push("/PatientSummryCard", {
                                      state: { card, patient },
                                    })
                                  }
                                >
                                  <div
                                    className="patienttitle mb-0"
                                    style={{
                                      flexDirection: "column",
                                      fontFamily: "Helvetica Neue",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", width: "100%" }}
                                    >
                                      <div className="application d-flex">
                                        <div
                                          style={{
                                            color: "#003b61",
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {i + 1}. Treatment Summary
                                        </div>
                                      </div>
                                      <div className="patientnumber">
                                        <div
                                          style={{
                                            color: "#212529",
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {moment(v?.date).format("LLL")}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="descripsum">
                                      <div
                                        style={{
                                          color: "#212529",
                                          fontWeight: "500",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        Description:{" "}
                                      </div>
                                      <div
                                        style={{
                                          color: "#212529",
                                          fontWeight: "300",
                                          fontSize: "14px",
                                        }}
                                        className="mt-1"
                                      >
                                        <div className="wrapit">
                                          <TextTruncate
                                            line={expanded1[i] ? null : 2}
                                            element="span"
                                            truncateText="…"
                                            text={v?.summery ? v?.summery : "-"}
                                            textTruncateChild={
                                              <a
                                                onClick={() =>
                                                  toggleExpansion1(i)
                                                }
                                              >
                                                {expanded1[i]
                                                  ? "Read less"
                                                  : "Read more"}
                                              </a>
                                            }
                                            onTruncate={(wasTruncated) => {
                                              if (wasTruncated) {
                                                setExpanded1((prevExpanded) => {
                                                  const newExpanded1 = [
                                                    ...prevExpanded,
                                                  ];
                                                  newExpanded1[i] = true;
                                                  return newExpanded1;
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      {noNotesFound && (
                        <span
                          className="d-flex justify-content-center"
                          style={{ fontFamily: "Helvetica Neue" }}
                        >
                          There are No Data
                        </span>
                      )}
                    </div>
                    <Document patientdataid={patient?._id} />
                  </Col>
                </Row>

                <Row>
                  {/*<h3>Consult Question Summary</h3>*/}
                  <div className="col-lg-12">
                    <div className="cunsultncyquestion">
                      <h3>Consult Question Summary</h3>
                      {patient?.patient_question.map((item, index) => {
                        return (
                          <Accordion key={`${index}${item?.questions}`}>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                {item?.questionType === "STI/STD"
                                  ? "STI Screening"
                                  : item?.questionType}
                                <span>
                                  {moment(patient?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="mt-5 w-100">
                                  {item?.questions.slice(1, 4).map((ele, i) => {
                                    return (
                                      <>
                                        <div
                                          key={`${i}${ele.question}`}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div>
                                            <h4 className="questions__text">
                                              <span className="numblue">
                                                {i + 1}
                                              </span>
                                              {ele.question}
                                            </h4>
                                            <h4 className="answer__text">
                                              {ele.answer}
                                            </h4>
                                          </div>
                                        </div>
                                        <hr></hr>
                                      </>
                                    );
                                  })}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}
                    </div>
                  </div>
                </Row>
              </div>
            </div>

            <div className="copyright">
              <p>©2023 Frenchie M.D Pty Ltd</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewPatient;
