import "./AddCoupons.css";
import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import DashHeader from "../../Component/DashHeader";
import { useForm } from "react-hook-form";
import { ApiPost } from "../../helpers/API/API_data";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import Select from "react-select";

const AddCoupons = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const history = useHistory();
  const signupSchema = yup.object().shape({
    startDate: yup.string().required("StartDate Is Required"),
    couponCode: yup.string().required("Coupon Code Is Required"),
    discount: yup.number().positive().required("Discount Is Required"),
    usePerCoupon: yup.string().required("Useper Coupon Is Required"),
    minimum_purchase_requirements: yup
      .number()
      .positive("Minimum Purchase Requirements Must Be a positive Number")
      .required("Minimum Purchase Requirements Is Required"),
    userPerCustomer: yup.string().required("UserPerCustomer Is Required"),
    couponName: yup.string().required("CouponName Is Required"),
    purchaseType: yup.string().required("PurchaseType Is Required"),
    status: yup.string().required("Status Is Required"),
    discountType: yup.string().required("DiscountType Is Required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(signupSchema),
  });

  const submitdata = (data) => {
    const body = {
      couponCode: data?.couponCode.toString(),
      couponName: data?.couponName,
      discountType: data?.discountType,
      usePerCoupon: parseInt(data?.usePerCoupon),
      userPerCustomer: data?.userPerCustomer,
      customerId: [],
      status: parseInt(data?.status),
      minimum_purchase_requirements: parseInt(
        data?.minimum_purchase_requirements
      ),
      startDate: data?.startDate,
      endDate: data?.endDate,
      purchaseType: parseInt(data?.purchaseType),
      conditionType: data?.conditionType?.map((ele) => ele?.value),
      discount: data?.discount,
    };
    ApiPost("admin/add/coupon", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        history.push("/CouponeList");
      })
      .catch((e) => {
        ErrorToast(e?.message);
      });
  };
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);
  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <DashHeader />
            <div className="activepatient">
              <div className="titlepatient">
                <h4>Create COUPONS</h4>
              </div>
              <div className="couponsfield">
                <div className="ui form">
                  <div className="two fields">
                    <div className="field start-date">
                      <label>Start date</label>
                      <div className="ui calendar" id="rangestart">
                        <div className=" left icon">
                          <input
                            {...register("startDate")}
                            type="date"
                            placeholder="Start"
                            min={new Date().toISOString().split("T")[0]}
                            max={endDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                        {errors?.startDate && (
                          <p className="error">{errors?.startDate?.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="field end-date">
                      <label>End date</label>
                      <div className="ui calendar" id="rangeend">
                        <div className=" left icon">
                          <input
                            {...register("endDate")}
                            type="date"
                            placeholder="End"
                            min={startDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                        {errors?.endDate && (
                          <p className="error">{errors?.endDate?.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="field">
                      <label>Coupons Name</label>
                      <input
                        {...register("couponName")}
                        type="text"
                        placeholder="Enter Coupons Name"
                      />
                      {errors?.couponName && (
                        <p className="error">{errors?.couponName.message}</p>
                      )}
                    </div>
                  </div>
                  <Row>
                    <Col lg={4}>
                      <div className="field">
                        <label>Coupons Code</label>
                        <input
                          {...register("couponCode")}
                          type="text"
                          placeholder="Enter Coupons Code"
                        />
                        {errors?.couponCode && (
                          <p className="error">{errors?.couponCode.message}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="field">
                        <label>Discount Type</label>
                        <Select
                          {...register("discountType")}
                          onChange={(e) => setValue("discountType", e.value)}
                          options={[
                            { label: "Amount", value: 0 },
                            { label: "Percentage", value: 1 },
                          ]}
                        />
                        {errors?.discountType && (
                          <p className="error">
                            {errors?.discountType.message}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="field">
                        <label>Discount</label>
                        <input
                          {...register("discount")}
                          type="number"
                          defaultValue={0}
                        />
                        {errors?.discount && (
                          <p className="error">{errors?.discount.message}</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="field">
                        <label>Purchase type</label>
                        <Select
                          {...register("purchaseType")}
                          onChange={(e) => setValue("purchaseType", e.value)}
                          options={[
                            { label: "Consult fee", value: 0 },
                            { label: "First treatment", value: 1 },
                            { label: "On-going treatment", value: 2 },
                          ]}
                        />
                        {errors?.purchaseType && (
                          <p className="error">
                            {errors?.purchaseType.message}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="field">
                        <label>Status</label>
                        <Select
                          {...register("status")}
                          onChange={(e) => setValue("status", e.value)}
                          options={[
                            { label: "Active", value: 1 },
                            { label: "InActive", value: 0 },
                          ]}
                        />
                        {errors?.status && (
                          <p className="error">{errors?.status.message}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="field">
                        <label>condition</label>
                        <Select
                          {...register("conditionType")}
                          onChange={(e) => setValue("conditionType", e)}
                          options={[
                            { label: "Erectile Dysfunction", value: 0 },
                            { label: "Premature Ejaculation", value: 1 },
                            { label: "STI Screening", value: 2 },
                            { label: "Thrush / Bacterial Vaginosis", value: 5 },
                            { label: "Urinary Tract Infection", value: 6 },
                            { label: "All", value: 7 },
                          ]}
                          isMulti={true}
                        />
                        {errors?.conditionType && (
                          <p className="error">
                            {errors?.conditionType.message}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="field">
                        <label>Use per Coupon</label>
                        <Select
                          {...register("usePerCoupon")}
                          onChange={(e) => setValue("usePerCoupon", e.value)}
                          options={[
                            { label: "1", value: 1 },
                            { label: "2", value: 2 },
                            { label: "3", value: 3 },
                            { label: "No Limit", value: 4 },
                          ]}
                        />
                        {errors?.usePerCoupon && (
                          <p className="error">
                            {errors?.usePerCoupon?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="field">
                        <label>Minimum purchase requirements</label>
                        <input
                          {...register("minimum_purchase_requirements")}
                          type="number"
                          placeholder="AMOUNT"
                          defaultValue={0}
                        />
                        {errors?.minimum_purchase_requirements && (
                          <p className="error">
                            {errors?.minimum_purchase_requirements?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="field">
                        <label>Use per Customer </label>
                        <Select
                          {...register("userPerCustomer")}
                          onChange={(e) => setValue("userPerCustomer", e.value)}
                          options={[
                            { label: "1", value: 1 },
                            { label: "2", value: 2 },
                            { label: "3", value: 3 },
                            { label: "No Limit", value: 4 },
                          ]}
                        />
                        {errors?.userPerCustomer && (
                          <p className="error">
                            {errors?.userPerCustomer?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="btnredsubmit mt-0">
                    <Button
                      onClick={handleSubmit((data) => {
                        submitdata(data);
                      })}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddCoupons;
