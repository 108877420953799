import './ViewPatient.css';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Accordion }
	from 'react-bootstrap';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import SideDrawer from '../SideDrawer/SideDrawer';
import { ApiGet, ApiPost, Bucket } from '../../helpers/API/API_data';
import { useEffect } from 'react';
import moment from 'moment';
import DashHeader from '../../Component/DashHeader';
import Document from './Document'
import TextTruncate from 'react-text-truncate';
import { appContext } from '../../helpers/AppContext';
import { useContext } from 'react';

const ViewConsultation = (props) => {
	const { id } = useParams()
	const history = useHistory()
	const [patient, setPatient] = useState();
	const [pathologies, setPathologies] = useState();

	const [card, setCard] = useState();
	const [Age, setAge] = useState();
	const location = useLocation();

	const [screenSize, setScreenSize] = useState(0);
	const { loader, setLoader } = useContext(appContext);

	const type = location?.state?.questionType

	const getData = async () => {
		const body = {
			_id: id,
			condition: type
		}
		await ApiPost(`admin/patient/viewconsultation`, body)
			.then(async (res) => {

				await setPatient(res?.data?.data[0]);
				await setPathologies(res?.data?.data?.[0]?.assingedpathologies)
				await setCard(res?.data?.data?.[0]?.card)
				await calculateAge(res?.data?.data[0]?.additionalInfo?.dob)
				const temp = res?.data?.data[0]?.patient_question;
				const tempArr = [];
				temp.forEach((ele) => {
					ele.questions.map((item) => {
						return (
							tempArr.push(item)
						)
					})
				})
			

			})

			.catch((e) => {
				console.log("e", e);
			});
	};
	const calculateAge = (dob) => {
		const today = new Date();
		let age = moment(today).format("DD-MM-YYYY").split("-")[2] - dob?.split("-")[2];
		setAge(age)
	}
	useEffect(() => {
		if (id) getData(id);
	}, [id]);

	useEffect(() => {
		let a = document.getElementById("sidebar");
		if (a && screenSize <= 1440) {
			a.classList.add("d-none");
			a.classList.remove("d-block");

		}
		else {
			a.classList.add("d-block");
			a.classList.remove("d-none");
		}
		const handleResize = () => {
			setScreenSize(window.innerWidth);

		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);

	}, [screenSize])

	let noNotesFound = true;


	const [noteExpanded, setNoteExpanded] = useState([]);


	const toggleNoteExpansion = (index) => {
		const newExpanded = [...noteExpanded];
		newExpanded[index] = !newExpanded[index];
		setNoteExpanded(newExpanded);
	};

	const [expanded1, setExpanded1] = useState([]);
	const toggleExpansion1 = (index) => {
		const newExpanded1 = [...expanded1];
		newExpanded1[index] = !newExpanded1[index];
		setExpanded1(newExpanded1);
	};

	// navigate to iniate sceen
	const navigateInitiateChat = async () => {
		setLoader(true);
		// history.push(`/ChatScreen`, { id: id })
		let body = {
			// userId: userData?._id,
			search: "",
			page: 1,
			// limit: 20
			limit: 100000,
		};
		await ApiPost("admin/room/get", body)
			.then((res) => {
				const userRoomId = res?.data?.data?.room_data?.find(
					(ele, i) => ele?.user?._id === id
				);
				if (userRoomId) {
					props?.isMobile ? history.push(`/ChatScreen`, { id: id }) : history.push(`/ChatScreenMobile/id/${id}`, { id: id })
				} else {
					ApiPost("admin/subadmin/patientroom/add", { userid: id })
						.then((res) => {
							if (res?.data?.data?.createroom) {
								props?.isMobile ? history.push(`/ChatScreen`, { id: id }) : history.push(`/ChatScreenMobile/id/${id}`, { id: id })
							}
						})
						.catch((err) => {
							console.log("err", err);
						})
						.finally(() => {
							setLoader(false);
						});
				}
			})
			.catch((err) => {
				console.log("err", err);
			})
			.finally(() => {
				setLoader(false);
			});
	};
	return (
		<>
			<div className="chatscreen admindashboard">

				<Row>
					<Col lg={2}>
						<SideDrawer />
					</Col>
					<Col lg={10}>
						<div className="loader">
							<DashHeader />
							<div className="viewpatientmain">
								<div className="viewpatienttitle">
									<Row>
										<Col lg={6}>
											<h4>VIEW CONSULTATION</h4>
										</Col>
										<Col lg={6}>
											<ul className='uploaddoc' style={{ columnGap: '20px', justifyContent: 'end' }}>
												<li>
													<a
														onClick={() => { navigateInitiateChat() }}
														style={{ color: 'white', cursor: 'pointer' }}>initiate Chat</a>
												</li>
											</ul>
										</Col>
									</Row>
								</div>
								<Row>
									<Col lg={7}>

										<div className="profilepatient" style={{ marginBottom: "30px" }}>
											<h4>Profile</h4>
											<ul className='profilepatientsubdetail'>
												<li>
													<div className="profilename">
														<span>Name</span>
														<p>{patient?.patient[0]?.firstName} {patient?.patient[0]?.lastName}</p>
													</div>
												</li>
												<li>
													<div className="profilename">
														<span>Phone number</span>
														<p>{patient?.patient[0]?.contactNumber ?? 0}</p>
													</div>
												</li>    <li>
													<div className="profilename">
														<span>Condition</span>

														<p>{patient?.condition === "STI/STD" ? "STI Screening" : patient?.condition}</p>
													</div>
												</li>
												<li>
													<div className="profilename">
														<span>Email</span>
														<p>{patient?.patient[0]?.email}</p>
													</div>
												</li>    <li>
													<div className="profilename">
														<span>Address</span>
														<p>
															{patient?.patient?.[0]?.billingAddress ? patient?.patient?.[0]?.billingAddress : "-"},{" "}
															{patient?.patient?.[0]?.city ? patient?.patient?.[0]?.city : "-"},{" "}
															{patient?.patient?.[0]?.state ? patient?.patient?.[0]?.state : "-"},{" "}
															{patient?.patient?.[0]?.country ? patient?.patient?.[0]?.country : "-"},{" "}
															{patient?.patient?.[0]?.postcode ? patient?.patient?.[0]?.postcode : "-"}
														</p>
													</div>
												</li>    <li>
													<div className="profilename">
														<span>Allergies</span>
														<p>{patient?.patient[0]?.allergies ? patient?.patient[0]?.allergies : "No"}</p>
													</div>
												</li>
												<li>
													<div className="profilename">
														<span>Date of Birth</span>
														<p>{patient?.patient[0]?.additionalInfo?.dob ? patient?.patient[0]?.additionalInfo?.dob : '-'}</p>
													</div>
												</li>    <li>
													<div className="profilename">
														<span>Gender</span>
														<p>{patient?.patient[0]?.gender === 0 ? "Male" : patient?.patient[0]?.gender === 1 ? "Female" : "other"}</p>
													</div>
												</li>    <li>
													<div className="profilename">
														<span>Gender Identity</span>
														<p>{patient?.patient[0]?.identityGender ? patient?.patient[0]?.identityGender : '-'}</p>
													</div>
												</li>
												<li>
													<div className="profilename">
														<span>Regular Medications</span>
														<p>{patient?.patient[0]?.currentMedicine ? patient?.patient[0]?.currentMedicine : "-"}</p>
													</div>
												</li>
												<li>
													<div className="profilename">
														<span>Age</span>
														<p>{Age ? `${Age} Year` : "-"}</p>
													</div>
												</li>
											</ul>
										</div>


										<div className="profilepatient" style={{ rowGap: '3px', marginBottom: "30px" }}>
											<h4>Regular Doctor</h4>
											{patient?.patient?.[0]?.additionalInfo
												?.regularDoctor ?
												<ul className='profilepatientsubdetail' >
													<li>
														<div className="profilename">
															<span>First Name</span>
															<p>{patient?.patient?.[0]?.additionalInfo?.regularDoctor?.firstName ? patient?.patient?.[0]?.additionalInfo?.regularDoctor?.firstName : "-"}</p>
														</div>
													</li>

													<li>
														<div className="profilename">
															<span>Last Name</span>
															<p>{patient?.patient?.[0]?.additionalInfo?.regularDoctor?.lastName ? patient?.patient?.[0]?.additionalInfo?.regularDoctor?.lastName : "-"}</p>

														</div>
													</li>
													<li>
														<div className="profilename">
															<span>Clinic Name</span>
															<p>{patient?.patient?.[0]?.additionalInfo?.regularDoctor?.clinicName ? patient?.patient?.[0]?.additionalInfo?.regularDoctor?.clinicName : "-"}</p>

														</div>
													</li>
													<li>
														<div className="profilename">
															<span>Email</span>
															<p>{patient?.patient?.[0]?.additionalInfo?.regularDoctor?.email ? patient?.patient?.[0]?.additionalInfo?.regularDoctor?.email : "-"}</p>

														</div>
													</li>
												</ul> : <span className='d-flex justify-content-center py-3' style={{ fontFamily: "Helvetica Neue" }}>There are no regular doctor</span>}
										</div>
										<div className="profilepatient" style={{ marginBottom: "30px" }}>
											<h4>Recent Pathology </h4>
											<div style={{ maxHeight: "150px", overflow: "auto" }}>
												{
													pathologies?.length ? pathologies.map((val, i) => {
														return (
															<>
																<ul key={`${i}${val?.pathology?.itemNumber}`} className='profilepatientsubdetail'>
																	<li>
																		<div className="profilename">
																			<span>Request Number</span>
																			<p>{val?.pathology?.itemNumber}</p>
																		</div>
																	</li>
																	<li>
																		<div className="profilename">
																			<span>Report Type</span>
																			<p style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', wordWrap: 'break-word' }}>
																				{val?.SonicResultData?.length > 0 ?
																					<>
																						{val?.SonicResultData?.map((v, i) => {
																							return (
																								<>
																									<div>{i + 1}. {v?.pathologyName}</div>
																									<span>{val?.SonicResultData?.length !== i + 1 && " "}</span>
																								</>
																							)
																						})}
																					</> : <p>{val?.pathology?.reportType}</p>}</p>
																		</div>
																	</li>
																</ul>
																{i !== pathologies.length - 1 && <hr />}

															</>
														)
													}) : <span className='d-flex justify-content-center py-3' style={{ fontFamily: "Helvetica Neue" }}>There are no Recent Pathology </span>

												}


											</div>
										</div>
										<div className="profilepatient" style={{ marginBottom: "30px" }}>
											<h4>Frenchie Rx</h4>
											<div style={{ maxHeight: "150px", overflow: "auto", margin: '22px' }}>
												{patient?.treatment.length ? patient?.treatment.map((vel, i) => {
													return (
														<>
															<ul key={`${i}${vel?.treatmentName}`} className='profilepatientsubdetail'>
																<li>
																	<div className="profilename">
																		<span>Treatment</span>
																		<p>{vel?.treatmentName ? vel?.treatmentName : "-"}</p>
																	</div>
																</li>
																<li>
																	<div className="profilename">
																		<span>Drug Name</span>
																		<p>{vel?.drugName ? vel?.drugName : "-"}</p>
																	</div>
																</li>    <li>
																	<div className="profilename">
																		<span>Quantity</span>
																		<p>{vel?.quantity ? vel?.quantity : "-"}</p>

																	</div>
																</li>
																<li>
																	<div className="profilename">
																		<span>Number of repeats</span>
																		<p>{vel?.number_of_repeats
																		}</p>
																	</div>
																</li>
																<li>
																	<div className="profilename">
																		<span>Next Payment Due</span>
																		<p>
																			{vel?.subscription?.nextPaymentDate ? moment(vel?.subscription?.nextPaymentDate)?.format("MM-DD-YYYY") : "-"}
																		</p>
																	</div>
																</li>
															</ul>
															{i !== patient?.treatment.length - 1 && <hr />}
														</>
													)

												}) : <span className='d-flex justify-content-center py-3' style={{ fontFamily: "Helvetica Neue" }}>There are no Frenchie Rx</span>
												}

											</div>
										</div>

									</Col>
									<Col lg={5}>
										<div className='profilepatient p-3' style={{ marginBottom: '16px' }}>
											<h4 className='p-0'>CONSULTATION NOTES</h4>

											<div style={{ maxHeight: "467px", overflow: "auto", cursor: 'pointer' }}
											>

												<div>
													{patient?.card?.length ? (
														patient?.card?.map((v, i) => {
															noNotesFound = false;
															return (
																<div className="consnotenew mt-3" key={i}
																	onClick={() => history.push('/PatientSummryCard', { state: { card, patientData: patient, type: "consultation" } })}
																>
																	<div
																		className="patienttitle mb-0"
																		style={{
																			flexDirection: "column",
																			fontFamily: "Helvetica Neue",
																			marginTop: "10px",
																		}}
																	>
																		<div style={{ display: "flex", width: "100%" }}>
																			<div className="application d-flex">
																				<div style={{ color: '#003b61', fontWeight: '500', fontSize: '16px' }}>
																					{i + 1}. Treatment Summary
																				</div>
																			</div>
																			<div className="patientnumber">
																				<div style={{ color: '#212529', fontWeight: '500', fontSize: '16px' }}>
																					{moment(v?.date).format("LLL")}
																				</div>
																			</div>
																		</div>
																		<div className="descripsum">
																			<div style={{ color: '#212529', fontWeight: '500', fontSize: '12px' }}> Description: </div>
																			<div style={{ color: '#212529', fontWeight: '300', fontSize: '14px' }} className='mt-1'>
																				<div className="wrapit">
																					<TextTruncate
																						line={expanded1[i] ? null : 2}
																						element="span"
																						truncateText="…"
																						text={v?.summery ? v?.summery : "-"}
																						textTruncateChild={
																							<a onClick={() => toggleExpansion1(i)}>
																								{expanded1[i] ? 'Read less' : 'Read more'}
																							</a>
																						}
																						onTruncate={(wasTruncated) => {
																							if (wasTruncated) {
																								setExpanded1((prevExpanded) => {
																									const newExpanded1 = [...prevExpanded];
																									newExpanded1[i] = true;
																									return newExpanded1;
																								});
																							}
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															);
														})
													) : (
														<div></div>
													)}
												</div>
											</div>

											{noNotesFound && (
												<span className="d-flex justify-content-center" style={{ fontFamily: "Helvetica Neue" }}>
													There are No Data
												</span>
											)}

										</div>
										<Document patientdataid={patient?.patient[0]?._id} />
									</Col>
								</Row>

								<Row>
									<div className="col-lg-12">
										<div className="cunsultncyquestion">
											<h3>Consult Question Summary</h3>
											{
												patient?.patient_questions?.map((item, index) => {
													return (
														<Accordion key={`${index}${item?.questions}`}>
															<Accordion.Item eventKey="0">
																<Accordion.Header>{item?.questionType === "STI/STD" ? "STI Screening" : item?.questionType}<span>{moment(patient?.createdAt).format("DD/MM/YYYY")}</span></Accordion.Header>
																<Accordion.Body>
																	<div className='mt-5 w-100'>
																		{item?.questions.slice(1, 4).map((ele, i) => {
																			return (
																				<>
																					<div key={`${i}${ele.question}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
																						<div>
																							<h4 className='questions__text'><span className="numblue">{i + 1}</span>{ele.question}</h4>
																							<h4 className='answer__text'>{ele.answer}</h4>
																						</div>

																					</div>
																					<hr></hr>
																				</>
																			)
																		})
																		}

																	</div>
																</Accordion.Body>
															</Accordion.Item>
														</Accordion>
													)
												})
											}


										</div>
									</div>
								</Row>
							</div>
						</div>

						<div className="copyright">
							<p>©2023 Frenchie M.D Pty Ltd</p>
						</div>
					</Col>
				</Row >
			</div >
		</>
	)

};

export default ViewConsultation;
