import "./ViewDoctor.css";
import React, { useContext, useState } from "react";
import chatprofile from "../../Assets/images/chat/chatprofile.png";
import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { useEffect } from "react";
import { ApiPost, Bucket } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";
import PatientData from "./Patientdata";
import Auth from "../../helpers/Auth";
import Loader from "../../Assets/images/chat/loading.gif";
import { appContext } from "../../helpers/AppContext";
import { ReactComponent as SingleTick } from "../../Assets/images/chat/singleTick.svg";
import { ReactComponent as DoubleTicks } from "../../Assets/images/chat/doubleTicks.svg";
import { ReactComponent as BlueTicks } from "../../Assets/images/chat/blueTicks.svg";
import Say_hi from "../../Assets/images/Say_hi.gif";
import { useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import moment from "moment";
import ViewDoctorCard from "../../Component/tableCard/ViewDoctorCard";
import Pagination from "../../Component/Pagination";
import CalendarComponent from "../../Component/calendar/Calendar";

const ViewDoctor = () => {
  const { socket, connectSocket, isTablet, itemPerPage } =
    useContext(appContext);
  const [doctorData, setDoctorData] = useState();
  const messageEl = useRef(null);
  const [patientData, setPatientData] = useState();
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [userMessage, setUserMessage] = useState([]);
  const userData = Auth.getUserData();
  const [roomId, setRoomId] = useState();
  const [rooms, setRooms] = useState();
  const [chatData, setchatData] = useState([]);
  const [name, setName] = useState();
  const [isUserTyping, setisUserTyping] = useState(false);
  const focusref = useRef();
  const elementRef = useRef(null);
  const [page, setPage] = useState(1);

  // modify doctor data
  const viewDoctorData = patientData?.map((doctor) => {
    return {
      name: (
        <span>{`${doctor?.patient?.firstName} ${doctor?.patient?.lastName}`}</span>
      ),
      condition: doctor?.condition?.map((ele) => <span>{ele}</span>),
      date: (
        <span>
          {doctor?.patient?.createdAt &&
            moment(doctor?.patient?.createdAt).format("LLL")}
        </span>
      ),
      status:
        doctor?.consultStatus === 4 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#0080001a", color: "green" }}
          >
            {"Treatment Plan Sent"}
          </h6>
        ) : doctor?.consultStatus === 1 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#0080001a", color: "green" }}
          >
            {"Paid Unassigned Doctor"}
          </h6>
        ) : doctor?.consultStatus === 2 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#0080001a", color: "green" }}
          >
            {"Paid Unfulfilled"}
          </h6>
        ) : doctor?.consultStatus === 3 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#0080001a", color: "green" }}
          >
            {" Live Consult"}
          </h6>
        ) : doctor?.consultStatus === 0 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#ff00001a", color: "red" }}
          >
            {"Not Paid"}
          </h6>
        ) : doctor?.consultStatus === 5 ? (
          <h6
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#e031311a", color: "#E03131" }}
          >
            {"Awaiting Pathology"}
          </h6>
        ) : (
          "-"
        ),
    };
  });

  // Pagination
  const handlePageChange = (pageNum) => setPage(pageNum);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(patientData?.length / itemPerPage);

  // get doctor by id
  const getData = async (id) => {
    const body = {
      doctorId: id,
    };
    await ApiPost("admin/doctor/by_id", body)
      .then((res) => {
        setDoctorData(res?.data?.data?.[0]);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  // set room id 
  const callroom = async (doctor, admin) => {
    const body = {
      userIds: [doctor, admin],
    };
    await ApiPost("admin/room/get_roomId", body)
      .then((res) => {
        setRoomId(res?.data?.data?._id);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  // functions to getroom id and get data
  useEffect(() => {
    getData(id);
    callroom(id, userData?._id);
  }, [id]);


  // get patient by doctor id
  const getPatientData = async (id) => {
    const body = {
      doctorId: id,
    };
    await ApiPost(`admin/patient/get_by_doctor`, body)
      .then((res) => {
        setPatientData(res?.data?.data);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

    // get patient by doctor id
  useEffect(() => {
    getPatientData(id);
  }, [id]);

  //*********************Chat*****************************

  // handle sent msg
  const handleSendMessage = (e, message) => {
    e.preventDefault();
    if (message.trim()) {
      socket?.emit("send_message", {
        senderId: userData?._id,
        roomId: roomId,
        receiverId: id,
        message: message.trim(),
      });
      setMessage("");
    }
  };

 
  const handleKeySendMsg = (e) => {
    e.preventDefault();
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage(e, message);
    }
  };

  const sendermsg = [];
  const receivermsg = [];
  for (let i = 0; i < userMessage.length; i++) {
    sendermsg.push(
      userMessage[i - 1]?.senderId !== userData?._id &&
        userMessage[i]?.senderId === userData?._id &&
        userMessage[i]
    );
    receivermsg.push(
      userMessage[i - 1]?.senderId === userData?._id &&
        userMessage[i]?.senderId !== userData?._id &&
        userMessage[i]
    );
  }

  socket?.off("receive_message").on("receive_message", (data) => {
    socket?.emit("message_deliver_status", {
      messageId: data._id,
      status: data?.roomId === roomId ? 2 : 1,
      roomId: data?.roomId,
    });
    if (data?.roomId === roomId) {
      setUserMessage((prev) => {
        return JSON.parse(JSON.stringify([...prev, data]));
      });
    }
  });
  socket
    ?.off("receive_message_with_updated_status")
    .on("receive_message_with_updated_status", (resData) => {
      if (resData?.roomId === roomId) {
        setUserMessage(resData?.response);
      }
    });
  socket
    ?.off("message_response")
    .on("message_response", async (messageResponse) => {
      setUserMessage((prev) => [...prev, messageResponse]);
    });

    // scroll to bottom of chats
  const scrollToBottomWithSmoothScroll = () => {
    messageEl.current?.addEventListener("DOMNodeInserted", (event) => {
      const { currentTarget: target } = event;
      target.scroll({ top: target.scrollHeight, behavior: "auto" });
    });
  };

  // load msg with scrolling
  const firstEvent = async (e) => {
    const element = e.target;
    const currentScrollPos = element.scrollTop;
    if (currentScrollPos === 0) {
      // Get the current scroll height before loading new data
      const prevScrollHeight = element.scrollHeight;
      const newScrollHeight = element.scrollHeight;
      // Calculate the difference in scroll height
      const scrollHeightDiff = newScrollHeight - prevScrollHeight;
      // Set the scroll position to maintain the user's position
      element.scrollTop = currentScrollPos + scrollHeightDiff;
      // Make your API call here
      await loadMoreMessages(page + 1);
    }
  };
  const loadMoreMessages = async (i) => {
    let body = {
      page: i,
      limit: 20,
      roomId: roomId,
    };
    try {
      const res = await ApiPost("admin/room/message/get", body);
      const newMessages = res?.data?.data?.message_data.reverse();
      if (page >= res?.data?.data?.state?.page_limit) {
        return;
      } else {
        setUserMessage((prevMessages) => [...newMessages, ...prevMessages]);
        setPage((page) => page + 1);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    scrollToBottomWithSmoothScroll();
    const temp = [];

    // modify user msg 
    if (userMessage.length) {
      userMessage.forEach((ele, i) => {
        if (
          userMessage[i - 1]?.createdAt &&
          moment(userMessage[i - 1]?.createdAt).format("DD/MM/YYY") ===
            moment(ele?.createdAt).format("DD/MM/YYY")
        ) {
          temp[temp.length - 1].item.push(ele);
        } else {
          temp.push({
            date: ele.createdAt,
            item: [ele],
          });
        }
        setchatData(temp);
      });
    }
  }, [userMessage]);

  // hide sidebar on responsiveness
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let a = document.getElementById("sidebar");
    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  // get selected room msg
  const callmessage = async () => {
    socket?.emit("join_room", { roomId: roomId, userId: userData?._id });
    let body = {
      page: 1,
      limit: 20,
      roomId: roomId,
    };
    await ApiPost("admin/room/message/get", body)
      .then((res) => {
        setUserMessage(res?.data?.data?.message_data.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const scrollToBottom = () => {
    if (elementRef.current) {
      elementRef.current.scrollToBottom = elementRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    // Call the scroll function after the component has rendered
    scrollToBottom();
  }, [page]);
  useEffect(() => {
    const name = rooms?.find((ele) => ele?.user?._id === id);
    setName(name);
    focusref?.current.focus();
    callmessage();

    return () => {
    };
  }, [roomId]);

  useEffect(() => {
    socket?.emit("online", { userId: userData?._id });
  }, [socket]);
  useEffect(() => {
    connectSocket();
  }, []);

  return (
    <>
      <div className="chatscreen admindashboard view_doctor_main_section">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <DashHeader />
            <div className="viewpatientmain viewmaindoc activepatient">
              <Row>
                <Col lg={6}>
                  <div className="viewpatienttitle">
                    <h4>View Doctor</h4>
                  </div>
                  <div className="profilepatient">
                    <h4>doctor details</h4>
                    <ul className="profilepatientsubdetail">
                      <li>
                        <div className="profilename">
                          <span>Name</span>
                          <p>
                            {doctorData?.firstName
                              ? `${doctorData?.firstName} ${doctorData?.lastName}`
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Phone number</span>
                          <p>{doctorData?.contactNumber}</p>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="profilename">
                          <span>Condition Specialties</span>
                          {doctorData?.additionalInfo?.conditionSpecialities.map(
                            (el) => (
                              <p>
                                {el === "STI/STD" ? "STI Screening" : el + ","}
                              </p>
                            )
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Pronouns</span>
                          <p>
                            {doctorData?.additionalInfo?.pronouns
                              ? doctorData?.additionalInfo?.pronouns
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Email</span>
                          <p>{doctorData?.email}</p>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="profilename">
                          <span>Address</span>
                          <p>{doctorData?.address}</p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Gender</span>
                          <p>
                            {doctorData?.gender === 0
                              ? "Male"
                              : doctorData?.gender === 1
                              ? "Female"
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Date of Birth</span>
                          <p>
                            {doctorData?.additionalInfo?.dob
                              ? doctorData?.additionalInfo?.dob
                              : "-"}{" "}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Prescriber No</span>
                          <p>{doctorData?.additionalInfo?.prescriberNumber}</p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Current Gender Identity</span>
                          <p>
                            {doctorData?.gender === 0
                              ? "Male"
                              : doctorData?.gender === 1
                              ? "Female"
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Frenchie MD Provider Number</span>
                          <p>
                            {doctorData?.additionalInfo?.frenchieProviderNumber
                              ? doctorData?.additionalInfo
                                  ?.frenchieProviderNumber
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Average Consult Response Time</span>
                          <p>
                            {parseInt(doctorData?.card[0]?.time / 60)
                              ? `${parseInt(
                                  doctorData?.card[0]?.time / 60
                                )}Minuts`
                              : "-"}{" "}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {isTablet ? (
                      <PatientData data={patientData} isDoctorView={true} />
                    ) : (
                      <>
                        <ul className="active_patient_ul mb-4">
                          {viewDoctorData?.map((items, index) => {
                            return <ViewDoctorCard key={index} data={items} />;
                          })}
                        </ul>
                        {patientData?.length > itemPerPage && (
                          <Pagination
                            page={page}
                            handlePageChange={handlePageChange}
                            handleNextClick={handleNextClick}
                            handlePrevClick={handlePrevClick}
                            totalPages={totalPages}
                          />
                        )}
                      </>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="chatwithdoctor">
                    <div className="viewpatienttitle">
                      <h4>Chat with doctor</h4>
                    </div>
                    <div className="chatdoctor">
                      <ul
                        className="chatprofileheader"
                        style={{ width: "100%" }}
                      >
                        <li>
                          <img
                            src={
                              doctorData?.image
                                ? Bucket + doctorData?.image
                                : chatprofile
                            }
                            alt=""
                            className="rounded-circle"
                            style={{ width: "80px", height: "80px" }}
                          />
                          <div className="chatmeninfo">
                            <h4>{`${doctorData?.firstName} ${doctorData?.lastName}`}</h4>
                            <p>{doctorData?.email}</p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.7924 4.81154C19.6637 4.73524 19.5044 4.73226 19.373 4.80393L14.7842 7.30955V5.80614C14.7828 4.63659 13.8351 3.68882 12.6655 3.6875H2.11864C0.949086 3.68882 0.00132415 4.63659 0 5.80614V14.1715C0.00132415 15.341 0.949086 16.2888 2.11864 16.2901H12.6655C13.8351 16.2888 14.7828 15.341 14.7842 14.1715V12.6954L19.3732 15.201C19.5044 15.2727 19.6638 15.2699 19.7924 15.1936C19.9212 15.1171 20 14.9785 20 14.8291V5.17585C20 5.02622 19.921 4.88784 19.7924 4.81154ZM13.9364 14.1716C13.9357 14.8734 13.367 15.442 12.6652 15.4428H2.11864C1.41684 15.442 0.848285 14.8734 0.847458 14.1716V5.80614C0.848285 5.10451 1.41684 4.53579 2.11864 4.53496H12.6655C13.3672 4.53579 13.9359 5.10451 13.9367 5.80614L13.9364 14.1716ZM19.1525 14.115L14.7842 11.7299V8.27503L19.1525 5.89006V14.115Z"
                                fill="#003B61"
                              />
                            </svg>
                          </div>
                        </li>
                      </ul>

                      <div
                        className="msgsend mainchat"
                        style={{ height: "59vh", width: "100%" }}
                      >
                        {!chatData.length && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100vh",
                            }}
                          >
                            <div className="view_modal_msg">
                              <img style={{ height: "50px" }} src={Say_hi} />
                              <p>
                                Say hi{" "}
                                {doctorData?.firstName
                                  ? `${doctorData?.firstName} ${doctorData?.lastName}`
                                  : "-"}{" "}
                                with a wave
                              </p>
                              <button
                                className="button"
                                onClick={(e) => {
                                  handleSendMessage(e, "Hi");
                                }}
                              >
                                Say hi
                              </button>
                            </div>
                          </div>
                        )}
                        <ul
                          className="chats"
                          ref={messageEl}
                          onScroll={firstEvent}
                          style={{ margin: "0" }}
                          id="chat-feed"
                        >
                          {/* render chat data */}
                          {chatData.length
                            ? chatData.map((item, i) => (
                                <>
                                  <li className="display_date">
                                    {moment(new Date()).format("DD-MM-YYYY") ===
                                    moment(new Date(item?.date)).format(
                                      "DD-MM-YYYY"
                                    )
                                      ? "Today"
                                      : moment(new Date(item?.date)).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </li>

                                  {item.item.map((res, i) => (
                                    <>
                                      {res.senderId === userData._id && (
                                        <li
                                          className="send_massage"
                                          style={{ marginTop: "3px" }}
                                          key={`sender${i}`}
                                        >
                                          <div className="massage_sent">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "end",
                                              }}
                                            >
                                              <div style={{ padding: "10px" }}>
                                                {res.message}
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "end",
                                                  gap: "5px",
                                                  margin: "5px",
                                                  minWidth: "45px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "10px",
                                                    padding: "0px",
                                                    color: "darkgrey",
                                                  }}
                                                >{`${new Date(
                                                  res?.createdAt
                                                ).getHours()}:${new Date(
                                                  res?.createdAt
                                                ).getMinutes()}`}</span>
                                                {res?.status === 0 ? (
                                                  <SingleTick
                                                    width="12px"
                                                    height="12px"
                                                  />
                                                ) : res?.status === 1 ? (
                                                  <DoubleTicks
                                                    width="12px"
                                                    height="12px"
                                                  />
                                                ) : (
                                                  <BlueTicks
                                                    width="16px"
                                                    height="16px"
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="ms-2 me-2"
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                            }}
                                          >
                                            {sendermsg.includes(res) ? (
                                              <img
                                                src={
                                                  userData?.image
                                                    ? Bucket + userData?.image
                                                    : chatprofile
                                                }
                                                alt="sender"
                                                style={{
                                                  height: "30px",
                                                  width: "30px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </li>
                                      )}
                                      {res.senderId !== userData._id && (
                                        <li
                                          className="mt-2 receiv_massage"
                                          key={`receiver${i}`}
                                        >
                                          <div
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                              marginRight: "8px",
                                            }}
                                          >
                                            {receivermsg.includes(res) ? (
                                              <img
                                                src={
                                                  doctorData?.image
                                                    ? Bucket + doctorData?.image
                                                    : chatprofile
                                                }
                                                alt="receiver"
                                                className="rounded-pill"
                                                style={{
                                                  height: "30px",
                                                  width: "30px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div
                                            className="massage_sent"
                                            style={{
                                              backgroundColor:
                                                "rgb(227, 248, 255)",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "5px",
                                                  minWidth: "10px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "10px",
                                                    padding: "0px",
                                                    backgroundColor:
                                                      "rgb(227, 248, 255)",
                                                    color: "darkgrey",
                                                  }}
                                                >{`${new Date(
                                                  res?.createdAt
                                                ).getHours()}:${new Date(
                                                  res?.createdAt
                                                ).getMinutes()}`}</span>
                                              </div>
                                              <div style={{ padding: "10px" }}>
                                                {res.message}
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </>
                                  ))}
                                </>
                              ))
                            : ""}
                        </ul>
                      </div>
                      {isUserTyping?.flag &&
                        isUserTyping?.roomId === roomId && (
                          <>
                            <span>
                              {name?.user?.firstName} is typing{" "}
                              <img src={Loader} />
                            </span>
                          </>
                        )}
                      <div
                        className="entermsgsends"
                        style={{
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          margin: "0px !important",
                          width: "100%",
                          bottom: "15px",
                          justifyContent: "center",
                          padding: "0px 10px",
                        }}
                      >
                        <TextareaAutosize
                          ref={focusref}
                          style={{ padding: "15px 20px" }}
                          minRows={1}
                          maxRows={5}
                          placeholder={`Write a message for ${doctorData?.firstName} ${doctorData?.lastName}`}
                          value={message}
                          onChange={(e) => {
                            socket?.emit("is_user_typing", true);
                            setTimeout(() => {
                              socket?.emit("is_user_typing", false);
                            }, 2000);
                            setMessage(e?.target?.value);
                          }}
                          onKeyUp={(e) => handleKeySendMsg(e)}
                          className={message ? "set_button" : "set_input"}
                        />
                        {message?.length ? (
                          <Link
                            onClick={(e) => handleSendMessage(e, message)}
                            style={{ position: "unset" }}
                          >
                            Send
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} style={{marginTop:"30px"}}>
                  <CalendarComponent doctorId={id}/>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="copyright">
        <p>©2023 Frenchie M.D Pty Ltd</p>
      </div>
    </>
  );
};

export default ViewDoctor;
