import { useContext } from "react";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "../../Component/modals/ShipingTrackNumber.css";
import { ApiPut } from "../../helpers/API/API_data";
import { appContext } from "../../helpers/AppContext";

const ShipingTrackNumber = (props) => {

  const [trackingNumber, setTrackingNumber] = useState();
  const [error, setError] = useState("");
  const { loader, setLoader } = useContext(appContext);

  // submit shipmentCode once order sgipped
  const HandleSubmit = () => {
    const body = {
      shipmentCode: trackingNumber,
      _id: props.id,
    };

    const bodydata = {
      limit: props.perpage,
      page: props.page,
    };
    setLoader(true)
    if (!trackingNumber) {
      setError("Enter Shipping Tracking Number");
    } else {
      ApiPut(`admin/patient/subscription`, body).then((res) => {
        props.getdata(bodydata);
      });
      setError("");
    }
  };

  return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header className="model-header" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Shipping Tracking Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body">
          <div className="activepatient">
            <div className="medicinlist">
              {/* form to submit shipcode */}
              <Form>
                <Row>
                  <Col lg={12}>
                    <div className="medicininput mb-0">
                      <span style={{ color: "white" }}>
                        Shipping Tracking Number{" "}
                      </span>
                      <input
                        type="text"
                        name="trackingnumber"
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.target.value)}
                      />
                    </div>
                    <span style={{ color: "burlywood" }}>{error}</span>
                  </Col>
                </Row>
                <div className="btnredsubmit submit-btn">
                  <Button onClick={HandleSubmit}> Submit </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
};

export default ShipingTrackNumber;
