import "./CouponeList.css";
import React, { useEffect, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiDelete, ApiPost } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";
import { SuccessToast } from "../../helpers/Toast";
import { useContext } from "react";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Table from "../../Component/Table/Table";
import Pagination from "../../Component/Pagination";
import CouponCard from "../../Component/tableCard/CouponCard";

const CouponeList = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [perpage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [screenSize, setScreenSize] = useState(0);

  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);

  const history = useHistory();

  const columns = [
    {
      headerName: "Coupon Name",
      field: "couponName",
    },
    {
      headerName: "Code",
      field: "couponCode",
    },
    {
      headerName: "Discount",
      field: "discount",
    },
    {
      headerName: "Purchase type",
      field: "purchaseType",
    },
    {
      headerName: "Start date",
      field: "startDate",
    },
    {
      headerName: "End date",
      field: "endDate",
    },
    {
      headerName: "Status",
      field: "status",
    },
    {
      headerName: "Action",
      field: "_id",
      cellRenderer: (d) => {
        return (
          <span>
            <FaUserEdit
              size={20}
              role="button"
              onClick={() => history.push(`/UpdateCoupons/${d?.data?._id}`)}
            />
            &nbsp;&nbsp;{" "}
            <AiFillDelete
              size={20}
              role="button"
              onClick={() => deleteCoupon(d?.data?._id)}
            />
          </span>
        );
      },
    },
  ];

  // modify coupon data
  const couponListData = data?.map((coupons) => {
    return {
      id: coupons?._id,
      couponName: coupons?.couponName,
      couponCode: coupons?.couponCode,
      discount: coupons?.discount,
      purchaseType: coupons?.purchaseType,
      startDate: coupons?.startDate,
      endDate: coupons?.endDate,
      status: coupons?.status,
      editCoupon: (
        <FaUserEdit
          size={20}
          role="button"
          onClick={() => history.push(`/UpdateCoupons/${coupons?._id}`)}
        />
      ),
      deleteCoupon: (
        <AiFillDelete
          size={20}
          role="button"
          onClick={() => deleteCoupon(coupons?._id)}
        />
      ),
    };
  });

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  // get coupon data
  useEffect(() => {
    getData();
  }, [page, perpage]);

  const getData = async () => {
    setLoader(true);
    const body = {
      page: page,
      limit: perpage,
    };
    await ApiPost("admin/coupon", body)
      .then((res) => {
        setLoader(false);
        setData(
          res?.data?.data?.coupon_data &&
            res?.data?.data?.coupon_data.map((element) => {
              return {
                couponName: element?.couponName,
                couponCode: element?.couponCode,
                discount: element?.discount,
                startDate:
                  element?.startDate &&
                  moment(element?.startDate).format("DD/MM/YYYY"),
                endDate:
                  element?.endDate &&
                  moment(element?.endDate).format("DD/MM/YYYY"),
                status: element?.status === 0 ? "InActive" : "Active",
                _id: element?._id,
                purchaseType:
                  element?.purchaseType === 0
                    ? "Consult fee"
                    : element?.purchaseType === 1
                    ? "First treatment"
                    : "On-going treatment",
              };
            })
        );
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  // delete coupon data
  const deleteCoupon = async (id) => {
    await ApiDelete(`/coupon/${id}`).then((res) => {
      getData();
      SuccessToast(res?.data?.message);
    });
  };

  // hide sidebar on responsiveness
  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Coupons List</h4>
                <div className="searchbox">
                  <Link className="button ms-3 addcoupone" to="/AddCoupons">
                    + Add Coupon
                  </Link>
                </div>
              </div>
              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={data}
                    page={page}
                    perpage={perpage}
                    setparpage={setPerPage}
                    setpage={setPage}
                    totalData={totalData}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {couponListData?.map((items, index) => {
                      return <CouponCard key={index} data={items} />;
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default CouponeList;
