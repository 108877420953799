import moment from 'moment';
import React from 'react'
import "react-data-table-component-extensions/dist/index.css";
import Table from "../../Component/Table/Table";


function PatientData({ data,isDoctorView = false}) {

    const columns = [
        {
            headerName: "Patient Name ",
            field: "name",
            cellRenderer: (d) =>
                <span>{`${d?.data?.patient?.firstName} ${d?.data?.patient?.lastName}`}</span>
        },

        {
            headerName: "Condition",
            field: "Condition",
            cellRenderer: (d) => {
                return d?.data?.condition.map((ele) => <><span>{ele}</span></>)
                // return <span> {params?.value?.map((ele, i) => <><span>{ele}</span>{params?.value?.length !== i + 1 && ", "}</>)}</span >
            }
        },
        {
            headerName: "Date",
            field: "createdAt",
            cellRenderer: (d) =>
                <span>{d?.data?.patient?.createdAt && moment(d?.data?.patient?.createdAt).format('LLL')}</span>

        },
        {
            headerName: "Consult Status",
            field: "consultStatus",
            cellRenderer: d => {
                return d?.value === 4 ? <span style={{ color: 'green' }}>{'Treatment Plan Sent'}</span> : d?.value === 1 ? <span style={{ color: 'green' }}>{'Paid Unassigned Doctor'}</span> : d?.value === 2 ? <span style={{ color: 'green' }}>{'Paid Unfulfilled'}</span> : d?.value === 3 ? <span style={{ color: 'green' }}>{' Live Consult'}</span> : d?.value === 0 ? <span style={{ color: 'red' }}>{'Not Paid'}</span> : d?.value === 5 ? <span style={{ color: '#E03131' }}>{'Awaiting Pathology'}</span> : '-'
            }

        },
    ];

    return (
        <div className="main">
            <Table
                columns={columns}
                data={data}
                ispagination={false}
                isDoctorView={isDoctorView}
            />
        </div>

    )
}

export default PatientData