import "./DoctorList.css";
import React, { useEffect, useState } from "react";
import user from "../../Assets/images/avatarBlue.png";
import { FaUserEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { Row, Col, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost, Bucket } from "../../helpers/API/API_data";
import { useRef } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { CSVLink } from "react-csv";
import DashHeader from "../../Component/DashHeader";
import { useContext } from "react";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import DeleteDoctor from "../../Component/modals/DeleteDoctor";
import DoctorCard from "../../Component/tableCard/DoctorCard";
import Pagination from "../../Component/Pagination";

const DoctorList = () => {
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setpage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [screenSize, setScreenSize] = useState(0);
  const [excel, setExcel] = useState([]);
  const [open, setopen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [viewData, setViewData] = useState({});
  const [id, setId] = useState("");

  const linkRef = useRef(null);
  const history = useHistory();

  const { loader, setLoader, itemPerPage, isTablet } = useContext(appContext);

  const searchdata = search.trim().split(/ +/).join(" ");

  const headers = [
    { label: "Doctor Name", key: "name" },
    { label: "Contact Details", key: "contactNumber" },
    { label: "Gender identity", key: "gender" },
    { label: "Condition Specialities", key: "condition" },
    { label: "Prescriber No", key: "registrationNo" },
    { label: "Available Day", key: "daytime" },
  ];

  const columns = [
    {
      headerName: "Doctor Name",
      field: "name",
      cellRenderer: (d) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/ViewDoctor/${d?.data?._id}`);
          }}
        >
          {d?.value}
        </span>
      ),
    },
    {
      headerName: "Contact Details",
      field: "contactNumber",
      cellRenderer: (d) => <span>{d?.value}</span>,
    },
    {
      headerName: "Gender identity",
      field: "gender",
      cellRenderer: (d) => <span>{d?.value === 0 ? "Male" : "Female"}</span>,
    },
    {
      headerName: "Condition Specialities",
      field: "conditionSpecialities",
      cellRenderer: (params) => {
        return (
          <span>
            {" "}
            {params?.value?.length > 0
              ? params?.value?.map((ele, i) => (
                  <>
                    <div>
                      {ele === "STI/STD" ? "STI Screening" : ele}
                      <span>{params?.value?.length !== i + 1 && ", "}</span>
                    </div>
                  </>
                ))
              : "-"}
          </span>
        );
      },
    },
    {
      headerName: "Prescriber No",
      field: "prescriberNumber",
      cellRenderer: (d) => <span>{d?.value ? d?.value : "-"}</span>,
    },
    {
      headerName: "Dr Response Time",
      field: "duration",
      cellRenderer: (d) => <span>{d?.value ? d?.value : "-"}</span>,
    },
    {
      headerName: "Available Day",
      field: "schedule",
      height: "500px",
      cellRenderer: (d) => {
        return [
          <span>
            <div>{d.value?.monday ? "Monday" : ""}</div>
            <div>{d.value?.tuesday ? "Tuesday" : ""}</div>
            <div>{d.value?.wednesday ? "Wednesday" : ""}</div>
            <div>{d.value?.thursday ? "Thursday" : ""}</div>
            <div>{d.value?.friday ? "Friday" : ""}</div>
            <div>{d.value?.saturday ? "Saturday" : ""}</div>
            <div>{d.value?.sunday ? "Sunday" : ""}</div>
          </span>,
        ];
      },
    },
    {
      headerName: "Action",
      cellRenderer: (d) => {
        return (
          <span>
            <FaUserEdit
              size={20}
              role="button"
              onClick={() => history.push(`/Update-Doctor/${d?.data?._id}`)}
            />
            &nbsp;&nbsp;{" "}
            <AiFillDelete
              size={20}
              role="button"
              onClick={() => {
                setopen(true);
                setId(d?.data?._id);
              }}
            />
            &nbsp;&nbsp;{" "}
            <AiOutlineEye
              size={20}
              role="button"
              onClick={() => {
                setViewOpen(true);
                setViewData(d?.data);
              }}
            />
          </span>
        );
      },
    },
  ];

  // modify doctor data
  const doctorListData = patients?.map((doctor) => {
    return {
      id: doctor?._id,
      data: doctor,
      name: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/ViewDoctor/${doctor?._id}`);
          }}
        >
          {doctor?.name}
        </span>
      ),
      contactNumber: doctor?.contactNumber,
      gender: doctor?.gender === 0 ? "Male" : "Female",
      conditionSpecialties:
        doctor?.conditionSpecialities?.length > 0
          ? doctor?.conditionSpecialities?.map((ele, i) => (
              <div>
                {ele === "STI/STD" ? "STI Screening" : ele}
                <span>
                  {doctor?.conditionSpecialities?.length !== i + 1 && ", "}
                </span>
              </div>
            ))
          : "-",
      prescriberNumber: doctor?.prescriberNumber
        ? doctor?.prescriberNumber
        : "-",
      duration: doctor?.duration ? doctor?.duration : "-",
      schedule: doctor?.schedule,
    };
  });

  // Pagination
  const handlePageChange = (pageNum) => setpage(pageNum);

  const handleNextClick = () => setpage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setpage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  // get doctor data
  const bodyData = {
    page: page,
    limit: perPage,
    search: searchdata,
  };

  const getData = async (body) => {
    setLoader(true);
    await ApiPost("admin/doctor/get", body)
      .then((res) => {
        setLoader(false);
        const tempData = res?.data?.data?.doctor_data.map((ele) => {
          return {
            ...ele,
            name: `${ele.firstName} ${ele.lastName}`,
            conditionSpecialities: ele?.additionalInfo?.conditionSpecialities,
            prescriberNumber: ele.additionalInfo.prescriberNumber,
            duration: ele?.averageDuration
              ? `${ele?.averageDuration?.split(".")[0]}`
              : "-",
          };
        });
        setPatients(tempData);
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getData(bodyData);
  }, [page, perPage]);

  // handle search doctor
  const handleSearch = (searchData) => {
    if (searchData.length === 0) {
      getData({ ...bodyData, search: "" });
    }
    setSearch(searchData);
  };
  // search doctor  on search button
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getData(body);
  };

  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata]);

  // hide side bar on resposive
  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  // export excell
  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
    };
    ApiPost("admin/doctor/get", body)
      .then((res) => {
        setExcel(
          res?.data?.data?.doctor_data &&
            res?.data?.data?.doctor_data.map((item) => {
              return {
                name: `${item?.firstName} ${item?.lastName}`,
                contactNumber: item?.contactNumber,
                gender:
                  item?.gender === 0
                    ? "Male"
                    : item?.gender === 1
                    ? "Female"
                    : "other",
                condition: item?.additionalInfo?.conditionSpecialities,
                registrationNo: item?.additionalInfo?.prescriberNumber
                  ? item?.additionalInfo?.prescriberNumber
                  : " - ",
                daytime: [
                  item?.schedule?.monday === true
                    ? `monday:${item?.schedule?.mondaymorningStartTime?.h}:${item?.schedule?.mondaymorningStartTime?.m} To ${item?.schedule?.mondaymorningEndTime?.h}:${item?.schedule?.mondaymorningEndTime?.m}`
                    : "-",
                  item?.schedule?.tuesday === true
                    ? `tuesday:${item?.schedule?.tuesdaymorningStartTime?.h}:${item?.schedule?.tuesdaymorningStartTime?.m} To ${item?.schedule?.tuesdaymorningEndTime?.h}:${item?.schedule?.tuesdaymorningEndTime?.m}`
                    : "-",
                  item?.schedule?.wednesday === true
                    ? `wednesday:${item?.schedule?.wednesdaymorningStartTime?.h}:${item?.schedule?.wednesdaymorningStartTime?.m} To ${item?.schedule?.wednesdaymorningEndTime?.h}:${item?.schedule?.wednesdaymorningEndTime?.m}`
                    : "-",
                  item?.schedule?.thursday === true
                    ? `thursday:${item?.schedule?.thursdaymorningStartTime?.h}:${item?.schedule?.thursdaymorningStartTime?.m} To ${item?.schedule?.thursdaymorningEndTime?.h}:${item?.schedule?.thursdaymorningEndTime?.m}`
                    : "-",
                  item?.schedule?.friday === true
                    ? `friday:${item?.schedule?.fridaymorningStartTime?.h}:${item?.schedule?.fridaymorningStartTime?.m} To ${item?.schedule?.fridaymorningEndTime?.h}:${item?.schedule?.fridaymorningEndTime?.m}`
                    : "-",
                  item?.schedule?.saturday === true
                    ? `saturday:${item?.schedule?.saturdaymorningStartTime?.h}:${item?.schedule?.saturdaymorningStartTime?.m} To ${item?.schedule?.saturdaymorningEndTime?.h}:${item?.schedule?.saturdaymorningEndTime?.m}`
                    : "-",
                  item?.schedule?.sunday === true
                    ? `sunday:${item?.schedule?.sundaymorningStartTime?.h}:${item?.schedule?.sundaymorningStartTime?.m} To ${item?.schedule?.sundaymorningEndTime?.h}:${item?.schedule?.sundaymorningEndTime?.m}`
                    : "-",
                ],
              };
            })
        );
      })
      .catch((e) => {});
  };

  return (
    <>
      <div className="chatscreen admindashboard doctor_list_main_div">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "auto",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Doctor List</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"DoctorList"}
                  headers={headers}
                />
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="Input search text"
                      className="  pe-3 search search1"
                      value={search}
                      name="search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn searchbtn1 d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                  <button
                    className="button button1"
                    type="button"
                    onClick={(e) => ExportExcel()}
                  >
                    Export ExcelSheet
                  </button>
                </div>
              </div>
              {isTablet ? (
                <Table
                  columns={columns}
                  data={patients}
                  page={page}
                  perpage={perPage}
                  setpage={setpage}
                  setparpage={setPerPage}
                  totalData={totalData}
                />
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {doctorListData?.map((item, index) => {
                      return (
                        <DoctorCard key={index} data={item} getData={getData} />
                      );
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
        {open && (
          // delete doctor
          <DeleteDoctor
            show={open}
            doctorId={id}
            onHide={() => setopen(false)}
            getDatas={(bodydata) => getData(bodydata)}
          />
        )}
        {/* view doctor */}
        <ViewDoctor
          viewOpen={viewOpen}
          setViewOpen={setViewOpen}
          viewData={viewData}
        />
      </div>
    </>
  );
};

export default DoctorList;

// view doctor modal
export const ViewDoctor = (props) => {
  return (
    <Modal
      show={props.viewOpen}
      onHide={() => props.setViewOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="assign-doctor-modals add-customer assign_doctor doctor_list_model_menu doctor_information_model"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="d-flex">
          Doctor Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: "300px",
          overflow: "auto",
        }}
      >
        <div className="text-center">
          <img
            alt=""
            src={props.viewData?.image ? Bucket + props.viewData?.image : user}
            style={{
              width: "150px",
              height: "150px",
            }}
            className="rounded-pill"
          />
        </div>
        <div
          className="mt-3"
          style={{
            color: "#003B61",
          }}
        >
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Name</div>
            <div className="col-md-8">
              {props.viewData?.firstName} {props.viewData?.lastName}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Email</div>
            <div className="col-md-8">{props.viewData?.email || "-"}</div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Date of Birth</div>
            <div className="col-md-8">
              {props.viewData?.additionalInfo?.dob || "-"}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Gender</div>
            <div className="col-md-8">
              {props.viewData?.gender === 0
                ? "Male"
                : props.viewData?.gender === 1
                ? "Female"
                : "Other"}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Gender Identify</div>
            <div className="col-md-8">
              {props.viewData?.identityGender || "-"}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Pronouns</div>
            <div className="col-md-8">
              {props.viewData?.additionalInfo?.pronouns || "-"}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Contact Number</div>
            <div className="col-md-8">
              {props.viewData?.contactNumber || "-"}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Condition Specialities</div>
            <div className="col-md-8">
              {props.viewData?.conditionSpecialities?.length > 0
                ? props.viewData?.conditionSpecialities?.map((val) => {
                    return <div>{val}</div>;
                  })
                : "-"}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Schedule</div>
            <div className="col-md-8">
              <div>
                {props.viewData?.schedule?.monday &&
                props.viewData?.schedule?.mondaymorningStartTime &&
                props.viewData?.schedule?.mondaymorningEndTime
                  ? `Mon: ${props.viewData?.schedule?.mondaymorningStartTime?.h}:${props.viewData?.schedule?.mondaymorningStartTime?.m} : ${props.viewData?.schedule?.mondaymorningEndTime?.h}:${props.viewData?.schedule?.mondaymorningEndTime?.m}`
                  : ""}
              </div>
              <div>
                {props.viewData?.schedule?.tuesday &&
                props.viewData?.schedule?.tuesdaymorningStartTime &&
                props.viewData?.schedule?.tuesdaymorningEndTime
                  ? `Tue: ${props.viewData?.schedule?.tuesdaymorningStartTime?.h}:${props.viewData?.schedule?.tuesdaymorningStartTime?.m} : ${props.viewData?.schedule?.tuesdaymorningEndTime?.h}:${props.viewData?.schedule?.tuesdaymorningEndTime?.m}`
                  : ""}
              </div>
              <div>
                {props.viewData?.schedule?.wednesday &&
                props.viewData?.schedule?.wednesdaymorningStartTime &&
                props.viewData?.schedule?.wednesdaymorningEndTime
                  ? `Wed: ${props.viewData?.schedule?.wednesdaymorningStartTime?.h}:${props.viewData?.schedule?.wednesdaymorningStartTime?.m} : ${props.viewData?.schedule?.wednesdaymorningEndTime?.h}:${props.viewData?.schedule?.wednesdaymorningEndTime?.m}`
                  : ""}
              </div>
              <div>
                {props.viewData?.schedule?.thursday &&
                props.viewData?.schedule?.thursdaymorningStartTime &&
                props.viewData?.schedule?.thursdaymorningEndTime
                  ? `Thur: ${props.viewData?.schedule?.thursdaymorningStartTime?.h}:${props.viewData?.schedule?.thursdaymorningStartTime?.m} : ${props.viewData?.schedule?.thursdaymorningEndTime?.h}:${props.viewData?.schedule?.thursdaymorningEndTime?.m}`
                  : ""}
              </div>
              <div>
                {props.viewData?.schedule?.friday &&
                props.viewData?.schedule?.fridaymorningStartTime &&
                props.viewData?.schedule?.fridaymorningEndTime
                  ? `Fri: ${props.viewData?.schedule?.fridaymorningStartTime?.h}:${props.viewData?.schedule?.fridaymorningStartTime?.m} : ${props.viewData?.schedule?.fridaymorningEndTime?.h}:${props.viewData?.schedule?.fridaymorningEndTime?.m}`
                  : ""}
              </div>
              <div>
                {props.viewData?.schedule?.saturday &&
                props.viewData?.schedule?.saturdaymorningStartTime &&
                props.viewData?.schedule?.saturdaymorningEndTime
                  ? `Sat: ${props.viewData?.schedule?.saturdaymorningStartTime?.h}:${props.viewData?.schedule?.saturdaymorningStartTime?.m} : ${props.viewData?.schedule?.saturdaymorningEndTime?.h}:${props.viewData?.schedule?.saturdaymorningEndTime?.m}`
                  : ""}
              </div>
              <div>
                {props.viewData?.schedule?.sunday &&
                props.viewData?.schedule?.sundaymorningStartTime &&
                props.viewData?.schedule?.sundaymorningEndTime
                  ? `Sun: ${props.viewData?.schedule?.sundaymorningStartTime?.h}:${props.viewData?.schedule?.sundaymorningStartTime?.m} : ${props.viewData?.schedule?.sundaymorningEndTime?.h}:${props.viewData?.schedule?.sundaymorningEndTime?.m}`
                  : ""}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Frenchie Provider Number</div>
            <div className="col-md-8">
              {props.viewData?.additionalInfo?.frenchieProviderNumber || "-"}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Prescriber Number</div>
            <div className="col-md-8">
              {props.viewData?.additionalInfo?.prescriberNumber || "-"}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 fw-bold ">Registration No</div>
            <div className="col-md-8">
              {props.viewData?.additionalInfo?.registrationNo || "-"}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
