import { Checkbox } from "antd";
import React from "react";

// order card
const OrderCard = ({ data, selectedRows = [], setSelectedRows = () => { } }) => {

  const handleSelectBox = (checked, id) => {
    if (checked) setSelectedRows([...selectedRows, id]);
    else setSelectedRows(selectedRows.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head flex">
          <div
            style={{
              marginRight: "8px",
            }}
          >
            <Checkbox
              checked={selectedRows.includes(data?.subscriptionId)}
              onChange={(e) => handleSelectBox(e.target.checked, data?.subscriptionId)}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <h6 className="consulation_list_li__head">{data?.patientName}</h6>
            <h5 className="consulation_list_li__head_number">
              {data?.orderNumber}
            </h5>
          </div>
        </div>
        <div className="consulation_list_li_data_inner">
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">Email</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.email}
            </h6>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Contact No.
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.contactNumber}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Address</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.address}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Item Number
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.itemNum}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Quantity</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.quantity}
              </h6>
            </div>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Treatment Name
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.treatmentName}
            </h6>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Payment Status
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.paymentStatus}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Order Status
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.orderStatus}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Shipping Tracking Number
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.shippingTrackNo}
              </h6>
            </div>
          </div>
          <div className="consulation_list_data_left_inner mb-0">
            <h6 className="consulation_list_li_data_inner_head">Action</h6>
            <div className="d-flex">
              <button
                className="p-0"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                {data?.actionView}
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default OrderCard;
