import CryptoJS from "crypto-js";

// encrpt all api payload
export function encryptdata(ele) {
    try {
        let patientData = JSON.stringify(ele)
        var ciphertext = CryptoJS.AES.encrypt(patientData, '123456789').toString();
        return ciphertext;
    } catch (error) {
    }

}

// decrpt all api response
export function decryptData(ciphertext,type,responseJson) {
    try {
        var bytes = CryptoJS.AES.decrypt(ciphertext, '123456789');
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    } catch (error) {
        return null;
    }
}
