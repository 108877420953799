import "./Pathology.css";
import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost } from "../../helpers/API/API_data";
import DashHeader from "../../Component/DashHeader";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useContext } from "react";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import Pagination from "../../Component/Pagination";
import PathologyCard from "../../Component/tableCard/PathologyCard";
import { useHistory } from "react-router-dom";

const Pathology = () => {
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setpage] = useState(1);
  const [TreatmentStatus, setTreatmentStatus] = useState(null);
  const [perpage, setperpage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [excel, setExcel] = useState([]);

  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);
  const history = useHistory();
  const linkRef = useRef(null);

  const columns = [
    {
      headerName: "Item Number",
      field: "itemnumber",
    },
    {
      headerName: "Patient Name",
      field: "patientname",
      cellRenderer: (d) => (

        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push({
              pathname: `/viewPatient/${d?.data.patientId}`,
              state: { questionType: d?.data?.condition },
            })
          }
        >
          {d?.data?.patientname}
        </span>
      ),
    },
    {
      headerName: "Referring Doctor",
      field: "referringdoctor",
    },
    {
      headerName: "Kit Name",
      field: "kitname",
    },
    {
      headerName: "Panel Description",
      field: "kitDescription",
    },
    {
      headerName: "Report Type",
      field: "reporttype",
      cellRenderer: (value) => (
        <>
          <div>
            {value?.data?.resultStatus?.length ? (
              <div>
                {value?.data?.resultStatus?.map((v, i) => {
                  return (
                    <>
                    {
                     v?.pathologyName
                    }
                      <span>
                        {value?.data?.resultStatus !== i + 1 && <br />}
                      </span>
                    </>
                  );
                })}
              </div>
            ) : (
              <div>{value?.value}</div>
            )}
          </div>
        </>
      ),
    },
    {
      headerName: "Result Status",
      field: "resultStatus",
      cellRenderer: (value) => (
        <div>
          {value?.data?.dateRecevied.length > 0 ? (
            <div>
              <div>Received</div>
            </div>
          ) : (
            <div>Pending</div>
          )}
        </div>
      ),
    },
    {
      headerName: "Date Ordered",
      field: "date",
    },
    {
      headerName: "Date Recevied",
      field: "dateRecevied",
      cellRenderer: (value) => (
        <div>
          <div>
            {value?.data?.dateRecevied.length > 0 ? (
              <div>
                {value?.data?.dateRecevied?.map((v, i) => {
                  return (
                    <>
                      {moment(v?.dateReceived).format("LLL")}
                      <span>
                        {value?.data?.dateRecevied !== i + 1 && <br />}
                      </span>
                    </>
                  );
                })}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        </div>
      ),
    },
   
  ];

  // modify pathology data
  const pathologyListData = patients?.map((pathology) => {

    return {
      id: pathology?.id,
      itemNumber: pathology?.itemnumber,
      patientName: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push({
              pathname: `/viewPatient/${pathology?.patientId}`,
              state: { questionType: pathology?.condition },
            })
          }
        >
          {pathology?.patientname}
        </span>
      ),
      referringDoctor: pathology?.referringdoctor,
      kitName: pathology?.kitname,
      kitDescription: pathology?.kitDescription,
      reportType: (
        <div>
          {pathology?.resultStatus?.length ? (
            <div>
              {pathology?.resultStatus?.map((v, i) => {
                return (
                  <>
                  {
                   v?.pathologyName
                  }
                    <span>
                      {v?.resultStatus !== i + 1 && <br />}
                    </span>
                  </>
                );
              })}
            </div>
          ) : (
            <div>{pathology?.reporttype}</div>
          )}
        </div>
      ),
      date: pathology?.date,
      dateRecevied: (
        <>
          {pathology?.dateRecevied?.length > 0 ? (
            <div>
              <div>
                {moment(pathology?.dateRecevied[0]?.dateReceived).format("LLL")}
              </div>
              <div>
                {moment(pathology?.dateRecevied[1]?.dateReceived).format("LLL")}
              </div>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
      resultStatus:
        pathology?.resultStatus?.length > 0
          ? "Received"
          : "Pending",
    };
  });

  // Pagination
  const handlePageChange = (pageNumber) => setpage(pageNumber);

  const handleNextClick = () => setpage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setpage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  const headers = [
    { label: "Item Number", key: "itemnumber" },
    { label: "Patient Name", key: "patientname" },
    { label: "Referring Doctor", key: "referringdoctor" },
    { label: "Kit Name", key: "kitname" },
    { label: "Panel Description", key: "kitDescription" },
    { label: "Report Type", key: "reporttype" },
    { label: "Result Status", key: "resultStatus" },
    { label: "Date Ordered", key: "date" },
    { label: "Date Recevied", key: "dateRecevied" },
  ];

  // get pathology data
  const getData = async (body) => {
    setLoader(true);
    await ApiPost("admin/pathology/get", body)
      .then((res) => {
        setLoader(false);
        setPatients(
          res?.data?.data?.pathology_data &&
          res?.data?.data?.pathology_data.map((val, i) => {
            return {
              id: (page - 1) * perpage + (i + 1),
              itemnumber: val?.pathology?.itemNumber,
              patientname: val?.patient?.firstName
                ? `${val?.patient?.firstName} ${val?.patient?.lastName}`
                : "-",
              referringdoctor: val?.doctor?.firstName
                ? `${val?.doctor?.firstName} ${val?.doctor?.lastName}`
                : "-",
              kitname: val?.pathology?.kitName,
              reporttype: val?.pathology?.reportType,
              kitDescription:val?.pathology?.panelDescriptor,
              date: val?.createdAt
                ? moment(val?.createdAt).format("LLL")
                : "-",
              dateRecevied: val?.SonicResultData,
              resultStatus: val?.SonicResultData,
              reportresult: "",
              requestnumber: "",
              patientId: val?.patient?._id,
              condition: val?.condition === "STI/STD" ? "STI Screening" : val?.condition,
              
            };
          })
        );
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const searchdata = search.trim().split(/ +/).join(" ");
  const bodyData = {
    page: page,
    limit: perpage,
    search: searchdata,
    status: parseInt(TreatmentStatus),
  };
  useEffect(() => {
    getData(bodyData);
  }, [page, perpage, search, TreatmentStatus]);


// handle search
  const handleSearch = (searchData) => {
    if (searchData.length === 0) {
      getData({ ...bodyData, search: "" });
    }
    setSearch(searchData);
  };

  // // handle search on submit
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getData(body);
  };

  // handle export excel
  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
    };
    ApiPost("admin/pathology/get", body)
      .then((res) => {

        setExcel(
          res?.data?.data?.pathology_data &&
          res?.data?.data?.pathology_data.map((val) => {

            return {
              itemnumber: val?.pathology?.itemNumber,
              patientname: `${val?.patient?.firstName} ${val?.patient?.lastName}`,
              referringdoctor: val?.doctor?.firstName
                ? `${val?.doctor?.firstName} ${val?.doctor?.lastName}`
                : "-",
              kitname: val?.pathology?.kitName,
              kitDescription:val?.pathology?.panelDescriptor,
              reporttype: val?.SonicResultData.length ? val?.SonicResultData?.map((item)=>item.pathologyName) : val?.pathology?.reportType,
              date:
                val?.createdAt && moment(val?.createdAt).format("DD/MM/YYYY"),
              dateRecevied:val?.SonicResultData.length ? val?.SonicResultData?.map((item)=> moment(item.dateReceived).format("LLL")) :"-"
              ,
              resultStatus: val?.SonicResultData.length > 0 ? "Received" : "Pending",
            };
          })
        );
      })
      .catch((e) => { });
  };

  // hide side bar on responsiveness
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      <div className="chatscreen admindashboard pathology_main_div">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4 className="mt-2">PATHOLOGY</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"pathology"}
                  headers={headers}
                ></CSVLink>
                <div className="searchbox flex-wrap">
                  <div className="d-flex mt-2">
                    <input
                      placeholder="Input search text"
                      className="  pe-3 search"
                      value={search}
                      name="search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                  <select
                    className="searchfilter mt-2"
                    name="TreatmentStatus"
                    onChange={(e) => setTreatmentStatus(e.target.value)}
                  >
                    <option selected disabled hidden>
                      All
                    </option>
                    <option value="null">All</option>
                    <option value={1}>Received</option>
                    <option value={2}>Waiting</option>
                  </select>
                  <button className="button mt-2" onClick={() => ExportExcel()}>
                    Export to Excel
                  </button>
                </div>
              </div>

              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={patients}
                    page={page}
                    perpage={perpage}
                    setparpage={setperpage}
                    setpage={setpage}
                    totalData={totalData}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {pathologyListData?.map((items, index) => {
                      return <PathologyCard key={index} data={items} />;
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}

              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default Pathology;
