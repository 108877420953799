import { Checkbox } from "antd";
import React from "react";

// contact us card
const ContactUsAndOutsideUsersCard = ({ data }) => {
  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head flex">
          <h6 className="consulation_list_li__head">{data?.id}</h6>
          <div
            style={{
              margin: "0 8px",
            }}
          >
            <Checkbox
              checked={isCheck.includes(data?.id)}
              onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
            />
          </div>
          <h6 className="consulation_list_li__head">{data?.name}</h6>
        </div>
        <div className="consulation_list_li_data_inner">
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Email Address
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.emailId}
            </h6>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Contact Number
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.contact}
            </h6>
          </div>
          {data?.message && (
            <div
              style={{ width: "100%" }}
              className="consulation_list_data_left_inner mb-0"
            >
              <h6 className="consulation_list_li_data_inner_head">Message</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.message}
              </h6>
            </div>
          )}
        </div>
      </li>
    </>
  );
};

export default ContactUsAndOutsideUsersCard;
