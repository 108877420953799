import "../App.css";
import { Switch, Route, useHistory } from "react-router-dom";
import LoginDoctor from "../Component/Auth/LoginDoctor/LoginDoctor";
import DoctorDashboard from "../Page/DoctorDashboard/DoctorDashboard";
import ChatScreen from "../Page/ChatScreen/ChatScreen";
import MasterPatient from "../Page/MasterPatient/MasterPatient.js";
import DoctorList from "../Page/DoctorList/DoctorList";
import ConsultationList from "../Page/ConsultationList/ConsultationList";
import Pathology from "../Page/Pathology/Pathology";
import CouponeList from "../Page/CouponeList/CouponeList";
import AddCoupons from "../Page/AddCoupons/AddCoupons.js";
import UpdateCoupons from "../Page/AddCoupons/UpdateCoupons.js";
import UpdateDoctor from "../Page/DoctorList/UpdateDoctor";
import ViewPatient from "../Page/ViewPatient/ViewPatient";
import ViewDoctor from "../Page/ViewDoctor/ViewDoctor";
import AdminDashboard from "../Page/AdminDashboard/AdminDashboard";
import Orders from "../Page/Orders/Orders";
import PatientSummryCard from "../Page/SummaryCard/PatientSummryCard/PatientSummryCard";
import AddTreatment from "../Page/AddTreatment/AddTreatment";
import TreatmentList from "../Page/TreatmentList/TreatmentList";
import Subscription from "../Page/Subscription/Subscription";
import AddSubscription from "../Page/AddSubscription/AddSubscription";
import PaymentSummary from "../Page/PaymentSummary/PaymentSummary";
import ContactUs from "../Page/ContactUs/ContactUs";
import QuestionSummary from "../Page/QuestionSummary/QuestionSummary";
import { useContext, useEffect } from "react";
import Auth from "../helpers/Auth";
import { Nomatch } from "../Page/Nomatch";
import { appContext } from "../helpers/AppContext";
import { useState } from "react";
import ChatScreenMobile from "../Page/ChatScreen/ChatScreenMobile";
import UpdateTreatment from "../Page/AddTreatment/UpdateTreatment";
import ViewConsultation from "../Page/ViewPatient/ViewConsultation";
import ChatScreenMobileList from "../Page/ChatScreen/ChatScreenListMobile";
import CustomerSupport from "../Page/customerSupport/CustomerSupport";
import CompleteOrder from "../Page/completeOrder/CompleteOrder";
import SubAdmin from "../Page/subadmin/SubAdmin";

function RouteLink() {
  const [rooms, setRooms] = useState();
  const [msg, setMsg] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth > 991);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth > 991);
  };

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []); 

  const userData = Auth.getUserData();
  const { socket, setUnreadMsg } = useContext(appContext);
  useEffect(() => {
    if (userData?._id) {
      socket?.emit("online", { userId: userData?._id });
      socket?.on("receive_message", (data) => {
        setMsg(data);
        socket?.emit("message_deliver_status", {
          messageId: data._id,
          status: 1,
          roomId: data?.roomId,
        });
        askNotificationPermission();
        if (
          Notification.permission === "granted" &&
          window?.location?.pathname !== "/ChatScreen" &&
          window?.location?.pathname.split("/")[1] !== "ViewDoctor"
        ) {
          createNotification(data);
        }
      });
      return () => {
        socket?.off("get_rooms");
        rooms?.map((res) => {
          socket?.emit("leave_room", {
            roomId: res?._id,
            userId: res?.user?._id,
          });
        });
      };
    }
  }, [window?.location?.pathname, msg]);
  useEffect(() => {
    if (userData?._id) {
      if (window?.location?.pathname !== "/ChatScreen") {
        socket?.on("get_rooms", async (rooms) => {
          rooms?.rooms &&
            rooms?.rooms.map((res) => {
              socket?.emit("join_room", {
                roomId: res?._id,
                userId: res?.user?._id,
              });
            });
          setRooms(rooms?.rooms);
        });
      }
    }
  }, [msg, window?.location?.pathname]);
  const createNotification = (data) => {
    const text = data?.message;
    const notification = new Notification(
      data?.firstName + " " + data?.lastName,
      { body: text, icon: "" }
    );
    notification.onclick = () => {
      notification.close();
      window.parent.focus();
    };
  };
  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
    return true;
  };
  const askNotificationPermission = () => {
    const handlePermission = (permission) => {
      if (!Reflect.has(Notification, "permission")) {
        Notification.permission = permission;
      }
    };
    if (!Reflect.has(window, "Notification")) {
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(handlePermission);
      } else {
        Notification.requestPermission(handlePermission);
      }
    }
  };
  return (
    <>
      <Switch>
        <PublicRoute exact path="/">
          {" "}
          <LoginDoctor />{" "}
        </PublicRoute>
        <PrivateRoute exact path="/Update-Doctor/:id">
          {" "}
          <UpdateDoctor />
        </PrivateRoute>
        <PrivateRoute exact path="/DoctorDashboard">
          {" "}
          <DoctorDashboard />
        </PrivateRoute>

        <PrivateRoute exact path="/ChatScreen">
          {" "}
          {isMobile ? <ChatScreen /> : <ChatScreenMobileList />}
        </PrivateRoute>
        <PrivateRoute exact path="/ChatScreenMobile/:id/:id">
          <ChatScreenMobile />
        </PrivateRoute>
        <PrivateRoute exact path="/Treatments">
          {" "}
          <TreatmentList />
        </PrivateRoute>
        <PrivateRoute exact path="/MasterPatient">
          {" "}
          <MasterPatient />
        </PrivateRoute>
        <PrivateRoute exact path="/DoctorList">
          {" "}
          <DoctorList />
        </PrivateRoute>
        <PrivateRoute exact path="/Pathology">
          {" "}
          <Pathology />
        </PrivateRoute>
        <PrivateRoute exact path="/AddTreatment">
          {" "}
          <AddTreatment />
        </PrivateRoute>
        <PrivateRoute exact path="/ConsultationList">
          {" "}
          <ConsultationList />
        </PrivateRoute>
        <PrivateRoute exact path="/CouponeList">
          {" "}
          <CouponeList />
        </PrivateRoute>
        <PrivateRoute exact path="/AddCoupons">
          {" "}
          <AddCoupons />
        </PrivateRoute>
        <PrivateRoute exact path="/ViewPatient/:id">
          {" "}
          <ViewPatient isMobile={isMobile}/>
        </PrivateRoute>
        <PrivateRoute  path="/ViewDoctor/:id">
          {" "}
          <ViewDoctor />
        </PrivateRoute>
        <PrivateRoute exact path="/ViewConsultation/:id">
          {" "}
          <ViewConsultation isMobile={isMobile} />
        </PrivateRoute>
        <PrivateRoute exact path="/AdminDashboard">
          {" "}
          <AdminDashboard />
        </PrivateRoute>
        <PrivateRoute exact path="/Orders">
          {" "}
          <Orders isMobile={isMobile} />
        </PrivateRoute>
        <PrivateRoute exact path="/complete_orders">
          {" "}
          <CompleteOrder />
        </PrivateRoute>
        <PrivateRoute exact path="/PatientSummryCard">
          {" "}
          <PatientSummryCard />
        </PrivateRoute>
        <PrivateRoute exact path="/SubscriptionModel">
          {" "}
          <Subscription />
        </PrivateRoute>
        <PrivateRoute exact path="/AddSubscription">
          {" "}
          <AddSubscription />
        </PrivateRoute>
        <PrivateRoute exact path="/PaymentSummary">
          {" "}
          <PaymentSummary />
        </PrivateRoute>
        <PrivateRoute exact path="/ContactUs">
          {" "}
          <ContactUs />
        </PrivateRoute>
        <PrivateRoute exact path="/customer-support">
          {" "}
          <CustomerSupport />
        </PrivateRoute>
        <PrivateRoute exact path="/UpdateTreatment/:id">  
        <UpdateTreatment />
        </ PrivateRoute >
        <PrivateRoute exact path="/QuestionSummary/:id">
          {" "}
          <QuestionSummary />
        </PrivateRoute>
        <PrivateRoute exact path="/UpdateCoupons/:id">
          {" "}
          <UpdateCoupons />
        </PrivateRoute>
        <PrivateRoute exact path="/sub_admins">
          {" "}
          <SubAdmin />
        </PrivateRoute>
        <Route exact path="*">
          <Nomatch />
        </Route>
      </Switch>
    </>
  );
}
export default RouteLink;
const PublicRoute = ({ children, ...res }) => {
  const auth = !Auth.isUserAuthenticated();
  const history = useHistory();
  return (
    <Route
      {...res}
      render={() => {
        if (auth) {
          return children;
        } else history.push({ pathname: "/AdminDashboard" });
      }}
    />
  );
};
const PrivateRoute = ({ children, ...res }) => {
  const auth = Auth.isUserAuthenticated();
  const history = useHistory();
  return (
    <Route
      {...res}
      render={() => {
        if (auth) {
          return children;
        } else history.push({ pathname: "/" });
      }}
    />
  );
};
