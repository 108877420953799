/**
 * The PathologyCard component is a React functional component that renders a card with various data
 * fields for a pathology patient.
 * @returns The PathologyCard component is returning a JSX element.
 */
import { Checkbox } from "antd";
import React from "react";

const PathologyCard = ({ data }) => {
  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div className="consulation_list_li_data_head flex">
          <div
            style={{
              marginRight: "8px",
            }}
          >
            <Checkbox
              checked={isCheck.includes(data?.id)}
              onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <h6 className="consulation_list_li__head">{data?.patientName}</h6>
            <h5 className="consulation_list_li__head_number">
              {data?.itemNumber}
            </h5>
          </div>
        </div>
        <div className="consulation_list_li_data_inner">
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Referring Doctor
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.referringDoctor}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Kit Name</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.kitName}
              </h6>
            </div>
          </div>
          <div
            style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">Report Type</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.reportType}
            </h6>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Date</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.date}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Results</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.resultStatus}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
          <div
            // style={{ width: "100%" }}
            className="consulation_list_data_left_inner"
          >
            <h6 className="consulation_list_li_data_inner_head">
              Request Number
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.dateRecevied}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">Panel Description</h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.kitDescription}
              </h6>
            </div>
          </div>
       
        </div>
      </li>
    </>
  );
};

export default PathologyCard;
