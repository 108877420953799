import "./ContactUs.css";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import DashHeader from "../../Component/DashHeader";
import { ApiPost } from "../../helpers/API/API_data";
import { useContext } from "react";
import Loader from "../../Component/loader/Loader.js";
import { appContext } from "../../helpers/AppContext";
import Table from "../../Component/Table/Table";
import Pagination from "../../Component/Pagination";
import ContactUsAndOutsideUsersCard from "../../Component/tableCard/ContactUsAndOutsideUsersCard";

const ContactUs = () => {
  const [Contactdata, setContactdata] = useState();
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");

  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);

  const columns = [
    {
      headerName: "item number",
      field: "id",
    },
    {
      headerName: "First Name",
      field: "name",
    },
    {
      headerName: "Last Name",
      field: "lastname",
    },
    {
      headerName: "Email Address",
      field: "emailid",
    },
    {
      headerName: "Contact number",
      field: "contact",
    },
    {
      headerName: "Message",
      field: "message",
    },
  ];

  const contactUsData = Contactdata?.map((contacts) => {
    return {
      id: contacts?.id,
      name: `${contacts?.name} ${contacts?.lastname}`,
      emailId: contacts?.emailid,
      contact: contacts?.contact,
      message: contacts?.message,
    };
  });

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  // get contact us data
  const getdata = (body) => {
    setLoader(true);
    ApiPost(`admin/contact`, body)
      .then((res) => {
        setLoader(false);
        setContactdata(
          res?.data?.data?.contact_data &&
            res?.data?.data?.contact_data.map((val, i) => {
              return {
                id: (page - 1) * perpage + (i + 1),
                name: val?.firstName,
                lastname: val?.lastName,
                emailid: val?.email,
                contact: val?.contactNumber,
                message: val?.message,
              };
            })
        );
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const body = {
    page: page,
    limit: perpage,
    search: search,
  };

    // get contact us data with paginations
  useEffect(() => {
    getdata(body);
  }, [page, perpage]);

  // handle search
  const handleSearch = (e) => {
    if (!e.target.value) {
      getdata({ ...body, search: "" });
    }
    setSearch(e.target.value);
  };

    // handle search on search botton
  const handleSubmit = () => {
    getdata(body);
  };

  useEffect(() => {
    handleSubmit();
  }, [search]);

  //hide sidebar on responsivness
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Contact Us </h4>
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="Input search text"
                      className="  pe-3 search"
                      value={search}
                      onChange={(e) => handleSearch(e)}
                    />
                    <button
                      className="searchbtn d-flex align-items-center"
                      onClick={() => handleSubmit()}
                    >
                      {" "}
                      Search{" "}
                    </button>
                  </div>
                </div>
              </div>
              {isTablet ? (
                <div className="admindatatble">
                  <Table
                    columns={columns}
                    data={Contactdata}
                    setpage={setPage}
                    setperPage={setPerPage}
                    totalData={totalData}
                    page={page}
                    perpage={perpage}
                  />
                </div>
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {contactUsData?.map((items, index) => {
                      return (
                        <ContactUsAndOutsideUsersCard
                          key={index}
                          data={items}
                        />
                      );
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default ContactUs;
