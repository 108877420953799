import React, { useContext, useEffect, useState, useRef } from "react";
import receiverprofile from "../../Assets/images/chat/avatarBlue.png";
import DashHeader from "../../Component/DashHeader";
import { Link, useLocation } from "react-router-dom";
import { appContext } from "../../helpers/AppContext";
import Auth from "../../helpers/Auth";
import { ReactComponent as SingleTick } from "../../Assets/images/chat/singleTick.svg";
import { ReactComponent as DoubleTicks } from "../../Assets/images/chat/doubleTicks.svg";
import { ReactComponent as BlueTicks } from "../../Assets/images/chat/blueTicks.svg";
import TextareaAutosize from "react-textarea-autosize";
import moment from "moment";
import ChatLoader from "../../Component/ChatLoader/ChatLoader";
import "./ChatScreenMobile.css";
import { RiArrowLeftSLine } from "react-icons/ri";
import { ApiPost, Bucket } from "../../helpers/API/API_data";

const ChatScreenMobile = () => {
  const { socket, loader, connectSocket, roomContexData, setRoomContexData } =
    useContext(appContext);
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState();
  const [userMessage, setUserMessage] = useState([]);
  const [chatData, setchatData] = useState([]);
  const [userRoomId, setUserRoomId] = useState();
  const [name, setName] = useState();
  const focusref = useRef();
  const messageEl = useRef(null);
  const userData = Auth.getUserData();
  const location = useLocation();
  let messageId = window.location?.pathname.split("/")[2];

  const id = window.location?.pathname.split("/")[3];
  const receiverId = id;
  // handle sent message
  const handleSendMessage = (e, message) => {
    // debugger
    e.preventDefault();
    if (message.trim()) {
      socket.emit("send_message", {
        senderId: userData?._id,
        roomId: userRoomId ? userRoomId?._id : roomId,
        receiverId: receiverId,
        message: message.trim(),
      });

      setMessage("");
    }
  };

  // get admin room data and set room ids
  const getRoomData = async () => {
    let body = {
      page: 1,
      limit: 10000,
    };
    await ApiPost("admin/room/get", body)
      .then((res) => {
        setRoomContexData(res?.data?.data?.room_data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getRoomData();
    setRoomId(messageId);
  }, [messageId]);

  useEffect(() => {
    connectSocket();
  }, []);

  const getRooms = (data) => {
    socket.emit("get_user_id", data);
  };

  // select and open rooms chat when navigate from initaite chat and chat with doctor or patient
  useEffect(() => {
    // debugger
    if (location?.state?.id) {
      const userRoomId = roomContexData?.find(
        (ele) => ele?.user?._id === location?.state?.id
      );
      setRoomId(userRoomId?._id);
      setUserRoomId(userRoomId);
    }
  }, [roomContexData, location?.state?.id]);

  // handle sent msg on hit enter
  const handleKeySendMsg = (e) => {
    e.preventDefault();
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage(e, message);
    }
  };

  // set sender and reciver msg
  const sendermsg = [];
  const receivermsg = [];
  for (let i = 0; i < userMessage.length; i++) {
    sendermsg.push(
      userMessage[i - 1]?.senderId !== userData?._id &&
        userMessage[i]?.senderId === userData?._id &&
        userMessage[i]
    );
    receivermsg.push(
      userMessage[i - 1]?.senderId === userData?._id &&
        userMessage[i]?.senderId !== userData?._id &&
        userMessage[i]
    );
  }
  // get chat message
  const callmessage = async () => {
    socket?.emit("join_room", { roomId: roomId, userId: userData?._id });
    let body = {
      page: 1,
      limit: 20,
      roomId: roomId,
    };
    await ApiPost("admin/room/message/get", body)
      .then((res) => {
        setUserMessage(res?.data?.data?.message_data.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    socket?.emit("online", { userId: userData?._id });
  }, [socket]);

  // get room chat data when rooms change
  useEffect(() => {
    if (roomId && roomContexData?.length > 0) {
      const name = roomContexData?.find((ele) => ele?.user?._id === id);
      setName(name);
      callmessage();
    }
  }, [roomContexData, roomId]);

  // handle msg seen and unseen status
  if (roomId && roomContexData?.length) {
    socket.off("receive_message").on("receive_message", (data) => {
      socket.emit("message_deliver_status", {
        messageId: data._id,
        status:
          data?.roomId === (userRoomId?._id ? userRoomId?._id : roomId) &&
          data?.receiverId === userData?._id &&
          window.location?.pathname !== "/ChatScreen"
            ? 2
            : 1,
        roomId: data?.roomId,
      });
      getRooms({ userId: userData._id, search: "" });
      if (data?.roomId === (userRoomId ? userRoomId?._id : roomId)) {
        setUserMessage((prev) => {
          return JSON.parse(JSON.stringify([...prev, data]));
        });
      }
    });
  }

  // scroll to botton
  const scrollToBottomWithSmoothScroll = () => {
    messageEl.current?.addEventListener("DOMNodeInserted", (event) => {
      const { currentTarget: target } = event;
      target.scroll({ top: target.scrollHeight, behavior: "auto" });
    });
  };

  // modify user message data
  useEffect(() => {
    const temp = [];
    if (userMessage.length) {
      userMessage.forEach((ele, i) => {
        if (
          userMessage[i - 1]?.createdAt &&
          moment(userMessage[i - 1]?.createdAt).format("DD/MM/YYY") ===
            moment(ele?.createdAt).format("DD/MM/YYY")
        ) {
          temp[temp.length - 1].item.push(ele);
        } else {
          temp.push({
            date: ele.createdAt,
            item: [ele],
          });
        }
        setchatData(temp);
      });
    } else {
      setchatData("");
    }
    scrollToBottomWithSmoothScroll();
  }, [userMessage]);
  return (
    <>
      <div className="chatscreen admindashboard chat_with_admin chat_screen_mobile">
        <DashHeader />
        <div className="inner_div_dashboard" style={{ paddingTop: "60px" }}>
          <ul className="chatprofileheader">
            <li>
              <Link
                to={"/ChatScreen"}
                className="chat_screen_arrow_mobile_link"
              >
                <RiArrowLeftSLine />
              </Link>
              <img
                src={
                  name?.user?.image
                    ? name?.user?.image
                    : "/images/avatarBlue.png"
                }
                alt=""
                className="userAvatar"
              />
              <div className="chatmeninfo">
                <h4>
                  {name?.user?.firstName
                    ? `${name?.user?.firstName} ${name?.user?.lastName}`
                    : "-"}
                </h4>
                <p>{name && name?.user?.email}</p>
              </div>
            </li>
            <li>
              <Link>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.7924 4.81154C19.6637 4.73524 19.5044 4.73226 19.373 4.80393L14.7842 7.30955V5.80614C14.7828 4.63659 13.8351 3.68882 12.6655 3.6875H2.11864C0.949086 3.68882 0.00132415 4.63659 0 5.80614V14.1715C0.00132415 15.341 0.949086 16.2888 2.11864 16.2901H12.6655C13.8351 16.2888 14.7828 15.341 14.7842 14.1715V12.6954L19.3732 15.201C19.5044 15.2727 19.6638 15.2699 19.7924 15.1936C19.9212 15.1171 20 14.9785 20 14.8291V5.17585C20 5.02622 19.921 4.88784 19.7924 4.81154ZM13.9364 14.1716C13.9357 14.8734 13.367 15.442 12.6652 15.4428H2.11864C1.41684 15.442 0.848285 14.8734 0.847458 14.1716V5.80614C0.848285 5.10451 1.41684 4.53579 2.11864 4.53496H12.6655C13.3672 4.53579 13.9359 5.10451 13.9367 5.80614L13.9364 14.1716ZM19.1525 14.115L14.7842 11.7299V8.27503L19.1525 5.89006V14.115Z"
                    fill="#003B61"
                  />
                </svg>
              </Link>
            </li>
          </ul>

          {loader ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ChatLoader />
            </div>
          ) : (
            <div className="msgsend mainchat">
              {!chatData.length && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <div className="modal_msg">
                    <img
                      alt=""
                      style={{ height: "50px" }}
                      src={"/images/Say_hi.gif"}
                    />
                    <p>
                      Say hi{" "}
                      {name?.user?.firstName
                        ? name?.user?.firstName + " " + name?.user?.lastName
                        : "-"}{" "}
                      with a wave
                    </p>
                    <button
                      className="button"
                      onClick={(e) => {
                        handleSendMessage(e, "Hi");
                      }}
                    >
                      Say hi
                    </button>
                  </div>
                </div>
              )}
              {/* chat message */}
              <ul
                className="chats"
                ref={messageEl}
                id="chat-feed"
                style={{ marginBottom: "50px" }}
              >
                {chatData.length
                  ? chatData.map((item, i) => (
                      <>
                        <li key={Math.random()} className="display_date">
                          {moment(new Date()).format("DD-MM-YYYY") ===
                          moment(new Date(item?.date)).format("DD-MM-YYYY")
                            ? "Today"
                            : moment(new Date(item?.date)).format("DD-MM-YYYY")}
                        </li>

                        {item.item.map((res, i) => (
                          <>
                            {res.senderId === userData._id && (
                              <li
                                className="send_massage"
                                style={{ marginTop: "3px" }}
                                key={`sender${i}`}
                              >
                                <div
                                  key={Math.random()}
                                  className="massage_sent"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: "10px",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {res.message}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "end",
                                        gap: "5px",
                                        margin: "5px",
                                        minWidth: "45px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          padding: "0px",
                                          color: "darkgrey",
                                        }}
                                      >{`${new Date(
                                        res?.createdAt
                                      ).getHours()}:${new Date(
                                        res?.createdAt
                                      ).getMinutes()}`}</span>
                                      {res?.status === 0 ? (
                                        <SingleTick
                                          width="12px"
                                          height="12px"
                                        />
                                      ) : res?.status === 1 ? (
                                        <DoubleTicks
                                          width="12px"
                                          height="12px"
                                        />
                                      ) : (
                                        <BlueTicks width="16px" height="16px" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="ms-2 me-2"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                  }}
                                >
                                  {sendermsg.includes(res) ? (
                                    <img
                                      src={
                                        userData?.image
                                          ? Bucket + userData?.image
                                          : receiverprofile
                                      }
                                      alt="sender"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                            )}
                            {res.senderId !== userData._id && (
                              <li
                                className="mt-2 receiv_massage"
                                key={`receiver${i}`}
                              >
                                <div
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                  }}
                                >
                                  {receivermsg.includes(res) ? (
                                    <img
                                      src={
                                        name?.user?.image
                                          ? name?.user?.image
                                          : receiverprofile
                                      }
                                      alt="receiver"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                      }}
                                      className="userAvatar"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  className="massage_sent"
                                  style={{
                                    backgroundColor: "#e3f8ff",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "5px",
                                        minWidth: "25px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          padding: "0px",
                                          backgroundColor: "#e3f8ff",
                                          color: "darkgrey",
                                        }}
                                      >{`${new Date(
                                        res?.createdAt
                                      ).getHours()}:${new Date(
                                        res?.createdAt
                                      ).getMinutes()}`}</span>
                                    </div>
                                    <div
                                      style={{
                                        padding: "10px",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {res.message}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                          </>
                        ))}
                      </>
                    ))
                  : ""}
              </ul>
              {/* text area to sent msg */}
              <div
                className="entermsgsends"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bottom: "22px",
                  gap: " 10px",
                  padding: "0px 10px",
                }}
              >
                <TextareaAutosize
                  ref={focusref}
                  style={{ padding: "15px 20px" }}
                  minRows={1}
                  maxRows={5}
                  placeholder={`Write a message for ${
                    name?.user?.firstName
                      ? name?.user?.firstName + " " + name?.user?.lastName
                      : "-"
                  } `}
                  value={message}
                  onChange={(e) => {
                    socket.emit("is_user_typing", true);
                    setTimeout(() => {
                      socket.emit("is_user_typing", false);
                    }, 2000);
                    setMessage(e?.target?.value);
                  }}
                  onKeyUp={(e) => handleKeySendMsg(e)}
                  className={message ? "set_button" : "set_input"}
                />
                {message?.length ? (
                  <Link
                    onClick={(e) => handleSendMessage(e, message)}
                    style={{ position: "unset" }}
                  >
                    Send
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatScreenMobile;
