import "./ConsultationList.css";
import React, { useEffect, useState, useRef } from "react";
import { ApiPost } from "../../helpers/API/API_data";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import DashHeader from "../../Component/DashHeader";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useContext } from "react";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/loader/Loader";
import Table from "../../Component/Table/Table";
import { useHistory } from "react-router-dom";
import ConsultationCard from "../../Component/tableCard/ConsultationCard";
import Pagination from "../../Component/Pagination";
import { SuccessToast } from "../../helpers/Toast";

const ConsultationList = () => {
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0)
  const [excel, setExcel] = useState([])
  const [patientData, setPatientData] = useState()
  const searchdata = search.trim().split(/ +/).join(' ')
  const linkRef = useRef(null)
  const history = useHistory();
  const [screenSize, setScreenSize] = useState(0);
  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);

  const columns = [
    {
      headerName: "Consultation No",
      field: "no",
      cellRenderer: (params) => (

        <>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleCopyClick(params?.data?.no);
              SuccessToast("Copy Successfully!");
            }}
          >
            {params?.data?.no}
          </span>
        </>
      ),
    },
    {
      headerName: "Patient Name",
      field: "name",
      cellRenderer: (params, i) =>

        <span
          onClick={() => history.push({
            pathname: `/viewConsultation/${params?.value?._id}`, state: {
              questionType: params?.data?.condition
            }
          })}
          style={{ cursor: 'pointer' }}> {params?.value?.firstName} {params?.value?.lastName}
        </span>

    },
    {
      headerName: "Doctor Name",
      field: "Doctorname",
    },
    {
      headerName: "Date/Time(Patient)",
      field: "patient",
    },
    {
      headerName: "Date/Time(Doctor)",
      field: "doctor",
    },
    {
      headerName: "Dr Response Time",
      field: "duration",
    },
    {
      headerName: "Consult Status",
      field: "consultStatus",
      cellRenderer: (params) => {
        if (params?.value === 4) {
          return <span style={{ color: '#a52a2a' }}>{'Completed'}</span>
        } else if (params?.value === 1) {
          return <span style={{ color: '#220a0a' }}>{'Unassigned Doctor'}</span>
        } else if (params?.value === 3) {
          return <span style={{ color: "#008000" }}>{"Live Consult"}</span>;
        } else if (params?.value === 0) {
          return <span style={{ color: '#ff0000' }}>{'Payment Pending'}</span>
        } else if (params?.value === 5) {
          return (
            <span style={{ color: "#045fbf" }}>{"Awaiting Pathology"}</span>
          );
        }
      },
    },
    {
      headerName: "Treatment Status",
      field: "treatment",
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.treatmentStatus == 0 ? <span style={{ color: '#ff0000' }}>{'Not Assigned'}</span> : params?.data?.treatmentStatus == 1 ? <span style={{ color: '#220a0a' }}>{'Pending'}</span> : params?.data?.treatmentStatus == 2 ? <span style={{ color: '#3e0000' }}>{'In Progress'}</span> : params?.data?.treatmentStatus == 3 ? <span style={{ color: '#3e0000' }}>{'Completed'}</span> : '-'}
          </>
        )
      }

    },
  ];

  // modify consulation data
  const consultationListData = patients?.map((consultations, index) => {

    return {
      id: consultations?.no,
      patientName: (
        <span
          onClick={() => {
            history.push({
              pathname: `/viewConsultation/${consultations?.name?._id}`, state: {
                questionType: consultations?.condition
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {" "}
          {consultations?.name?.firstName} {consultations?.name?.lastName}
        </span>
      ),
      doctorName: consultations?.Doctorname,
      patientDate: consultations?.patient,
      doctorDate: consultations?.doctor,
      consultStatus:
        consultations?.consultStatus && consultations?.consultStatus === 4 ? (
          <h4
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#a52a2a1a", color: "#a52a2a" }}
          >
            Treatment Plan Sent
          </h4>
        ) : consultations?.consultStatus === 1 ||
          consultations?.consultStatus === 2 ? (
          <h4
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#220a0a1a", color: "#220a0a" }}
          >
            Paid Unassigned Doctor
          </h4>
        ) : consultations?.consultStatus === 3 ? (
          <h4
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#0080001a", color: "#008000" }}
          >
            Live Consult
          </h4>
        ) : consultations?.consultStatus === 0 ? (
          <h4
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#ff00001a", color: "#ff0000" }}
          >
            Not Paid
          </h4>
        ) : consultations?.consultStatus === 5 ? (
          <h4
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#045fbf1a", color: "#045fbf" }}
          >
            Awaiting Pathology
          </h4>
        ) : (
          "-"
        ),
      treatmentStatus:
        consultations?.treatmentStatus === 4 ? (
          <h4
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#ff00001a", color: "#ff0000" }}
          >
            {"Not Paid"}
          </h4>
        ) : consultations?.treatmentStatus === 5 ? (
          <h4
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#220a0a1a", color: "#220a0a" }}
          >
            {"Paid Unfulfilled"}
          </h4>
        ) : consultations?.treatmentStatus === 6 ? (
          <h4
            className="consulation_list_li_data_inner_head_data status"
            style={{ backgroundColor: "#0080001a", color: "green" }}
          >
            {"Paid Fulfilled"}
          </h4>
        ) : (
          "-"
        ),
    };
  });

  // Pagination
  const handlePageChange = (pageNum) => setPage(pageNum);

  const handleNextClick = () => setPage((nextPage) => nextPage + 1);
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  const headers = [
    { label: "Consultation No", key: "no" },
    { label: "Patient Name", key: "name" },
    { label: "Doctor Name", key: "Doctorname" },
    { label: "Date/Time(Patient)", key: "patient" },
    { label: "Date/Time(Doctor)", key: "doctor" },
    { label: "Consult Status", key: "status" },
    { label: "Treatment Status", key: "treatment" },
  ];

  // get consult data
  const getData = async (body) => {
    setLoader(true);
    ApiPost(`admin/consulation/get`, body)
      .then((res) => {
        setLoader(false)
        setPatientData(res?.data?.data?.consulation_request_data)
        setPatients(res?.data?.data?.consulation_request_data?.map((val, index) => {
          return {
            no: val?.consultNumber,
            name: val?.patient[0],
            Doctorname: `${val?.doctor?.[0]?.firstName}  ${val?.doctor?.[0]?.lastName}`,
            patient: val?.patientJoiningDate && moment(val.patientJoiningDate).format('LLL'),
            doctor: val?.doctorAssignDate && moment(val.doctorAssignDate).format('LLL'),
            consultStatus: val?.consultStatus,
            duration: val?.durationInHoursMinutes ? val?.durationInHoursMinutes : "-",
            treatmentStatus: val?.questions[0]?.treatmentStatus,
            condition: val?.condition,
          }
        }
        ));
        setTotalData(res?.data?.data?.state?.data_count)

      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const bodyData = {
    page: page,
    limit: perpage,
    search: searchdata,
  };

  useEffect(() => {
    getData(bodyData);
  }, [page, perpage]);

  // handle search 
  const handleSearch = (searchData) => {
    if (searchData.length === 0) {
      getData({ ...bodyData, search: "" });
    }
    setSearch(searchData);
  };

   // handle search on submit
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getData(body);
  };

  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata])


  // export excel
  useEffect(() => {
    if (excel.length) {
      linkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
    };
    ApiPost(`admin/consulation/get`, body).then((res) => {
      setExcel(
        res?.data?.data?.consulation_request_data?.map((val, index) => {
          return {
            no: val?.consultNumber,
            name: `${val?.patient?.[0]?.firstName}  ${val?.patient?.[0]?.lastName}`,
            Doctorname: `${val?.doctor?.[0]?.firstName}  ${val?.doctor?.[0]?.lastName}`,
            patient:
              val?.patientJoiningDate &&
              moment(val.patientJoiningDate).format("LLL"),
            doctor:
              val?.doctorAssignDate &&
              moment(val.doctorAssignDate).format("LLL"),
            status:
              val?.consultStatus === 4
                ? "Completed"
                : val?.consultStatus === 1
                  ? "Unassigned Doctor"
                  : val?.consultStatus === 3
                    ? "live consult"
                    : val?.consultStatus === 0
                      ? "Payment Pending"
                      : val?.consultStatus === 5
                        ? "Awaiting pathology"
                        : "-",
          
            treatment:
              val?.treatmentStatus == 0 ? "Not Assigned" : val?.treatmentStatus == 1 ? "Pending" : val?.treatmentStatus == 2 ? "In Progress" : val?.treatmentStatus == 3 ? "Completed" : "-",
          };
        })
      );
    });
  };

  // handle copy id
  const handleCopyClick = (value) => {
    // Create a temporary textarea element to copy the text
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = value;
    document.body.appendChild(tempTextarea);
    // Select the text inside the textarea and copy it
    tempTextarea.select();
    document.execCommand("copy");
    // Remove the temporary textarea element
    document.body.removeChild(tempTextarea);
  };

  
// hide sidebar on resposive
  useEffect(() => {
    let a = document.getElementById("sidebar");

    if (a && screenSize <= 1440) {
      a.classList.add("d-none");
      a.classList.remove("d-block");
    } else {
      a.classList.add("d-block");
      a.classList.remove("d-none");
    }
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <DashHeader />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Consultation List</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={linkRef}
                  filename={"Consultation List"}
                  headers={headers}
                ></CSVLink>
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="Input search text"
                      className="  pe-3 search search1"
                      value={search}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                  <button className="button" onClick={() => ExportExcel()}>
                    Export to Excel
                  </button>
                </div>
              </div>
              {isTablet ? (
                <Table
                  columns={columns}
                  data={patients}
                  page={page}
                  perpage={perpage}
                  setparpage={setPerPage}
                  setpage={setPage}
                  totalData={totalData}
                />
              ) : (
                <>
                  <ul className="active_patient_ul">
                    {consultationListData?.map((item, index) => {
                      return (
                        <ConsultationCard
                          key={index}
                          data={item}
                          getData={getData}
                        />
                      );
                    })}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
              <div className="copyright">
                <p>2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default ConsultationList;
