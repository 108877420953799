import React, { useState,useEffect } from "react";
import "./Calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { ApiPost } from "../../helpers/API/API_data";
import { ErrorToast } from "../../helpers/Toast";
const localizer = momentLocalizer(moment);

const CalendarComponent = ({doctorId}) => {
  const [eventsData, setEventsData] = useState( );

  // get slot doctor slot data
  useEffect(() => {
    const body = {
      doctorId : doctorId
    }
    ApiPost("admin/patient/SelectedSlot/get", body)
    .then(async(res)=>{
        if (res?.status == 200) {
          const data = res?.data?.data;
          const formattedData = data?.SlotsList?.map((event) => {
            return {
              start: new Date(event?.start),
              end: new Date(event?.end),
              title: `${ event?.start ? moment(event?.start).format('LT') : ""} - ${moment(event?.end).format('LT')}`,
            };
          });
          data?.selectedList?.forEach(event => {
            formattedData?.push({
              start: new Date(event?.start.slice(0, 16)
           ),
              end: new Date(event?.end?.slice(0, 16)),
              title: `${ event?.start ? moment(event?.start.slice(0, 16)).format('LT') : ""} - ${moment(event?.end?.slice(0, 16)).format('LT')}`,
              isBooked:true
            })
          });
          setEventsData(formattedData);
        }
    })
    .catch((e) => {
      ErrorToast(e?.message);
    }).finally(()=>{
     
    })
   
  }, [])
  

  return (
    <>
       <div className="viewpatienttitle">
       <h4>Available Slot to Consult</h4>
       </div>
    
      <Calendar
        views={["month","day"]}
        selectable={false}
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        eventPropGetter={(event) => {
          const backgroundColor = event.isBooked ? '#b4555d' : '#3174ad';
          return { style: { backgroundColor } }
        }}        
        style={{ height: "100vh" }}
      />
    </>
  );
};

export default CalendarComponent;
