/**
 * The ConsultationCard component is a React component that renders a card with information about a
 * consultation, including patient and doctor names, dates and times, and consultation and treatment
 * statuses.
 * @returns The ConsultationCard component is being returned.
 */
import { Checkbox } from "antd";
import moment from "moment";
import React from "react";

const ConsultationCard = ({ data }) => {

  const [isCheck, setIsCheck] = React.useState([]);
  const handleSelectBox = (checked, id) => {
    if (checked) setIsCheck([...isCheck, id]);
    else setIsCheck(isCheck.filter((item) => item !== id));
  };

  return (
    <>
      <li className="active_patient_li_list">
        <div
          className="consulation_list_li_data_head flex"
          style={{ border: "none", paddingBottom: "0" }}
        >
          <Checkbox
            checked={isCheck.includes(data?.id)}
            onChange={(e) => handleSelectBox(e.target.checked, data?.id)}
          />
          <h6 className="consulation_list_li_data_inner_head_data ms-2">
            {data?.id}
          </h6>
        </div>
        <div className="consulation_list_li_data_inner">
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Patient Name
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.patientName}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Doctor Name
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {data?.doctorName}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Date/Time(Patient)
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {`${data?.patientDate && moment(data?.patientDate).format("L")
                  } ${data?.patientDate && moment(data?.patientDate).format("LT")
                  }`}
              </h6>
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Date/Time(Doctor)
              </h6>
              <h6 className="consulation_list_li_data_inner_head_data">
                {`${data?.doctorDate && moment(data?.doctorDate).format("L")} ${data?.doctorDate && moment(data?.doctorDate).format("LT")
                  }`}
              </h6>
            </div>
          </div>
          <div className="d-flex consulation_list_data_left_main_flex">
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Consult Status
              </h6>
              {data?.consultStatus}
            </div>
            <div className="consulation_list_data_left_inner">
              <h6 className="consulation_list_li_data_inner_head">
                Treatment Status
              </h6>
              {data?.treatmentStatus}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default ConsultationCard;
